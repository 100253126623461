import {Switch, BrowserRouter as Router, Route} from 'react-router-dom'

import PrivateRoute from './components/private-route'
import AuthLayout from './layout/auth-layout'
import DefaultLayout from './layout/default-layout'
import AppProviders from './app-providers'

function App() {
  return (
    <AppProviders>
      <Router>
        <Switch>
          <Route path="/auth" component={AuthLayout} />
          <PrivateRoute path="/" component={DefaultLayout} />
        </Switch>
      </Router>
    </AppProviders>
  )
}

export default App
