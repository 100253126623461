import React from 'react'
import CreateEditHomeWorkLevel from './homework/create-edit-homework-level'

const HomeworkRoom = () => {
  const [forceReload, setForceReload] = React.useState(false)

  return (
    <div>
      <div className="mb-10 mt-10">
        <h3 className="mt-4 text-blue-700">Beginner level</h3>
        <CreateEditHomeWorkLevel
          level="beginner"
          onUpdate={forceReload}
          onReload={() => setForceReload(p => !p)}
        />
      </div>
      <div className="mb-10">
        <h3 className="mt-4 text-yellow-500">Intermidate level</h3>
        <CreateEditHomeWorkLevel
          level="intermidate"
          onUpdate={forceReload}
          onReload={() => setForceReload(p => !p)}
        />
      </div>
      <div className="mb-10">
        <h3 className="mt-4 text-red-500">Advanced level</h3>
        <CreateEditHomeWorkLevel
          level="advanced"
          onUpdate={forceReload}
          onReload={() => setForceReload(p => !p)}
        />
      </div>
    </div>
  )
}

export default HomeworkRoom
