import React from 'react'
import {BsPen} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import {Image} from 'semantic-ui-react'
import routes from '../../routes'

const SectionCard = ({ name, description, chapterid, subjectid,sectionId , order}) => {
  return (
    <div className="rounded-md w-full shadow-sm mb-4 hover:shadow-md hover:opacity-95 p-3 border-2 border-solid">

        <div className="flex justify-between items-center">
         
            <p className="text-primary text-lg font-semibold">Section {order}: {name}</p>

          <Link
            className="mt-1 cursor-pointer"
            to={routes.subjects.chapters.sections.update(subjectid, chapterid,sectionId)}
          >
            <BsPen size="14" />
          </Link>
        </div>

        <p className="font-semibold h-14 overflow-y-auto mt-4">{description}</p>
    </div>
  )
}

export default SectionCard
