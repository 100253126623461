import {Menu} from 'semantic-ui-react'

const ChapterTabs = ({activeMenu, setActiveMenu}) => {
  return (
    <div class="flex">
        <Menu
          className="mt-2 border-none shadow-none text-xl  m-2"
          stackable
          
        >
            <>
              <Menu.Item
                active={activeMenu === 'practicRoom'}
                onClick={() => setActiveMenu('practicRoom')}
                 className={`bottom-arrow ${
                      activeMenu === 'practicRoom' ? 'border-t-4 border-primary bg-white' : 'bg-gray-50'
                    }`}
              >
                <div className="flex flex-col w-full ">
                 
                  <p className="font-medium text-labelColor">Practice Room</p>
                  <div
                    // className={`bottom-arrow ${
                    //   activeMenu === 'practicRoom' ? 'border-t-2 border-red-800' : 'hidden'
                    // }`}
                  ></div>
                </div>
              </Menu.Item>
              <Menu.Item
                active={activeMenu === 'homeworkRoom'}
                onClick={() => setActiveMenu('homeworkRoom')}
                className={`bottom-arrow ${
                      activeMenu === 'homeworkRoom' ? 'border-t-4 border-primary bg-white' : 'bg-gray-50'
                    }`}
              >
                <div className="flex flex-col w-full ">
          
                  <p className="font-medium text-labelColor">Homework Room</p>
                  <div
                    className={`bottom-arrow ${
                      activeMenu === 'homeworkRoom' ? 'visible' : 'hidden'
                    }`}
                  ></div>
                </div>
              </Menu.Item>
            </>
        </Menu>
    </div>
  )
}

export default ChapterTabs
