import {Menu} from 'semantic-ui-react'

const TeacherInfoTabs = ({activeMenu, setActiveMenu}) => {
  return (
    <div class="flex  border solid border-gray-400 rounded-md shadow mt-10">
      <Menu
        className=" border-none shadow-none  bg-transparent text-xl "
        stackable
        widths={2}
      >
        <>
          <Menu.Item
            active={activeMenu === 'basicInfo'}
            onClick={() => setActiveMenu('basicInfo')}
            className={` ${
              activeMenu === 'basicInfo'
                ? ' text-primary bg-gray-200'
                : 'bg-gray-50 text-gray-500'
            }`}
          >
            <div className="flex flex-col w-full ">
              <p className="font-medium text-labelColor">Basic info</p>
              <div
              // className={` ${
              //   activeMenu === 'basicInfo' ? 'border-t-2 border-red-800' : 'hidden'
              // }`}
              ></div>
            </div>
          </Menu.Item>
          <Menu.Item
            active={activeMenu === 'classes'}
            onClick={() => setActiveMenu('classes')}
            className={` ${
              activeMenu === 'classes'
                ? ' text-primary bg-gray-200'
                : 'bg-gray-50 text-gray-500'
            }`}
          >
            <div className="flex flex-col w-full ">
              <p className="font-medium text-labelColor">Classes</p>
              <div
              // className={`bottom-arrow ${
              //   activeMenu === 'classes' ? 'border-t-2 border-red-800' : 'hidden'
              // }`}
              ></div>
            </div>
          </Menu.Item>
        </>
      </Menu>
    </div>
  )
}

export default TeacherInfoTabs
