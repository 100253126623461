import React from "react";
import { Controller } from "react-hook-form";
import { FormDropdown } from "semantic-ui-react";

const FormDropdownController = ({
  control,
  name,
  label,
  controlProps,
  inputProps,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      {...controlProps}
      render={({ field }) => {
        /**
         * field.onChange expects e.target.value, while semantic in drop down doesn't provide e.target.value
         * so we're mapping data.value to e.target.value
         */
        const controllerOnChange = (e, data) => {
            // debugger
          field.onChange({
            ...e,
            target: { ...e.target, value: data.value || undefined },
          });
          
          if (typeof inputProps.onChangeCB === 'function')
            inputProps.onChangeCB(data.value)
        };

        return (
          <FormDropdown
            key={field.id}
            error={control?._formState?.errors?.[name] && control?._formState?.errors?.[name]?.message}

            {...field}
            onChange={controllerOnChange}
            label={label}
            {...inputProps}
          />
        );
      }}
    />
  );
};

export default FormDropdownController;
