import React from 'react'
import {Fragment} from 'react/cjs/react.production.min'
import {
  Button,
  Dimmer,
  Loader,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react'
import VoucherStudentClassAccordion from './voucher-student-class-accordion'
import {
  AiOutlineCaretDown,
  AiOutlineCaretRight,
  AiOutlineEye,
  AiOutlineMinusCircle,
} from 'react-icons/ai'
import {useParams} from 'react-router-dom'

import {truncate} from '../../utils/string'
import useAsync from '../../hooks/use-async'
import {authAxios} from '../../config/axios-config'
import {formatResponseError} from '../../utils/format-response'

const VoucherStudentInfo = () => {
  const [activeIndex, setActiveIndex] = React.useState(-1)

  const [classData, setClassData] = React.useState([])
  const {userid} = useParams()

  const {
    run: getStudentDetails,
    error: errorGettingStudentDetails,
    isLoading: gettingStudentDetails,
  } = useAsync()

  const {
    run: getClasses,
    error: errorGettingClasses,
    isLoading: gettingclasses,
  } = useAsync()

  React.useEffect(() => {
    getClasses(authAxios.get(`/student/class/all?studentId=${userid}`)).then(
      ({data}) => {
        setClassData(data)
        console.log(data)
      },
    )
  }, [getClasses, userid])

  const handleClickAccordion = (e, titleProps) => {
    const {index} = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent">
        <Dimmer active={gettingclasses} inverted>
          <Loader active={true} />
        </Dimmer>

        {errorGettingClasses ? (
          <Message error list={formatResponseError(errorGettingClasses)} />
        ) : null}
        <h3 className=" text-primary mt-12">Student's logs</h3>
        <div className="w-32 h-1 rounded-full bg-gradient-to-r from-primary to-tableHeader"></div>

        <div className="mt-20">
          {classData?.map((a, idx) => (
            <VoucherStudentClassAccordion
              index={idx}
              classid={a?._id}
              name={a?.name}
              activeIndex={activeIndex}
              clickAccordion={handleClickAccordion}
            />
          ))}
        </div>
      </Segment>
    </div>
  )
}

export default VoucherStudentInfo
