import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {
  Button,
  Dimmer,
  Input,
  Loader,
  Message,
  Pagination,
  Segment,
  Table,
} from 'semantic-ui-react'

import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import routes from '../../routes'
import {useLocation, useHistory} from 'react-router-dom'
import queryString from 'query-string'
import {formatResponseError} from '../../utils/format-response'
import {BsPen} from 'react-icons/bs'
import DefaultContainer from '../../components/shared/default-container'
import {truncate} from '../../utils/string'

const Competency = () => {
  const [questions, setQuestions] = React.useState([])
  const [searchKeyword, setSearchKeyword] = useState('')

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [limit, setLimit] = useState(10)

  const location = useLocation()
  const history = useHistory()

  const {
    run: getAmortizationQuestion,
    error: errorGettingAmotizationQuestion,
    isLoading: gettingAmotizationQuestion,
  } = useAsync()

  React.useEffect(() => {
    const {
      page = 1,
      limit = 10,
      search = '',
    } = queryString.parse(location.search)

    setPage(page)
    setLimit(limit)
    setSearchKeyword(search)

    getAmortizationQuestion(
      authAxios.get(
        `/school/competency/?page=${page}&limit=${limit}&search=${search}`,
      ),
    ).then(({data}) => {
      setQuestions(data.docs)
      setTotalPages(data.totalPages)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAmortizationQuestion, location.search])

  const handleOnPageChange = (e, {activePage}) => {
    history.push(
      `${location.pathname}?page=${activePage}&limit=${limit}&search=${searchKeyword}`,
    )
  }

  const onSearchClick = () => {
    history.push(
      `${location.pathname}?page=${page}&limit=${limit}&search=${searchKeyword}`,
    )
  }

  return (
    <DefaultContainer>
      <Segment className="border-none shadow-none bg-transparent w-full">
        {errorGettingAmotizationQuestion ? (
          <Message
            error
            list={formatResponseError(errorGettingAmotizationQuestion)}
          />
        ) : null}
        <Dimmer active={gettingAmotizationQuestion} inverted>
          <Loader active={true} />
        </Dimmer>
        <div className="flex justify-between align-center">
          <h1 className="text-3xl">Competency</h1>
          <div>
            <Input
              type="text"
              placeholder="Search..."
              size="small"
              className="mr-4"
              action
              value={searchKeyword}
              onChange={(e, data) => {
                setSearchKeyword(data.value)
              }}
            >
              <input />
              <Button type="button" onClick={onSearchClick}>
                Search
              </Button>
            </Input>

            <Link className="text-white" to={routes.competency.new}>
              <Button size="small" primary>
                New
              </Button>
            </Link>
          </div>
        </div>

        <div className="mt-12">
          <Table celled striped className="shadow-lg">
            <Table.Header>
              <Table.Row className="text-sm">
                <Table.HeaderCell className="bg-tableHeader">
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell className="bg-tableHeader">
                  Subject
                </Table.HeaderCell>

                <Table.HeaderCell className="bg-tableHeader">
                  Action
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {questions?.map((a, idx) => (
                <Table.Row key={a?._id} textAlign="left" verticalAlign="top">
                  <Table.Cell>{truncate(a?.name, 35)}</Table.Cell>
                  <Table.Cell>{a?.subject?.name}</Table.Cell>

                  <Table.Cell>
                    <Button
                      basic
                      size="small"
                      className="px-3 py-2"
                      onClick={() =>
                        history.push(routes.competency.update(a?._id))
                      }
                    >
                      <BsPen size="14" />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>

        <div className="flex justify-end my-8">
          <Pagination
            activePage={page}
            onPageChange={handleOnPageChange}
            totalPages={totalPages}
          />
        </div>
      </Segment>
    </DefaultContainer>
  )
}

export default Competency
