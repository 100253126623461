import { FieldArray, Formik } from 'formik'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Dimmer, Form, Loader, Message, Segment } from 'semantic-ui-react'
import FormikDropdown from '../../../components/formik/FormikDropdown'
import { authAxios } from '../../../config/axios-config'
import useAsync from '../../../hooks/use-async'
import { formatResponseError } from '../../../utils/format-response'

const HomwworkRoomReview = ({level, onUpdate, onReload}) => {
    const [wexs, setWexs] = React.useState([])
    const [tests, setTests] = React.useState([])
    const [lockedBys, setLockedBys] = React.useState([])
  
    const [levelData, setLevelData] = React.useState(false)
  
    const {chapterid, subjectid} = useParams()
  
    const {
      run: getWexs,
      error: errorGettingWexs,
      isLoading: gettingWexs,
    } = useAsync()
  
    const {
      run: getTests,
      error: errorGettingTests,
      isLoading: gettingTests,
    } = useAsync()
  
    const {
      run: getLockedBy,
      error: errorGettingLockedBy,
      isLoading: gettingLockedBy,
    } = useAsync()
  
    const {
      run: getLevel,
      error: errorGettingLevel,
      isLoading: gettingLevel,
    } = useAsync()
  
   
    const requestOptions = (i, formik) => {
        let options = [...lockedBys]
        let prevValObj
        while (i > -1) {
          let prevVal = formik.values.level[i].exercise
          // console.log(prevVal)
          if (formik.values.level[i].type === 'wex') {
            prevValObj = wexs.find(w => w.value === prevVal)
          } else {
            prevValObj = tests.find(w => w.value === prevVal)
          }
          if (prevValObj) options.push(prevValObj)
          --i
        }
        return Array.from(new Set(options))
      }
    React.useEffect(() => {
      getWexs(
        authAxios.get(
          `/chapter/exercise/all?type=unpair&exercise=wex&sid=${subjectid}`,
        ),
      ).then(({data}) => {
        let options = []
        data?.forEach(s => options.push({text: s.name, key: s._id, value: s._id}))
        setWexs(options)
      })
    }, [getWexs, subjectid, onUpdate])
  
    React.useEffect(() => {
      getTests(
        authAxios.get(
          `/chapter/exercise/all?type=unpair&exercise=test&sid=${subjectid}`,
        ),
      ).then(({data}) => {
        let options = []
        data?.forEach(s => options.push({text: s.name, key: s._id, value: s._id}))
        setTests(options)
      })
    }, [getTests, subjectid, onUpdate])
  
    React.useEffect(() => {
      getLockedBy(
        authAxios.get(
          `/chapter/exercise/all?type=pair&cid=${chapterid}&sid=${subjectid}`,
        ),
      ).then(({data}) => {
        let options = []
        data?.forEach(s => options.push({text: s.name, key: s._id, value: s._id}))
        setLockedBys(options)
      })
    }, [chapterid, getLockedBy, subjectid, onUpdate])
  
    React.useEffect(() => {
      getLevel(
        authAxios.get(`/chapter/homework/${chapterid}?type=${level}`),
      ).then(({data}) => {
        setLevelData(data)
      })
    }, [chapterid, getLevel, level, onUpdate])
  
    return (
      <div className="p-2 border-2 solid rounded-md">
        <Segment className="border-none shadow-none  bg-transparent">
          {errorGettingWexs ? (
            <Message error list={formatResponseError(errorGettingWexs)} />
          ) : null}
          {errorGettingTests ? (
            <Message error list={formatResponseError(errorGettingTests)} />
          ) : null}
          {errorGettingLockedBy ? (
            <Message error list={formatResponseError(errorGettingLockedBy)} />
          ) : null}
         
          {errorGettingLevel ? (
            <Message error list={formatResponseError(errorGettingLevel)} />
          ) : null}
  
          <Dimmer
            className="h-full"
            active={gettingLevel}
            inverted
          >
            <Loader active={true} />
          </Dimmer>
  
          <Formik
            initialValues={{
              level:
                levelData.length > 0
                  ? levelData?.map(s => ({
                      type: s.exercise.type,
                      exercise: s.exercise._id,
                      lock: s.lock?.lock.map(l => l._id),
                    }))
                  : [
                      {
                        exercise: '',
                        lock: [],
                        type: 'wex',
                      },
                      {
                        exercise: '',
                        lock: [],
                        type: 'wex',
                      },
                      {
                        exercise: '',
                        lock: [],
                        type: 'test',
                      },
                    ],
            }}
            enableReinitialize
          >
            {formik => (
              <Form className="mt-4" onSubmit={formik.handleSubmit}>
                <FieldArray name="level">
                  {({insert, remove, push}) => (
                    <div>
                     
  
                      {formik.values.level.length > 0
                        ? formik.values.level.map((r, i) => {
                            const {touched, errors} = formik
                            const hasTouchedexercise =
                              touched.level && touched.level[i]?.exercise
                            const hasexerciseError =
                              errors.level && errors.level[i]?.exercise
  
                            const hasTouchedTEst =
                              touched.level && touched.level[i]?.lock
                            const hasTEstError =
                              errors.level && errors.level[i]?.lock
  
                            let levelExerciseOption = null
                            if (
                              levelData.length > 0 &&
                              levelData[i]?.exercise?.type ===
                                formik.values.level[i].type
                            ) {
                              const s = levelData[i]
                              levelExerciseOption = {
                                text: s.exercise.name,
                                key: s.exercise._id,
                                value: s.exercise._id,
                              }
                            }
  
                            return (
                              <div className="flex justify-between gap-2 flex-wrap mb-4" key={i}>
                                <div class="flex-grow md:w-5/12">
                                  {formik.values.level[i].type === 'wex' ? (
                                    <FormikDropdown
                                      label="WEX"
                                      placeholder="select a wex"
                                      options={
                                        levelExerciseOption
                                          ? [levelExerciseOption, ...wexs]
                                          : wexs
                                      }
                                      name={`level.${i}.exercise`}
                                      loading={gettingWexs}
                                      disabled
                                      error={
                                        hasTouchedexercise && hasexerciseError
                                      }
                                    />
                                  ) : (
                                    <FormikDropdown
                                      label="Test"
                                      placeholder="select a test"
                                      options={
                                        levelExerciseOption
                                          ? [levelExerciseOption, ...tests]
                                          : tests
                                      }
                                      name={`level.${i}.exercise`}
                                      loading={gettingTests}
                                      disabled

                                      error={
                                        hasTouchedexercise && hasexerciseError
                                      }
                                    />
                                  )}
                                  {/* <FormikDropdown
                                  label={formik.values.level[i].type === 'wex' ? 'WEX':'Test'}
                                    placeholder="select a wex"
                                    options={testOptions1}
                                    name={`level.${i}.exercise`}
                                    // error={
                                    //   hasTouchedFeedbackMessage &&
                                    //   hasFeedbackMessageError
                                    // }
                                  /> */}
                                </div>
                                <div class="flex-grow md:w-5/12">
                                  <FormikDropdown
                                    label="Locked by"
                                    placeholder="please select a wex first"
                                    loading={gettingLockedBy}
                                    options={requestOptions(i - 1, formik)}
                                    name={`level.${i}.lock`}
                                    multiple
                                    disabled
                                    error={hasTouchedTEst && hasTEstError}
                                  />
                                </div>
                              </div>
                            )
                          })
                        : null}
                    </div>
                  )}
                </FieldArray>
              </Form>
            )}
          </Formik>
        </Segment>
      </div>
    )
  }

export default HomwworkRoomReview
