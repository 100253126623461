import {Formik} from 'formik'
import React from 'react'
import {Link} from 'react-router-dom'
import {
  Button,
  Confirm,
  Dimmer,
  Dropdown,
  Form,
  Icon,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react'
import * as Yup from 'yup'

import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import {formatResponseError} from '../../utils/format-response'
import {truncate} from '../../utils/string'
import FormikDropdown from '../formik/FormikDropdown'
import FormikInput from '../formik/FormikInput'

const PdfCard = ({title, access, data, pdfLink, id, reload}) => {
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [isEditing, setIsEditing] = React.useState(false)

  const pdfAccessOptions = React.useMemo(
    () => [
      {text: 'all', value: 'all', key: 'all'},
      {text: 'instructor', value: 'instructor', key: 'instructor'},
      {text: 'student', value: 'student', key: 'student'},
    ],
    [],
  )

  const newPdfSchema = Yup.object({
    title: Yup.string().trim().required('Required'),
    access: Yup.string().trim().required('Required'),
  })

  const {
    run: deletePdf,
    error: errorDeletingPdf,
    isLoading: deletingPdf,
  } = useAsync()

  const {
    run: EditPdf,
    error: errorEditingPdf,
    isLoading: EditingPdf,
  } = useAsync()

  const handleCancel = () => {
    setIsDeleting(false)
  }
  const handleConfirm = () => {
    deletePdf(authAxios.delete(`/school/subject/pdf/${id}`)).then(({data}) => {
      console.log(data)
      reload()
    })
    setIsDeleting(false)
  }

  const onEdit = values => {
    EditPdf(authAxios.patch(`/school/subject/pdf/${id}`,values)).then(({data}) => {
      console.log(data)
      reload()
      setIsEditing(false)
    })
  }

  return (
    <div className="rounded-md w-56 h-48 shadow-md hover:shadow-xl  hover:opacity-95  flex  flex-col border-2 solid relative">
      {/* {errorDeletingPdf ? (
        <Message error list={formatResponseError(errorDeletingPdf)} />
      ) : null}
      {errorEditingPdf ? (
        <Message error list={formatResponseError(errorEditingPdf)} />
      ) : null} */}
      <Dropdown
        className="absolute top-1 -right-1 icon "
        icon="ellipsis vertical"
        floating
      >
        <Dropdown.Menu className="" as="div" direction="left">
        <Dropdown.Item
            className=""
            text="Edit"
            icon="edit"
            onClick={() => {
              setIsEditing(p => !p)
            }}
          />
          <Dropdown.Divider className="m-0" />

          <Dropdown.Item
            className=""
            text="Delete"
            icon="trash alternate outline"
            onClick={() => {
              setIsDeleting(p => !p)
            }}
          />
          
        </Dropdown.Menu>
      </Dropdown>

      <Dimmer active={deletingPdf} inverted>
        <Loader active={true} />
      </Dimmer>

      <a target="_blank" href={pdfLink} rel="noreferrer" className="">
        <div className="flex justify-center items-center mt-8">
          <Icon name="file pdf outline" size="huge" />
        </div>

        <div className="flex justify-center items-center mt-4">
          <p className="text-center px-2 break-all">{truncate(title, 60)}</p>
        </div>
        <Confirm
          content={`Are you sure to delete ${title}`}
          open={isDeleting}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          cancelButton="Cancel"
          confirmButton={
            <Button negative className="cofirmDelete bg-red-600 ">
              Delete
            </Button>
          }
        />

        <Modal
          open={isEditing}
          onClose={() => setIsEditing(false)}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          size="tiny"
          closeIcon
        >
          <Modal.Header>Edit Pdf</Modal.Header>
          <Modal.Content>
            <Formik
              initialValues={{
                title: data?.title || '',
                access: access || pdfAccessOptions[0].value || '',
              }}
              validationSchema={newPdfSchema}
              onSubmit={onEdit}
              enableReinitialize
            >
              {formik => (
                <Form onSubmit={formik.submitForm} loading={EditingPdf}>
                  <FormikInput label="Title" name="title" />
                  <FormikDropdown
                    label="Access"
                    name="access"
                    options={pdfAccessOptions}
                  />
                  <Button primary className="my-5">
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </Modal.Content>
        </Modal>
      </a>
    </div>
  )
}

export default PdfCard
