import Axios from 'axios'
import Auth from '../utils/auth'

Axios.defaults.baseURL =
  process.env.NODE_ENV !== 'prod'
    ? process.env.REACT_APP_DEV_URL
    : process.env.REACT_APP_SERVER_URL

const AuthAxios = Axios.create()
AuthAxios.interceptors.request.use(config => {
  let accessToken = Auth.getToken()
  config.headers = {Authorization: `Bearer ${accessToken}`}
  return config
})

export const axios = Axios
export const authAxios = AuthAxios
