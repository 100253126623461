import React from 'react'
import useAsync from './use-async'
import {authAxios} from '../config/axios-config'

function useSubject() {
  const [subjects, setSubjects] = React.useState([])
  const {
    run: getSubjects,
    error: errorGettingSubjects,
    isLoading: gettingSubjects,
  } = useAsync()

  React.useEffect(() => {
    getSubjects(authAxios.get('/school/subject/all')).then(({data}) => {
      let options = []
      data?.subjects.forEach(s =>
        options.push({text: s.name, key: s._id, value: s._id}),
      )
      setSubjects(options)
    })
  }, [getSubjects])

  return {subjects, gettingSubjects, errorGettingSubjects}
}

export default useSubject
