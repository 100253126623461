import {Formik} from 'formik'
import React from 'react'
import {useHistory} from 'react-router'
import {Button, Form, Message} from 'semantic-ui-react'
import * as Yup from 'yup'

import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import useSubject from '../../hooks/use-subjects'
import routes from '../../routes'
import {formatResponseError} from '../../utils/format-response'
import FormikDropdown from '../formik/FormikDropdown'
import FormikInput from '../formik/FormikInput'
import DefaultContainer from '../shared/default-container'

const NewCompetency = () => {
  const history = useHistory()

  const {
    run: createQuestion,
    error: errorCreatingQuestion,
    isLoading: creatingQuestion,
  } = useAsync()
  const {subjects, errorGettingSubjects, gettingSubjects} = useSubject()

  const newCompetencySchema = Yup.object({
    subject: Yup.string().trim().required(),
    name: Yup.string().trim().required(),
  })

  function onCreate(values) {
    createQuestion(authAxios.post('/school/subject/competency', values)).then(
      ({data}) => {
        console.log(data)
        history.push(routes.competency.all)
      },
    )
  }

  return (
    <DefaultContainer>
      <h2 className="mb-8">Create new Competency</h2>

      {errorGettingSubjects ? (
        <Message error list={formatResponseError(errorGettingSubjects)} />
      ) : null}

      {errorCreatingQuestion ? (
        <Message error list={formatResponseError(errorCreatingQuestion)} />
      ) : null}

      <Formik
        initialValues={{
          subject: '',
          name: '',
        }}
        onSubmit={onCreate}
        validationSchema={newCompetencySchema}
      >
        {formik => (
          <Form loading={creatingQuestion} onSubmit={formik.handleSubmit}>
            <FormikInput
              label="Competency"
              name="name"
              placeholder="Enter Competency"
            />
            <FormikDropdown
              label="Subject"
              name="subject"
              loading={gettingSubjects}
              options={subjects}
            />
            <div className="my-8 flex justify-between">
              <Button size="tiny" type="submit" primary>
                Save
              </Button>

              <Button
                size="tiny"
                onClick={() => history.push(routes.competency.all)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </DefaultContainer>
  )
}

export default NewCompetency
