
import Auth from '../utils/auth';
import routes from '../routes'
import { useHistory } from 'react-router-dom'

function useAuth() {
    const history = useHistory()

    const logoutAndRedirect = () => {
        Auth.logout()
        history.push(routes.login)
        history.go(0)
    }

    return { logoutAndRedirect }
}

export default useAuth