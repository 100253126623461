import React from 'react'
import useAsync from './use-async'
import {authAxios} from '../config/axios-config'

function useDifficulty() {
  const [difficulties, setDifficulty] = React.useState([])
  const {
    run: getDifficulty,
    error: errorGettingDifficulty,
    isLoading: gettingDifficulties,
  } = useAsync()

  React.useEffect(() => {
    getDifficulty(authAxios.get('/question/difficulty')).then(({data}) => {
      let options = []
      data?.forEach(s =>
        options.push({text: s.name, key: s._id, value: s._id}),
      )
      setDifficulty(options)
    })
  }, [getDifficulty])

  return {difficulties, gettingDifficulties, errorGettingDifficulty}
}

export default useDifficulty
