import {FieldArray, Formik} from 'formik'
import React from 'react'
import {AiOutlineDelete} from 'react-icons/ai'
import {Button, Dimmer, Form, Loader, Message, Segment} from 'semantic-ui-react'
import FormikDropdown from '../../../components/formik/FormikDropdown'
import * as Yup from 'yup'
import {useParams} from 'react-router-dom'
import useAsync from '../../../hooks/use-async'
import {authAxios} from '../../../config/axios-config'
import {formatResponseError} from '../../../utils/format-response'

const CreateEditHomeWorkLevel = ({level, onUpdate, onReload}) => {
  const [wexs, setWexs] = React.useState([])
  const [tests, setTests] = React.useState([])
  const [lockedBys, setLockedBys] = React.useState([])
  const [allLockedBys, setAllLockedBys] = React.useState([])

  const [levelData, setLevelData] = React.useState(false)

  const {chapterid, subjectid} = useParams()

  const {
    run: getWexs,
    error: errorGettingWexs,
    isLoading: gettingWexs,
  } = useAsync()

  const {
    run: getTests,
    error: errorGettingTests,
    isLoading: gettingTests,
  } = useAsync()

  const {
    run: getLockedBy,
    error: errorGettingLockedBy,
    isLoading: gettingLockedBy,
  } = useAsync()

  const {
    run: submitLevel,
    error: errorSavingLevel,
    isLoading: savingLevel,
    setError
  } = useAsync()

  const {
    run: getLevel,
    error: errorGettingLevel,
    isLoading: gettingLevel,
  } = useAsync()

  const homeworkRoomSchema = Yup.object({
    level: Yup.array()
      .of(
        Yup.object({
          exercise: Yup.string().required('Required'),
          lock: Yup.array().min(1, `At least select one`).required('Required'),
        }),
      )
      .required(),
  })

  const onSubmit = values => {
    let obj = {type: `${level}`, ...values}
    submitLevel(authAxios.post(`/chapter/homework?cid=${chapterid}`, obj)).then(
      ({data}) => {
        // setForceUpdate(true)
        setError(null)
        onReload()
      },
    )
  }

 

  React.useEffect(() => {
    getWexs(
      authAxios.get(
        `/chapter/exercise/all?type=unpair&exercise=wex&sid=${subjectid}`,
      ),
    ).then(({data}) => {
      let options = []
      data?.forEach(s => options.push({text: s.name, key: s._id, value: s._id}))
      setWexs(options)
    })
  }, [getWexs, subjectid, onUpdate])

  React.useEffect(() => {
    getTests(
      authAxios.get(
        `/chapter/exercise/all?type=unpair&exercise=test&sid=${subjectid}`,
      ),
    ).then(({data}) => {
      let options = []
      data?.forEach(s => options.push({text: s.name, key: s._id, value: s._id}))
      setTests(options)
    })
  }, [getTests, subjectid, onUpdate])

  React.useEffect(() => {
    getLockedBy(
      authAxios.get(
        `/chapter/exercise/all?type=pair&cid=${chapterid}&sid=${subjectid}`,
      ),
    ).then(({data}) => {
      let options = []
      data?.forEach(s => options.push({text: s.name, key: s._id, value: s._id}))
      setLockedBys(options)
    })
  }, [chapterid, getLockedBy, subjectid, onUpdate])

  React.useEffect(() => {
    getLevel(
      authAxios.get(`/chapter/homework/${chapterid}?type=${level}`),
    ).then(({data}) => {
      console.log(data)
      let tempLocksArr=[]
      data?.forEach(s=> {
        s?.lock?.lock?.forEach(l => tempLocksArr.push({text: l?.name, key: l?._id, value: l?._id}))
      })
      setAllLockedBys([...lockedBys,...tempLocksArr])
      setLevelData(data)
    })
  }, [chapterid, getLevel, level, onUpdate, lockedBys])

  /**
   * @param {obj to append to wexs options} levelExerciseOption
   * @returns all wexs after merging data returned on update with wexs request options
   */
  const getAllWexs = levelExerciseOption => {
    let options = []
    if (levelExerciseOption?.type === 'wex') {
      options = [levelExerciseOption, ...wexs]
    } else {
      options = [...wexs]
    }
    let optionsIds = options?.map(o => o.value)

    if (levelData?.length > 0) {
      let questionWexs = []
      levelData
        .filter(l => l.exercise.type === 'wex')
        ?.forEach(
          s =>
            !optionsIds.includes(s?.exercise._id) &&
            questionWexs.push({
              text: s?.exercise.name,
              key: s?.exercise._id,
              value: s?.exercise._id,
            }),
        )
      options = options.concat([...questionWexs])
    } else {
      options = [...wexs]
    }
    return options
  }

  const getAllTests = levelExerciseOption => {
    let options = []
    if (levelExerciseOption?.type === 'test') {
      options = [levelExerciseOption, ...tests]
    } else {
      options = [...tests]
    }
    let optionsIds = options?.map(o => o.value)

    if (levelData?.length > 0) {
      let questionTests = []
      levelData
        .filter(l => l.exercise.type === 'test')
        ?.forEach(
          s =>
            !optionsIds.includes(s?.exercise._id) &&
            questionTests.push({
              text: s?.exercise.name,
              key: s?.exercise._id,
              value: s?.exercise._id,
            }),
        )
      options = options.concat([...questionTests])
    } else {
      options = [...tests]
    }

    return options
  }
  const requestOptions = (i, formik) => {
    let options = [...allLockedBys]
    let prevValObj
    while (i > -1) {
      let prevVal = formik.values.level[i].exercise
      if (formik.values.level[i].type === 'wex') {
        prevValObj = wexs.find(w => w.value === prevVal)
      } else {
        prevValObj = tests.find(w => w.value === prevVal)
      }
      if (prevValObj) options.push(prevValObj)
      --i
    }
    return Array.from(new Set(options))
  }

  return (
    <div className="p-2 border-2 solid rounded-md">
      <Segment className="border-none shadow-none  bg-transparent">
        {errorGettingWexs ? (
          <Message error list={formatResponseError(errorGettingWexs)} />
        ) : null}
        {errorGettingTests ? (
          <Message error list={formatResponseError(errorGettingTests)} />
        ) : null}
        {errorGettingLockedBy ? (
          <Message error list={formatResponseError(errorGettingLockedBy)} />
        ) : null}
        {errorSavingLevel ? (
          <Message error list={formatResponseError(errorSavingLevel)} />
        ) : null}
        {errorGettingLevel ? (
          <Message error list={formatResponseError(errorGettingLevel)} />
        ) : null}

        <Dimmer
          className="h-full"
          active={savingLevel || gettingLevel}
          inverted
        >
          <Loader active={true} />
        </Dimmer>

        <Formik
          initialValues={{
            level:
              levelData.length > 0
                ? levelData?.map(s => ({
                    type: s.exercise.type,
                    exercise: s.exercise._id,
                    lock: s.lock?.lock.map(l => l._id),
                  }))
                : [
                    {
                      exercise: '',
                      lock: [],
                      type: 'wex',
                    },
                    // {
                    //   exercise: '',
                    //   lock: [],
                    //   type: 'wex',
                    // },
                    {
                      exercise: '',
                      lock: [],
                      type: 'test',
                    },
                  ],
          }}
          validationSchema={homeworkRoomSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {formik => (
            <Form className="mt-4" onSubmit={formik.handleSubmit}>
              <FieldArray name="level">
                {({insert, remove, push}) => (
                  <div>
                    <div class="flex justiy-end ">
                      <p
                        className="text-primary cursor-pointer hover:underline hover:text-primary-hover ml-auto mr-4"
                        onClick={() =>
                          push({
                            exercise: '',
                            lock: [],
                            type: 'wex',
                          })
                        }
                      >
                        Add new Wex
                      </p>
                      <p
                        className="text-primary cursor-pointer hover:underline hover:text-primary-hover mr-10"
                        onClick={() =>
                          push({
                            exercise: '',
                            lock: [],
                            type: 'test',
                          })
                        }
                      >
                        Add new Test
                      </p>
                    </div>

                    {formik.values.level.length > 0
                      ? formik.values.level.map((r, i) => {
                          const {touched, errors} = formik
                          const hasTouchedexercise =
                            touched.level && touched.level[i]?.exercise
                          const hasexerciseError =
                            errors.level && errors.level[i]?.exercise

                          const hasTouchedTEst =
                            touched.level && touched.level[i]?.lock
                          const hasTEstError =
                            errors.level && errors.level[i]?.lock

                          let levelExerciseOption = null
                          if (
                            levelData.length > 0 &&
                            levelData[i]?.exercise?.type ===
                              formik.values.level[i].type
                          ) {
                            const s = levelData[i]
                            levelExerciseOption = {
                              text: s.exercise.name,
                              key: s.exercise._id,
                              value: s.exercise._id,
                              type: s.exercise.type,
                            }
                          }

                          return (
                            <div className="flex justify-between gap-2 flex-wrap mb-4" key={i}>
                              <div class="flex-grow md:w-5/12">
                                {formik.values.level[i].type === 'wex' ? (
                                  <FormikDropdown
                                    label="WEX"
                                    placeholder="select a wex"
                                    options={getAllWexs(levelExerciseOption)}
                                    name={`level.${i}.exercise`}
                                    loading={gettingWexs}
                                    error={
                                      hasTouchedexercise && hasexerciseError
                                    }
                                  />
                                ) : (
                                  <FormikDropdown
                                    label="Test"
                                    placeholder="select a test"
                                    options={getAllTests(levelExerciseOption)}
                                    name={`level.${i}.exercise`}
                                    loading={gettingTests}
                                    error={
                                      hasTouchedexercise && hasexerciseError
                                    }
                                  />
                                )}
                                {/* <FormikDropdown
                                label={formik.values.level[i].type === 'wex' ? 'WEX':'Test'}
                                  placeholder="select a wex"
                                  options={testOptions1}
                                  name={`level.${i}.exercise`}
                                  // error={
                                  //   hasTouchedFeedbackMessage &&
                                  //   hasFeedbackMessageError
                                  // }
                                /> */}
                              </div>
                              <div class="flex-grow md:w-5/12">
                                <FormikDropdown
                                  label="Locked by"
                                  placeholder="please select a wex first"
                                  loading={gettingLockedBy}
                                  options={requestOptions(i - 1, formik)}
                                  name={`level.${i}.lock`}
                                  multiple
                                  disabled={
                                    !Boolean(formik.values.level[i].exercise)
                                  }
                                  error={hasTouchedTEst && hasTEstError}
                                />
                              </div>
                              <div class="flex-grow md:w-1/12 flex items-end">
                                {i > 2 ? (
                                  <Button
                                    type="button"
                                    icon
                                    basic
                                    onClick={() => remove(i)}
                                  >
                                    <AiOutlineDelete />
                                  </Button>
                                ) : null}
                              </div>
                            </div>
                          )
                        })
                      : null}
                  </div>
                )}
              </FieldArray>

              <div class="flex justify-end w-full mt-8 -ml-7">
                <Button primary type="submit">
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Segment>
    </div>
  )
}

export default CreateEditHomeWorkLevel
