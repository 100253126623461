import React from 'react'
import {useParams} from 'react-router-dom'
import {Dimmer, Loader, Message, Segment} from 'semantic-ui-react'

import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import {formatResponseError} from '../../utils/format-response'

const TeacherBasicInfo = () => {
  const [teacherData, setTeacherData] = React.useState()
  const {userid} = useParams()

  const {
    run: getTeacherData,
    error: errorGettingTeacherData,
    isLoading: gettingTeacherData,
  } = useAsync()

  React.useEffect(() => {
    getTeacherData(
      authAxios.get(`/admin/users/info/${userid}?userType=instructor`),
    ).then(({data}) => {
      setTeacherData(data)
      console.log(data)
    })
  }, [getTeacherData, userid])

  return (
    <div className="mt-10">
      <Segment className="border-none shadow-none bg-transparent">
        <Dimmer active={gettingTeacherData} inverted>
          <Loader active={true} />
        </Dimmer>

        {errorGettingTeacherData ? (
          <Message error list={formatResponseError(errorGettingTeacherData)} />
        ) : null}
        <div className="flex my-6">
          <div className=" w-1/6 p-3 rounded-md">Teacher's name</div>
          <div className="bg-gray-200 w-2/6 p-3 rounded-md ml-4">
            {teacherData?.name}
          </div>
        </div>
        <div className="flex my-6">
          <div className=" w-1/6 p-3 rounded-md">Email</div>
          <div className="bg-gray-200 w-2/6 p-3 rounded-md ml-4">
            {teacherData?.email}
          </div>
        </div>
        <div className="flex my-6">
          <div className=" w-1/6 p-3 rounded-md">School</div>
          <div className="bg-gray-200 w-2/6 p-3 rounded-md ml-4">
            {teacherData?.school?.name}
          </div>
        </div>
        <div className="flex my-6">
          <div className=" w-1/6 p-3 rounded-md">Subject</div>
          <div className="bg-gray-200 w-2/6 p-3 rounded-md ml-4">
            {teacherData?.subject?.name}
          </div>
        </div>
      </Segment>
    </div>
  )
}

export default TeacherBasicInfo
