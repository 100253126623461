import React, {useState} from 'react'
import {
  Dimmer,
  Dropdown,
  Image,
  Loader,
  Message,
  Pagination,
  Segment,
  Table,
} from 'semantic-ui-react'
import queryString from 'query-string'

import numOfUsers from '../assets/images/numOfUsers.svg'
import numOfPayingStudents from '../assets/images/numOfPayingStudents.svg'
import numOfVoucherStudents from '../assets/images/numOfVoucherStudents.svg'
import teachersimg from '../assets/images/teachers.svg'
import classrooms from '../assets/images/classrooms.svg'
import useAsync from '../hooks/use-async'
import {authAxios} from '../config/axios-config'
import {formatResponseError} from '../utils/format-response'
import {useHistory, useLocation} from 'react-router-dom'

const Dashboard = () => {
  const [staticData, setstaticData] = useState()
  const [teachers, setTeachers] = React.useState([])
  const [forceUpdate, setForceUpdate] = useState(false)

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [limit, setLimit] = useState(10)

  const location = useLocation()
  const history = useHistory()

  const pendingStatusOptions = React.useMemo(
    () => [
      {text: 'Accepted', value: 'Accepted', key: 'Accepted'},
      {text: 'Pending', value: 'Pending', key: 'Pending'},
      {text: 'Rejected', value: 'Rejected', key: 'Rejected'},
    ],
    [],
  )

  const statusOptions = React.useMemo(
    () => [
      {text: 'Accepted User', value: 'true', key: 'Accepted'},
      {text: 'Rejected User', value: 'false', key: 'Rejecte'},
    ],
    [],
  )

  const {
    run: getStaticData,
    isLoading: gettingStaticData,
    error: errorGettingStaticData,
  } = useAsync()

  const {
    run: getTeacherRequest,
    isLoading: gettingTeacherRequest,
    error: errorGettingTeacherRequest,
  } = useAsync()

  const {
    run: updateStatus,
    isLoading: updatingStatus,
    error: errorUpdateingStatus,
  } = useAsync()

  React.useEffect(() => {
    getStaticData(authAxios.get(`/admin/statistics`)).then(({data}) => {
      console.log(data)
      setstaticData(data)
    })
  }, [getStaticData, forceUpdate])

  // React.useEffect(() => {
  //   getTeacherRequest(authAxios.get(`/admin/requests/instructor`)).then(
  //     ({data}) => {
  //       console.log(data)
  //       setTeachers(data.docs)

  //       // setstaticData(data)
  //     },
  //   )
  // }, [getTeacherRequest, forceUpdate])
  const handleOnPageChange = (e, {activePage}) => {
    history.push(`${location.pathname}?page=${activePage}&limit=${limit}`)
  }

  React.useEffect(() => {
    const {page = 1, limit = 10} = queryString.parse(location.search)
    setPage(page)
    setLimit(limit)

    getTeacherRequest(
      authAxios.get(`/student/verify/all?page=${page}&limit=${limit}`),
    ).then(({data}) => {
      console.log({data})
      setTeachers(data.docs)
      setTotalPages(data.totalPages)

      // setstaticData(data)
    })
  }, [getTeacherRequest, forceUpdate, location.search])

  const onStatusChange = (value, id) => {
    // console.log(value, id)
    updateStatus(authAxios.post(`student/verify/${id}?status=${value}`)).then(
      ({data}) => {
        setForceUpdate(p => !p)
        console.log(data)
      },
    )
  }
  // const onStatusChange = (value, id) => {
  //   // console.log(value, id)
  //   updateStatus(
  //     authAxios.put(`/admin/approve/instructor/${id}?status=${value}`),
  //   ).then(({data}) => {
  //     setForceUpdate(p => !p)
  //     console.log(data)
  //   })
  // }

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent">
        <Dimmer
          active={gettingStaticData || gettingTeacherRequest || updatingStatus}
          inverted
        >
          <Loader active={true} />
        </Dimmer>

        {errorGettingStaticData ? (
          <Message error list={formatResponseError(errorGettingStaticData)} />
        ) : null}

        {errorGettingTeacherRequest ? (
          <Message
            error
            list={formatResponseError(errorGettingTeacherRequest)}
          />
        ) : null}

        {errorUpdateingStatus ? (
          <Message error list={formatResponseError(errorUpdateingStatus)} />
        ) : null}

        <h3 className=" text-primary mt-12">Dashboard</h3>

        <div className="w-24 h-1 rounded-full bg-gradient-to-r from-primary to-tableHeader"></div>

        <div className="mt-12 flex flex-wrap gap-x-4 xl:gap-x-4 gap-y-4  items-center ">
          <div className="w-60 h-36 shadow-xl rounded-2xl ">
            <p className="font-semibold text-sm text-gray-400 p-4 mt-2">
              Number of users
            </p>
            <hr className="mb-4" />
            <div className="flex justify-between px-4">
              <p className="text-2xl font-semibold">{staticData?.user}</p>
              <Image src={numOfUsers}></Image>
            </div>
          </div>

          <div className="w-60 h-36 shadow-xl rounded-2xl ">
            <p className="font-semibold text-sm text-gray-400 p-4 mt-2">
              Number of paying students
            </p>
            <hr className="mb-4" />
            <div className="flex justify-between px-4">
              <p className="text-2xl font-semibold">{staticData?.nonVoucher}</p>
              <Image src={numOfPayingStudents}></Image>
            </div>
          </div>
          <div className="w-60 h-36 shadow-xl rounded-2xl ">
            <p className="font-semibold text-sm text-gray-400 p-4 mt-2">
              Number of voucher students
            </p>
            <hr className="mb-4" />
            <div className="flex justify-between px-4">
              <p className="text-2xl font-semibold">{staticData?.voucher}</p>
              <Image src={numOfVoucherStudents}></Image>
            </div>
          </div>
          <div className="w-60 h-36 shadow-xl rounded-2xl ">
            <p className="font-semibold text-sm text-gray-400 p-4 mt-2">
              Number of teachers
            </p>
            <hr className="mb-4" />
            <div className="flex justify-between px-4">
              <p className="text-2xl font-semibold">{staticData?.instructor}</p>
              <Image src={teachersimg}></Image>
            </div>
          </div>
          <div className="w-60 h-36 shadow-xl rounded-2xl ">
            <p className="font-semibold text-sm text-gray-400 p-4 mt-2">
              Number of classes
            </p>
            <hr className="mb-4" />
            <div className="flex justify-between px-4">
              <p className="text-2xl font-semibold">{staticData?.class}</p>
              <Image src={classrooms}></Image>
            </div>
          </div>
        </div>

        <h3 className="mt-16"> New Requests</h3>

        <Table striped className="shadow-lg mt-4 mb-16">
          <Table.Header>
            <Table.Row
              className="text-sm"
              textAlign="center"
              verticalAlign="middle"
            >
              <Table.HeaderCell className="bg-tableHeader" width="2">
                Rang
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader" width="2">
                Name
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader" width="2">
                Email
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader" width="2">
                Status
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {teachers?.map((a, idx = 1) => (
              <Table.Row key={a?._id} textAlign="center" verticalAlign="top">
                <Table.Cell>{idx + 1}</Table.Cell>
                <Table.Cell>{`${a?.firstname}  ` + a?.lastname}</Table.Cell>
                <Table.Cell>{a?.email}</Table.Cell>
                <Table.Cell>
                  {
                    <Dropdown
                      placeholder="Status"
                      selection
                      // value={`${a?.isVerified}`}
                      options={statusOptions}
                      onChange={(e, {value}) => onStatusChange(value, a?._id)}
                      // className={`  ${
                      //   a?.isVerified === false
                      //     ? 'bg-red-100 text-red-700'
                      //     : 'bg-green-100 text-green-700'
                      // }  `}
                    />
                  }
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {teachers?.length > 0 && (
          <div className="flex justify-end my-8">
            <Pagination
              activePage={page}
              onPageChange={handleOnPageChange}
              totalPages={totalPages}
            />
          </div>
        )}
      </Segment>
    </div>
  )
}

export default Dashboard
