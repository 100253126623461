import React, {useRef} from 'react'
import {useDrag, useDrop} from 'react-dnd'
import {Icon, Image} from 'semantic-ui-react'
import {ItemTypes} from '../../utils/ItemTypes'
import { truncate } from '../../utils/string'

const ChapterCardOrder = ({
  chapterSrc,
  name,
  brief,
  chapterid,
  subjectid,
  index,
  moveCard,
}) => {
  const ref = useRef(null)
  
  const [{handlerId}, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{isDragging}, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return {chapterid, index}
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  drag(drop(ref))
  const opacity = isDragging ? 0 : 1

  return (
    <div
      ref={ref}
      style={{opacity}}
      data-handler-id={handlerId}
      className="rounded-t-md cursor-move shadow-md mb-4 hover:shadow-xl hover:opacity-95 flex border-4 border-dashed"
    >
      <div className="w-96 h-40">
        <Image
          className="w-96 h-40 rounded-l-md"
          src={chapterSrc}
          alt="no image provided"
        ></Image>
      </div>

      <div className="flex justify-between   w-full my-3 px-3">
        <div className="flex flex-col">
          {/* <Link to={routes.subjects.chapters.update(chapterid)}> */}
          <p className="text-primary text-lg font-semibold">{truncate(name,60)}</p>
          {/* </Link> */}
          <p className="font-semibold h-20 overflow-y-auto ">{brief}</p>
        </div>
        <div className="mt-2">
          <Icon className="text-gray-500" name="sidebar" size="large" />
        </div>
      </div>
    </div>
  )
}

export default ChapterCardOrder
