import React from "react";
import { Controller } from "react-hook-form";
import { FormInput, FormTextArea } from "semantic-ui-react";

const FormTextAreaController = ({
  control,
  name,
  label,
  controlProps,
  inputProps,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      {...controlProps}
      render={({ field }) => {
        return <FormTextArea
        error={control?._formState?.errors?.[name] && control?._formState?.errors?.[name]?.message}
        
         {...field} label={label} {...inputProps} />;
      }}
    />
  );
};

export default FormTextAreaController;