import React from 'react'
import {FastField, Field} from 'formik'
import {Form} from 'semantic-ui-react'

// function resolve(obj, path) {
//   let root = (obj = [obj])
//   path = [0, ...path]
//   while (path.length > 1) obj = obj[path.shift()]
//   return [obj, path[0], root]
// }

// function get(obj, path) {
//   let [parent, key] = resolve(obj, path)
//   return parent[key]
// }

function FormikDropdown({label, name, className, onChangeCB, ...props}) {
  return (
    <>
      <label htmlFor={name} className="font-normal text-base text-labelColor">
        {label}
      </label>
      <Field name={name}>
        {({form, field}) => {
          const {setFieldValue, setFieldTouched, errors, touched} = form

          return (
            <Form.Dropdown
              id={name}
              className={`block mt-2 text-sm ${className}`}
              error={touched[name] && errors[name]}
              fluid
              selection
              {...field}
              {...props}
              onBlur={() => setFieldTouched(name, true)}
              onChange={(e, {value}) => {
                setFieldValue(name, value)
                if (typeof onChangeCB === 'function') onChangeCB(value)
              }}
            />
          )
        }}
      </Field>
    </>
  )
}

export default FormikDropdown
