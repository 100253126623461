import React from 'react'
import {Formik} from 'formik'
import {Form, Button, Message, Dimmer, Loader} from 'semantic-ui-react'
import * as Yup from 'yup'
import {useHistory, useParams, useLocation} from 'react-router'

import useAsync from '../../hooks/use-async'
import {authAxios} from '../../config/axios-config'
import {formatResponseError} from '../../utils/format-response'
import routes from '../../routes'
import PdfsAttachments from '../../components/shared/pdfsAttachments'
import FormikDropdown from '../../components/formik/FormikDropdown'
import useSubject from '../../hooks/use-subjects'
import DefaultContainer from '../../components/shared/default-container'

const CreateEditPdf = () => {
  const history = useHistory()
  const [formErrors, setFormErrors] = React.useState(null)
  const {subjects, errorGettingSubjects, gettingSubjects} = useSubject()

  const [attachmentData, setAttachmentData] = React.useState([])

  const {id} = useParams()

  const location = useLocation()
  const [state] = React.useState(location?.state)

  const pdfAccessOptions = React.useMemo(
    () => [
      {text: 'all', value: 'all', key: 'all'},
      {text: 'instructor', value: 'instructor', key: 'instructor'},
      {text: 'student ', value: 'student ', key: 'student '},
    ],
    [],
  )


  const {
    run: submitPdf,
    error: errorSubmitingPdf,
    isLoading: submitingPdf,
  } = useAsync()

  const newPdfSchema = Yup.object({
    subject: Yup.string().trim().required('Required'),
    access: Yup.string().trim().required('Required'),

  })

  const onsubmitPdf = (values, {resetForm}) => {
    let errors = []

    if (attachmentData.length === 0) {
      errors.push('you have to add File')
    } else {
      setFormErrors(null)
    }

    if (errors.length > 0) {
      setFormErrors(errors)
      return
    }

    const formData = new FormData()

    let sid = values.subject

    if (attachmentData.length > 0) {
      for (let i in attachmentData) {
        formData.append('docs', attachmentData[i])
      }
    }

    submitPdf(authAxios.post(`/school/subject/pdf?sid=${sid}${values.access === 'all' ? '' : `&access=${values.access}`}`, formData)).then(
      ({data}) => {
        setAttachmentData([])
        history.push(routes.pdfs.all, {
          currentSubject: state?.currentSubject,
        })
      },
    )
  }

  
  return (
    <DefaultContainer >
      <h3 className="mt-4 mb-8">Add Pdf</h3>

      {errorSubmitingPdf ? (
        <Message error list={formatResponseError(errorSubmitingPdf)} />
      ) : null}
      {errorGettingSubjects ? (
        <Message error list={formatResponseError(errorGettingSubjects)} />
      ) : null}
      {formErrors?.length > 0 ? <Message error list={formErrors} /> : null}

      <Dimmer active={submitingPdf} inverted>
        <Loader active={true} />
      </Dimmer>
      <div className="max-w-lg">
        <Formik
          initialValues={{
            subject: state?.currentSubject || '',
            access : pdfAccessOptions[0].value || '',

          }}
          validationSchema={newPdfSchema}
          onSubmit={onsubmitPdf}
          enableReinitialize
        >
          {formik => {
            return (
              <Form onSubmit={formik.handleSubmit}>
                <FormikDropdown
                  label="Subject"
                  name="subject"
                  loading={gettingSubjects}
                  options={subjects}
                  placeholder="select the name of subject"
                />
                 <FormikDropdown
                  label="Access"
                  name="access"
                  options={pdfAccessOptions}
                />

                {/* {question?.image && (
                  <Image
                    size="medium"
                    src={question?.image}
                    rounded
                    className="mb-4 mt-8"
                  />
                )} */}
                <PdfsAttachments setAttachmentData={setAttachmentData} />

                {/* <hr /> */}
                <div className="flex justify-between">
                  <Button
                    className="mt-4 mb-8 mr-2"
                    size="tiny"
                    type="submit"
                    primary
                  >
                    Save
                  </Button>
                  <Button
                    className="mt-4 mb-8"
                    size="tiny"
                    onClick={() =>
                      history.push(routes.pdfs.all, {
                        currentSubject: state?.currentSubject,
                      })
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </DefaultContainer>
  )
}

export default CreateEditPdf
