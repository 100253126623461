import {FieldArray, Formik} from 'formik'
import React from 'react'
import {AiOutlineDelete} from 'react-icons/ai'
import {useHistory, useParams} from 'react-router'
import {Button, Form, Message, Modal} from 'semantic-ui-react'
import * as Yup from 'yup'

import FormikDropdown from '../../components/formik/FormikDropdown'
import FormikInput from '../../components/formik/FormikInput'
import DefaultContainer from '../../components/shared/default-container'
import {authAxios} from '../../config/axios-config'
import useAccountNames from '../../hooks/use-account-names'
import useAsync from '../../hooks/use-async'
import routes from '../../routes'
import {formatResponseError} from '../../utils/format-response'

const CreateEditFeedback = () => {
  const [feedbackMessageOptions, setFeedbackMessageOptions] = React.useState()
  const [sectionNames, setSectionNames] = React.useState([])
  const [addingFeedbackMessage, setAddingFeedbackMessage] =
    React.useState(false)
  const [forceReload, setForceReload] = React.useState(false)

  const {
    accountNames,
    gettingAccountNames,
    error: errorGettingAccountNames,
  } = useAccountNames()
  const {
    run: getFeedbackMessages,
    isLoading: gettingFeedbackMessages,
    error: errorGettingFeedbackMessage,
  } = useAsync()
  const {
    run: getFeedback,
    data: feedbackData,
    isLoading: gettingFeedback,
    error: errorGettingFeedback,
  } = useAsync({data: []})
  const {
    run: submitFeedback,
    isLoading: creatingFeedback,
    error: errorCreatingFeedback,
  } = useAsync()

  const {
    run: getSectionNames,
    error: errorGettingSectionNames,
    isLoading: gettingSectionNames,
  } = useAsync()

  const history = useHistory()
  const {id} = useParams()

  const newCategorySchema = Yup.object({
    name: Yup.string().trim().required('Required'),
    relations: Yup.array(
      Yup.object({
        account: Yup.string().required('Required'),
        feedbackMessage: Yup.string().required('Required'),
      }),
    )
      .min(1, `At least one row has to be defined`)
      .required('Required'),
  })

  const onSubmit = values => {
    console.log(values)
    if (id) {
      // update
      submitFeedback(
        authAxios.put(`/question/tablefeedback/category/${id}`, values),
      ).then(() => history.push(routes.feedbacks.all))
    } else {
      // create
      submitFeedback(
        authAxios.post('/question/tablefeedback/category', values),
      ).then(() => history.push(routes.feedbacks.all))
    }
  }

  React.useEffect(() => {
    if (id)
      getFeedback(authAxios.get(`/question/tablefeedback/category?id=${id}`))
  }, [id, getFeedback])

  React.useEffect(() => {
    getFeedbackMessages(authAxios.get(`/question/tablefeedback/all`)).then(
      ({data}) => {
        let options = []
        data?.forEach(d =>
          options.push({text: d.title, value: d._id, key: d._id}),
        )
        setFeedbackMessageOptions(options)
      },
    )
  }, [getFeedbackMessages, forceReload])

  React.useEffect(() => {
    getSectionNames(authAxios.get('/question/taccount/sectionname')).then(
      ({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.name, key: s._id, value: s._id}),
        )
        setSectionNames(options)
      },
    )
  }, [getSectionNames])

  return (
    <DefaultContainer>
      <h3 className="mt-4 mb-8">
        {id ? 'Update' : 'Create new'} feedback category
      </h3>

      {errorGettingAccountNames ? (
        <Message error list={formatResponseError(errorGettingAccountNames)} />
      ) : null}
      {errorGettingFeedback ? (
        <Message error list={formatResponseError(errorGettingFeedback)} />
      ) : null}
      {errorCreatingFeedback ? (
        <Message error list={formatResponseError(errorCreatingFeedback)} />
      ) : null}
      {errorGettingFeedbackMessage ? (
        <Message
          error
          list={formatResponseError(errorGettingFeedbackMessage)}
        />
      ) : null}
      {errorGettingSectionNames ? (
        <Message error list={formatResponseError(errorGettingSectionNames)} />
      ) : null}
      <Formik
        initialValues={{
          name: feedbackData[0]?.category?.name || '',
          relations:
            feedbackData.length > 0
              ? feedbackData?.map(f => ({
                  account: f?.account?._id,
                  feedbackMessage: f?.feedbackMessage?._id,
                }))
              : [
                  {
                    account: '',
                    feedbackMessage: '',
                  },
                ],
        }}
        validationSchema={newCategorySchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {formik => (
          <Form
            loading={
              gettingAccountNames ||
              gettingFeedbackMessages ||
              creatingFeedback ||
              gettingFeedback
            }
            onSubmit={formik.handleSubmit}
          >
            <FormikInput
              label="Feedback category name"
              name="name"
              className="w-2/5"
            />
            <FieldArray name="relations">
              {({insert, remove, push}) => (
                <div>
                  <div class="flex justiy-end mb-4">
                    <p
                      className="text-primary ml-auto cursor-pointer hover:underline hover:text-primary-hover mr-8"
                      onClick={() => setAddingFeedbackMessage(true)}
                    >
                      Add new feedback message
                    </p>
                    <p
                      className="text-primary cursor-pointer hover:underline hover:text-primary-hover"
                      onClick={() =>
                        push({
                          account: '',
                          feedbackMessage: '',
                        })
                      }
                    >
                      Add new row
                    </p>
                  </div>

                  {formik.values.relations.length > 0
                    ? formik.values.relations.map((r, i) => {
                        const {touched, errors} = formik
                        const hasTouchedAccount =
                          touched.relations && touched.relations[i]?.account
                        const hasAccountError =
                          errors.relations && errors.relations[i]?.account

                        const hasTouchedFeedbackMessage =
                          touched.relations &&
                          touched.relations[i]?.feedbackMessage
                        const hasFeedbackMessageError =
                          errors.relations &&
                          errors.relations[i]?.feedbackMessage

                        return (
                          <div
                            className="flex justify-between gap-2 flex-wrap mb-4"
                            key={i}
                          >
                            <div class="flex-grow md:w-2/12">
                              <FormikDropdown
                                search
                                label="Feedback"
                                options={feedbackMessageOptions}
                                name={`relations.${i}.feedbackMessage`}
                                error={
                                  hasTouchedFeedbackMessage &&
                                  hasFeedbackMessageError
                                }
                              />
                            </div>
                            <div class="flex-grow md:w-2/12">
                              <FormikDropdown
                                search
                                label="Account"
                                options={accountNames}
                                name={`relations.${i}.account`}
                                error={hasTouchedAccount && hasAccountError}
                              />
                            </div>
                            <div class="flex-grow md:w-2/12 flex items-end">
                              {/* <div className="w-full">
                                <FormikDropdown
                                search
                                  className="mr-2"
                                  label="Section Name"
                                  options={sectionNames}
                                  name={`relations.${i}.sectionName`}
                                  loading={gettingSectionNames}
                                />
                              </div> */}
                              <Button
                                type="button"
                                icon
                                basic
                                onClick={() => remove(i)}
                              >
                                <AiOutlineDelete />
                              </Button>
                            </div>
                          </div>
                        )
                      })
                    : null}
                </div>
              )}
            </FieldArray>
            {formik.touched.relations &&
              formik.errors.relations &&
              !Array.isArray(formik.errors.relations) && (
                <p
                  className="px-4 py-2 text-red-700 bg-red-50 border border-red-700 opacity-60 rounded-md"
                  size="small"
                >
                  {formik.errors.relations}
                </p>
              )}
            <div class="flex items-center justify-between mt-8">
              <Button onClick={() => history.push(routes.feedbacks.all)}>
                Cancel
              </Button>
              <Button primary type="submit">
                {id ? 'Update' : 'Create'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {addingFeedbackMessage && (
        <NewFeedbackMessageModal
          open={addingFeedbackMessage}
          onClose={() => setAddingFeedbackMessage(false)}
          reload={() => setForceReload(p => !p)}
        />
      )}
    </DefaultContainer>
  )
}

export default CreateEditFeedback

const NewFeedbackMessageModal = ({open, onClose, reload}) => {
  const {run, isLoading, error} = useAsync()

  const newFeedbackMessageSchema = Yup.object({
    title: Yup.string().trim().required('Required'),
    debitMessage: Yup.string().trim().required('Required'),
    creditMessage: Yup.string().trim().required('Required'),
  })

  const onCreate = values => {
    run(authAxios.post('/question/tablefeedback', values)).then(() => {
      reload()
      onClose()
    })
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      size="tiny"
      closeIcon
    >
      <Modal.Header>New feedback message</Modal.Header>
      <Modal.Content>
        {error ? <Message error list={formatResponseError(error)} /> : null}
        <Formik
          initialValues={{
            title: '',
            debitMessage: '',
            creditMessage: '',
          }}
          onSubmit={onCreate}
          validationSchema={newFeedbackMessageSchema}
        >
          {formik => (
            <Form onSubmit={formik.submitForm} loading={isLoading}>
              <FormikInput label="Title" name="title" />
              <FormikInput label="Debit message" name="debitMessage" />
              <FormikInput label="Credit message" name="creditMessage" />
              <Button primary className="my-5">
                Add
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}
