import {Formik} from 'formik'
import React from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Dimmer, Form, Image, Loader, Message, Segment} from 'semantic-ui-react'
import FormikInput from '../../../components/formik/FormikInput'
import {authAxios} from '../../../config/axios-config'
import useAsync from '../../../hooks/use-async'
import {formatResponseError} from '../../../utils/format-response'

const BasicChapterReview = () => {
  const [question, setQuestion] = React.useState()

  const [attachmentData, setAttachmentData] = React.useState(null)

  const {chapterid, subjectid} = useParams()

  const {
    run: getchapter,
    error: errorGettingchapter,
    isLoading: gettingchapter,
  } = useAsync()

  React.useEffect(() => {
    if (chapterid)
      getchapter(authAxios.get(`/chapter/${chapterid}`)).then(({data}) => {
        setQuestion(data)
        setAttachmentData(question?.image)
      })
  }, [chapterid, getchapter, question?.image])

  return (
    <div>
      <Segment>
       

        {errorGettingchapter ? (
          <Message error list={formatResponseError(errorGettingchapter)} />
        ) : null}

        <Dimmer active={gettingchapter} inverted>
          <Loader active={true} />
        </Dimmer>
        <div className="max-w-lg">
          <Formik
            initialValues={{
              chapterName: question?.chapterName || '',
              brief: question?.brief || '',
            }}
            enableReinitialize
          >
            {formik => {
              return (
                <Form onSubmit={formik.handleSubmit}>
                  <FormikInput name="chapterName" label="Chapter Name" disabled/>

                  <FormikInput name="brief" label="Brief about chapter"  disabled/>

                  {question?.image && (
                    <Image
                      size="medium"
                      src={question?.image}
                      rounded
                      className="mb-4 mt-8"
                    />
                  )}
                </Form>
              )
            }}
          </Formik>
        </div>
      </Segment>
    </div>
  )
}

export default BasicChapterReview
