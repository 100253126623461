/**
 * @params take an array of objects to append it into formdata
 * 
 */
export const convertArrayOfObjToFormData = (formData, arr, filedName) => {
  arr.forEach((f, i) => {
    Object.entries(f).forEach(([k, v]) => {
      formData.append(`${filedName}[${i}][${k}]`, v)
    })
  })
}
