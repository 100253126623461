import React, {useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Button, Dimmer, Loader, Message, Segment} from 'semantic-ui-react'
import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import routes from '../../routes'
import {formatResponseError} from '../../utils/format-response'
import {truncate} from '../../utils/string'
import ChapterTabs from './chapter-tabs'
import HomeworkRoom from './homework-room'
import PracticeRoom from './practice-room'

const ChapterContent = () => {
  const [activeMenu, setActiveMenu] = useState('practicRoom')
  const [chapterData, setChapterData] = React.useState()
  const [forceRelood, setForceRelood] = React.useState(false)
  const history = useHistory()

  const {run: archieveChapter, isLoading: archievingChapter} = useAsync()

  const {subjectid, chapterid} = useParams()

  const {
    run: getchapter,
    error: errorGettingchapter,
    isLoading: gettingchapter,
  } = useAsync()

  React.useEffect(() => {
    if (chapterid)
      getchapter(authAxios.get(`/chapter/${chapterid}`)).then(({data}) => {
        setChapterData(data)
      })
  }, [chapterid, getchapter, forceRelood])

  const onArchieved = () => {
    let obj = {
      status: 'Archived',
    }
    archieveChapter(
      authAxios.patch(`/chapter/status?cid=${chapterid}`, obj),
    ).then(({data}) => {
      setForceRelood(p => !p)
      history.push(routes.subjects.chapters.all(subjectid))
    })
  }
  
  let chName = chapterData?.chapterName || ''

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent w-full">

      {errorGettingchapter ? (
        <Message error list={formatResponseError(errorGettingchapter)} />
      ) : null}
      <Dimmer active={archievingChapter || gettingchapter} inverted>
        <Loader active={true} />
      </Dimmer>
      <p className="my-2 text-3xl font-semibold ml-2 ">
        Chapter: {truncate(chName, 55)}
      </p>

      <div className="flex justify-between items-center">
        <ChapterTabs
          activeMenu={activeMenu}
          setActiveMenu={value => setActiveMenu(value)}
        />
        {chapterData?.status === 'Incomplete' ? (
          <Button
            className="text-primary bg-transparent border-solid hover:text-white hover:bg-primary rounded-md"
            onClick={() => {
              history.push(
                routes.subjects.chapters.review(subjectid, chapterid),
              )
            }}
          >
            Review Chapter
          </Button>
        ) : chapterData?.status === 'Archived' ? (
          <Button
            className="text-primary bg-transparent border-solid hover:text-white hover:bg-primary rounded-md"
            onClick={() => {
              history.push(
                routes.subjects.chapters.review(subjectid, chapterid),
              )
            }}
          >
            Review Chapter
          </Button>
        ) : (
          <Button
            className="text-primary bg-transparent border-solid hover:text-white hover:bg-primary rounded-md"
            onClick={onArchieved}
          >
            Archieve Chapter
          </Button>
        )}
        {/* <Button
          className="text-primary bg-transparent border-solid hover:text-white hover:bg-primary rounded-md"
          onClick={() => {
            history.push(routes.subjects.chapters.review(subjectid, chapterid))
          }}
        >
          Review Chapter
        </Button> */}
      </div>
      <hr />
      <div className="mt-2">
        {activeMenu === 'practicRoom' && <PracticeRoom />}
        {activeMenu === 'homeworkRoom' && <HomeworkRoom />}
      </div>
      </Segment>

    </div>
  )
}

export default ChapterContent
