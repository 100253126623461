import React, {useState} from 'react'
import {useHistory, useLocation} from 'react-router'
import {
  Button,
  Dimmer,
  Input,
  Loader,
  Message,
  Modal,
  Pagination,
  Table,
} from 'semantic-ui-react'
import queryString from 'query-string'

import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import { formatResponseError } from '../../utils/format-response'
import { truncate } from '../../utils/string'
const QuestionModal = ({open, onClose, type, sendDataToParent, subject}) => {
  const [questions, setQuestions] = React.useState([])

  const [searchInput, setSearchInput] = useState('')
  const [searchKeyword, setSearchkeyword] = useState('')

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [limit, setLimit] = useState(10)

  const location = useLocation()
  const history = useHistory()
  const {
    run: getQuestion,
    error: errorGettingQuestion,
    isLoading: gettingQuestion,
  } = useAsync()

  React.useEffect(() => {
    const {
      page = 1,
      limit = 10,
      search = '',
    } = queryString.parse(location.search)
    setPage(page)
    setLimit(limit)
    setSearchInput(search)
    setSearchkeyword(search)

    if (type)
      getQuestion(
        authAxios.get(
          `/question/?type=${type}&page=${page}&limit=${limit}&search=${search}&subject=${subject}`,
        ),
      ).then(({data}) => {
        setQuestions(data.docs)
        setTotalPages(data.totalPages)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuestion, location.search, type , subject])

  React.useEffect(() => {
    history.replace(
      `${location.pathname}?page=${1}&limit=${10}&search=`,
    )
    setSearchInput('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!open])

  const handleOnPageChange = (e, {activePage}) => {
    history.push(
      `${location.pathname}?page=${activePage}&limit=${limit}&search=${searchKeyword}`,
    )
  }

  const onSearchClick = () => {
    setSearchkeyword(searchInput)
    history.push(
      `${location.pathname}?page=${1}&limit=${limit}&search=${searchInput}`,
    )
  }



  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      size="large"
      closeIcon
    >
      <Modal.Header>Choose a question</Modal.Header>
      <Modal.Content>

      {errorGettingQuestion ? (
          <Message error list={formatResponseError(errorGettingQuestion)} />
        ) : null}
        <Dimmer active={gettingQuestion} inverted>
          <Loader active={true} />
        </Dimmer>
        <div className="flex justify-between align-center">
          <h1 className="text-3xl">Add Question</h1>
          <div>
            <Input
              type="text"
              placeholder="Search..."
              size="small"
              className="mr-4"
              action
              value={searchInput}
              onChange={(e, data) => {
                setSearchInput(data.value)
              }}
            >
              <input />
              <Button type="button" onClick={onSearchClick}>
                Search
              </Button>
            </Input>
          </div>
        </div>

        <div className="mt-12">
          <Table celled striped className="shadow-lg">
            <Table.Header>
              <Table.Row className="text-sm">
               
                <Table.HeaderCell className="bg-tableHeader">
                  Question Title
                </Table.HeaderCell>
                <Table.HeaderCell className="bg-tableHeader">
                  Subject
                </Table.HeaderCell>
                <Table.HeaderCell className="bg-tableHeader">
                  Difficulty
                </Table.HeaderCell>
                <Table.HeaderCell className="bg-tableHeader">
                  Competency
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {questions?.map((a, idx) => (
                <Table.Row key={a._id} textAlign="left" verticalAlign="top" className="cursor-pointer hover:text-white hover:bg-primary-hover" 
                onClick={()=>{
                    sendDataToParent({
                        questionId:a._id,
                        questionTitle:a.questionTitle,
                    })
                     onClose()
                }}>
                  <Table.Cell>{truncate(a.questionTitle,35)}</Table.Cell>
                  <Table.Cell>{truncate(a.subject.name,35)}</Table.Cell>
                  <Table.Cell>{truncate(a?.difficulty?.name,35)}</Table.Cell>
                  <Table.Cell>{truncate(a?.competency?.name,35)}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>

        <div className="flex justify-between my-8">
        <Button primary size='tiny' className='py-0' onClick={()=> onClose()}>
                Close
        </Button>
          <Pagination
            activePage={page}
            onPageChange={handleOnPageChange}
            totalPages={totalPages}
          />
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default QuestionModal
