import React, {useEffect, useState} from 'react'
import queryString from 'query-string'
import {
  Button,
  Confirm,
  Dimmer,
  Dropdown,
  Input,
  Loader,
  Message,
  Pagination,
  Segment,
  Table,
} from 'semantic-ui-react'
import {useHistory, useLocation} from 'react-router-dom'
import {AiOutlineEye, AiOutlineMinusCircle} from 'react-icons/ai'

import useAsync from '../hooks/use-async'
import {authAxios, axios} from '../config/axios-config'
import {formatResponseError} from '../utils/format-response'
import routes from '../routes'

const Teacher = () => {
  const [teachers, setTeachers] = React.useState([])
  const [schools, setSchools] = useState([])
  const [subjects, setSubjects] = useState([])

  const [isDeleting, setIsDeleting] = React.useState(false)
  const [forceReload, setForceReload] = React.useState(false)

  const [searchKeyword, setSearchKeyword] = useState('')
  const [currentSchool, setCurrentSchool] = useState('')
  const [currentSubject, setCurrentSubject] = useState('')

  const [forceUpdate, setForceUpdate] = useState(false)

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [limit, setLimit] = useState(10)

  const location = useLocation()
  const history = useHistory()

  const pendingStatusOptions = React.useMemo(
    () => [
      {text: 'Accepted', value: 'Accepted', key: 'Accepted'},
      {text: 'Pending', value: 'Pending', key: 'Pending'},
      {text: 'Rejected', value: 'Rejected', key: 'Rejected'},
    ],
    [],
  )

  const statusOptions = React.useMemo(
    () => [
      {text: 'Accepted', value: 'Accepted', key: 'Accepted'},
      {text: 'Rejected', value: 'Rejected', key: 'Rejected'},
    ],
    [],
  )

  const {
    run: getSubjects,
    isLoading: gettingSubjects,
    error: errorGettingSubjects,
  } = useAsync()
  const {
    run: getSchools,
    isLoading: gettingSchools,
    error: errorGettingSchools,
  } = useAsync()
  const {
    run: getTeachers,
    isLoading: gettingTeachers,
    error: errorGettingTeachers,
  } = useAsync()
  const {
    run: updateStatus,
    isLoading: updatingStatus,
    error: errorUpdateingStatus,
  } = useAsync()

  const {
    run: deleteTeacher,
    error: errorDeletingTeacher,
    isLoading: deletingTeacher,
  } = useAsync()

  React.useEffect(() => {
    const {
      page = 1,
      limit = 10,
      search = '',
    } = queryString.parse(location.search)

    setPage(page)
    setLimit(limit)
    setSearchKeyword(search)

    // if (currentSchool && currentSubject) {
    getTeachers(
      authAxios.get(
        `/admin/users/instructor?page=${page}&limit=${limit}&search=${search}&subject=${currentSubject}&school=${currentSchool}`,
      ),
    ).then(({data}) => {
      console.log(data)
      setTeachers(data.docs)
      setTotalPages(data.totalPages)
    })
    // }
  }, [
    currentSchool,
    currentSubject,
    getTeachers,
    location.search,
    forceUpdate,
    forceReload,
  ])

  useEffect(() => {
    getSubjects(axios.get('/school/subject/all')).then(({data}) => {
      let subjectsOptions = [{text: '', key: '1', value: ''}]

      data?.subjects?.map(d =>
        subjectsOptions.push({
          key: d._id,
          text: d.name,
          value: d._id,
        }),
      )
      setSubjects(subjectsOptions)
      setCurrentSubject(subjectsOptions[0]?.value)
    })

    getSchools(axios.get('/school/all')).then(({data}) => {
      let schoolOptions = [{text: '', key: '1', value: ''}]

      data?.schools?.map(d =>
        schoolOptions.push({
          key: d._id,
          text: d.name,
          value: d._id,
        }),
      )
      setSchools(schoolOptions)
      setCurrentSchool(schoolOptions[0]?.value)
    })
  }, [getSubjects, getSchools])

  const onSearchClick = () => {
    history.push(
      `${location.pathname}?page=${1}&limit=${limit}&search=${searchKeyword}`,
    )
  }
  const handleOnPageChange = (e, {activePage}) => {
    history.push(
      `${location.pathname}?page=${activePage}&limit=${limit}&search=${searchKeyword}`,
    )
  }

  const onSchoolChange = (e, {value}) => {
    setCurrentSchool(value)

    history.replace(`${location.pathname}?currentschoolid=${value}`)
  }
  const onSubjectChange = (e, {value}) => {
    setCurrentSubject(value)

    history.replace(`${location.pathname}?currentsubjectid=${value}`)
  }

  const onStatusChange = (value, id) => {
    // console.log(value, id)
    updateStatus(
      authAxios.put(`/admin/approve/instructor/${id}?status=${value}`),
    ).then(({data}) => {
      setForceUpdate(p => !p)
      console.log(data)
    })
  }

  const handleConfirm = () => {
    deleteTeacher(authAxios.delete(`/instructor/${isDeleting?._id}`)).then(
      ({data}) => {
        console.log(data)
        setForceReload(p => !p)
      },
    )
    setIsDeleting(false)
  }

  const handleCancel = () => {
    setIsDeleting(false)
  }

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent">
        <Dimmer
          active={gettingTeachers || updatingStatus || deletingTeacher}
          inverted
        >
          <Loader active={true} />
        </Dimmer>

        {errorGettingSubjects ? (
          <Message error list={formatResponseError(errorGettingSubjects)} />
        ) : null}
        {errorGettingSchools ? (
          <Message error list={formatResponseError(errorGettingSchools)} />
        ) : null}
        {errorGettingTeachers ? (
          <Message error list={formatResponseError(errorGettingTeachers)} />
        ) : null}
        {errorUpdateingStatus ? (
          <Message error list={formatResponseError(errorUpdateingStatus)} />
        ) : null}
        {errorDeletingTeacher ? (
          <Message error list={formatResponseError(errorDeletingTeacher)} />
        ) : null}

        <div>
          <Input
            type="text"
            placeholder="Search..."
            size="small"
            className="mr-4"
            action
            value={searchKeyword}
            onChange={(e, data) => {
              setSearchKeyword(data.value)
            }}
          >
            <input />
            <Button type="button" onClick={onSearchClick}>
              Search
            </Button>
          </Input>
        </div>
        <div className="flex mt-16">
          <Dropdown
            placeholder="Schools"
            search
            selection
            value={currentSchool}
            loading={gettingSchools}
            options={schools}
            onChange={onSchoolChange}
            className="drop-shadow-md shadow-md ml-auto"
          />
          <Dropdown
            placeholder="Subjects"
            search
            selection
            value={currentSubject}
            loading={gettingSubjects}
            options={subjects}
            onChange={onSubjectChange}
            className=" drop-shadow-md shadow-md  mx-2"
          />
        </div>

        <Table striped className="shadow-lg ">
          <Table.Header>
            <Table.Row
              className="text-sm"
              textAlign="center"
              verticalAlign="middle"
            >
              <Table.HeaderCell className="bg-tableHeader" width="2">
                Teachers's name
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader" width="2">
                Email
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader" width="2">
                School
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader" width="2">
                Subject
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader" width="2">
                Status
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader" width="2">
                Actions
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {teachers?.map((a, idx) => (
              <Table.Row key={a?._id} textAlign="center" verticalAlign="top">
                <Table.Cell>{a?.name}</Table.Cell>
                <Table.Cell>{a.email}</Table.Cell>
                <Table.Cell>{a?.school?.name}</Table.Cell>
                <Table.Cell>{a?.subject?.name}</Table.Cell>

                <Table.Cell>
                  {a?.status === 'Pending' ? (
                    <Dropdown
                      placeholder="Status"
                      selection
                      value={a?.status}
                      options={pendingStatusOptions}
                      onChange={(e, {value}) => onStatusChange(value, a?._id)}
                      className={`  bg-yellow-100 text-yellow-700 `}
                    />
                  ) : (
                    <Dropdown
                      placeholder="Status"
                      selection
                      value={a?.status}
                      options={statusOptions}
                      onChange={(e, {value}) => onStatusChange(value, a?._id)}
                      className={`  ${
                        a?.status === 'Rejected'
                          ? 'bg-red-100 text-red-700'
                          : 'bg-green-100 text-green-700'
                      }  `}
                    />
                  )}
                </Table.Cell>
                <Table.Cell className=" flex items-center justify-center">
                  <Button size="small" className=" bg-transparent ">
                    <AiOutlineEye
                      size="16"
                      onClick={() =>
                        history.push(routes.teacherInfo.teacherInfo(a?._id))
                      }
                    />
                  </Button>
                  <Button size="small" className=" bg-transparent p-0 ">
                    <AiOutlineMinusCircle
                      size="14"
                      onClick={() => setIsDeleting(a)}
                    />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Confirm
          content={
            <div className="p-6">
              <p className="text-xl font-semibold">
                Are you sure to delete {isDeleting?.name} ?
              </p>
              {/* <p className="text-sm mt-1">
                by removing this Instructor you will affect the grades and records
                of all participant students
              </p> */}
            </div>
          }
          open={isDeleting}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          cancelButton="Cancel"
          confirmButton={
            <Button negative className="cofirmDelete bg-red-600 ">
              Delete
            </Button>
          }
        />

        {teachers.length > 0 && (
          <div className="flex justify-end my-8">
            <Pagination
              activePage={page}
              onPageChange={handleOnPageChange}
              totalPages={totalPages}
            />
          </div>
        )}
      </Segment>
    </div>
  )
}

export default Teacher
