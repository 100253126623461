// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login_simple_input__1NxvF {\n  display: flex;\n  align-items: baseline;\n}\n\n.login_growing__1bBjx {\n  display: flex;\n  align-items: baseline;\n  margin-bottom: 10px;\n  padding-bottom: 10px;\n}\n\n.login_growing__1bBjx div {\n  flex-grow: 1px;\n  margin-right: 10px;\n  margin-left: 10px;\n}", "",{"version":3,"sources":["webpack://src/pages/auth/login.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,qBAAA;AADJ;;AAGA;EACI,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,oBAAA;AAAJ;;AACI;EACI,cAAA;EACA,kBAAA;EACA,iBAAA;AACR","sourcesContent":["\n\n.simple_input {\n    display: flex;\n    align-items: baseline;\n}\n.growing {\n    display: flex;\n    align-items: baseline;\n    margin-bottom: 10px;\n    padding-bottom: 10px;\n    div {\n        flex-grow: 1px;\n        margin-right: 10px;\n        margin-left: 10px;\n    }\n} \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"simple_input": "login_simple_input__1NxvF",
	"growing": "login_growing__1bBjx"
};
export default ___CSS_LOADER_EXPORT___;
