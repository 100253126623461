import React from 'react'
import TeacherBasicInfo from './teacher-basic-info'
import TeacherClasses from './teacher-classes'
import TeacherInfoTabs from './teacher-info-tabs'

const TeacherInfo = () => {

    const [activeMenu, setActiveMenu] = React.useState(
        'basicInfo',
      )

  return (
    <div>
    <div className="">
      <TeacherInfoTabs
        activeMenu={activeMenu}
        setActiveMenu={value => setActiveMenu(value)}
      />
    </div>

    <div className="mt-2 ">
      {activeMenu === 'basicInfo' && <TeacherBasicInfo />}
      {activeMenu === 'classes' && <TeacherClasses />}
    </div>
  </div>
  )
}

export default TeacherInfo