import {authAxios} from '../../../config/axios-config'

async function updateAdmin(data) {
  const result = await authAxios.patch(`/admin`, data)
  return result?.data
}


async function getAdmin(id) {
    const result = await authAxios.get(`/admin/${id}`)
    return result?.data
  }

export const adminsApi = {
  updateAdmin,
  getAdmin
}
