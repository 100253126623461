import { Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Message } from "semantic-ui-react";
import * as Yup from "yup";

import { authAxios } from "../../config/axios-config";
import useAsync from "../../hooks/use-async";
import useDifficulty from "../../hooks/use-difculty";
import useSubject from "../../hooks/use-subjects";
import routes from "../../routes";
import { formatResponseError } from "../../utils/format-response";
import FormikDropdown from "../formik/FormikDropdown";
import FormikInput from "../formik/FormikInput";
import DefaultContainer from "../shared/default-container";

const NewBalanceSheetQuestion = () => {
  const history = useHistory();

  const {
    run: createQuestion,
    error: errorCreatingQuestion,
    isLoading: creatingQuestion,
  } = useAsync();
  const { subjects, errorGettingSubjects, gettingSubjects } = useSubject();

  const [competency, setCompetency] = React.useState([]);

  const { difficulties, gettingDifficulties, errorGettingDifficulty } =
    useDifficulty();

  const {
    run: getCompetency,
    error: errorGettingCompetency,
    isLoading: gettingCompetency,
  } = useAsync();

  const newAmoritzationQuestionSchema = Yup.object({
    questionTitle: Yup.string().trim().required("Required"),
    subject: Yup.string().trim().required("Required"),
    difficulty: Yup.string().trim().required("Required"),
    hint: Yup.string().trim().required("Required"),
    competency: Yup.string().trim().required("Required"),
  });

  function onCreate(values) {
    createQuestion(authAxios.post("/question/balancesheet", values)).then(
      ({ data }) => {
        history.push(routes.balanceSheetQuestion.update(data?._id), {
            new: true,
            data,
        });
      }
    );
  }

  const getCompetencyOptions = React.useCallback(
    (subjectId) => {
      getCompetency(
        authAxios.get(`/school/subject/competency/all/?id=${subjectId}`)
      ).then(({ data }) => {
        let options = [];
        data?.forEach((s) =>
          options.push({ text: s.name, key: s._id, value: s._id })
        );
        setCompetency(options);
      });
    },
    [getCompetency]
  );

  return (
    <DefaultContainer>
      <h2>Create new Balance-Sheet question</h2>

      {errorGettingSubjects ? (
        <Message error list={formatResponseError(errorGettingSubjects)} />
      ) : null}

      {errorCreatingQuestion ? (
        <Message error list={formatResponseError(errorCreatingQuestion)} />
      ) : null}

      {errorGettingDifficulty ? (
        <Message error list={formatResponseError(errorGettingDifficulty)} />
      ) : null}
      {errorGettingCompetency ? (
        <Message error list={formatResponseError(errorGettingCompetency)} />
      ) : null}
      <Formik
        initialValues={{
          subject: "",
          questionTitle: "",
          hint: "",
          difficulty: "",
          competency: "",
        }}
        onSubmit={onCreate}
        validationSchema={newAmoritzationQuestionSchema}
      >
        {(formik) => (
          <Form loading={creatingQuestion} onSubmit={formik.handleSubmit}>
            <FormikDropdown
              label="Subject"
              name="subject"
              loading={gettingSubjects}
              options={subjects}
              placeholder="select a subject"
              onChangeCB={(value) => {
                getCompetencyOptions(value);
                formik.setFieldValue("competency", "");
              }}
            />

            <FormikDropdown
              label="Competency"
              name="competency"
              placeholder="please select a subject first!"
              loading={gettingCompetency}
              options={competency}
              disabled={gettingCompetency}
            />

            <FormikDropdown
              label="Difficulty"
              options={difficulties}
              placeholder="select a difficulty"
              name="difficulty"
              loading={gettingDifficulties}
            />

            <FormikInput
              label="Question title"
              name="questionTitle"
              placeholder="Question title"
            />
            <FormikInput label="Hint" name="hint" placeholder="Question hint" />
            <div className="flex justify-between">
              <Button type="submit" size="tiny" primary>
                Continue
              </Button>
              <Button
                className="mr-2"
                size="tiny"
                onClick={() => history.push(routes.balanceSheetQuestion.all)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </DefaultContainer>
  );
};

export default NewBalanceSheetQuestion;
