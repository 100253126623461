import React from 'react'
import {BsPen} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import {RiDeleteBin7Fill} from 'react-icons/ri'
import {Button, Image} from 'semantic-ui-react'

import routes from '../../routes'
import {truncate} from '../../utils/string'

const ChapterCard = ({
  chapter,
  chapterSrc,
  name,
  brief,
  chapterid,
  subjectid,
  order,
  status,
  setCurrentChapter,
  setIsDeleting,
}) => {
  return (
    <div className="rounded-xl shadow-md hover:shadow-xl hover:opacity-95 flex flex-col border-2 solid">
      <Link
        className=" cursor-pointer"
        to={routes.subjects.chapters.content(subjectid, chapterid)}
      >
        <Image
          className="w-80 h-40 bg-contain rounded-t-md bg-indigo-50"
          src={chapterSrc}
          alt="no image provided"
        ></Image>
      </Link>

      <div className="flex flex-col p-3 w-80 h-28">
        <div className="flex justify-between items-center">
          <Link
            className="mt-1 cursor-pointer"
            to={routes.subjects.chapters.content(subjectid, chapterid)}
          >
            <p className="text-primary text-lg font-semibold">
              Chapter {order}: {truncate(name, 15)}
            </p>
          </Link>
          <div className="flex">
            <Link
              className="mt-1 ml-2 cursor-pointer"
              to={routes.subjects.chapters.update(subjectid, chapterid)}
            >
              <BsPen size="14" />
            </Link>
            <Button
              basic
              size="small"
              className="px-0.5 py-0.5 border-none bg-red-100 ml-1 -mr-1"
              onClick={() => {
                setIsDeleting(p => !p)
                setCurrentChapter(chapter)
              }}
            >
              <RiDeleteBin7Fill size="14" className='border-none'/>
            </Button>
          </div>
        </div>
        <p className="font-semibold h-10 overflow-y-auto ">{brief}</p>
        <p className="flex items-center ">
          Status:{' '}
          <p
            className={`ml-1 font-semibold ${
              status === 'Incomplete'
                ? 'text-red-500'
                : status === 'Archived'
                ? 'text-yellow-500'
                : 'text-green-500'
            }  `}
          >
            {status}
          </p>
        </p>
      </div>
    </div>
  )
}

export default ChapterCard
