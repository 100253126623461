import {Formik} from 'formik'
import React from 'react'
import {useHistory, useParams} from 'react-router'
import {Button, Form, Message} from 'semantic-ui-react'
import * as Yup from 'yup'

import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import useSubject from '../../hooks/use-subjects'
import routes from '../../routes'
import {formatResponseError} from '../../utils/format-response'
import FormikDropdown from '../formik/FormikDropdown'
import FormikInput from '../formik/FormikInput'
import DefaultContainer from '../shared/default-container'

const UpdateCompetency = () => {
  const history = useHistory()
  const [question, setQuestion] = React.useState()

  const {id} = useParams()

  const {
    run: getQuestion,
    error: errorGettingQuestion,
    isLoading: gettingQuestion,
  } = useAsync()

  const {
    run: updateQuestionReq,
    error: errorupdatingQuestion,
    isLoading: updatingQuestion,
  } = useAsync()

  const {subjects, errorGettingSubjects, gettingSubjects} = useSubject()

  const newCompetencySchema = Yup.object({
    subject: Yup.string().trim().required(),
    name: Yup.string().trim().required(),
  })

  React.useEffect(() => {
    getQuestion(authAxios.get(`/school/subject/competency/${id}`)).then(
      ({data}) => {
        setQuestion(data)
      },
    )
  }, [getQuestion, id])

  function onUpdate(values) {
    updateQuestionReq(
      authAxios.put(`/school/subject/competency/${id}`, values),
    ).then(({data}) => {
      history.push(routes.competency.all)
    })
  }

  return (
    <DefaultContainer >
      <h2 className="mb-8">Update Competency</h2>

      {errorGettingSubjects ? (
        <Message error list={formatResponseError(errorGettingSubjects)} />
      ) : null}

      {errorGettingQuestion ? (
        <Message error list={formatResponseError(errorGettingQuestion)} />
      ) : null}

      {errorupdatingQuestion ? (
        <Message error list={formatResponseError(errorupdatingQuestion)} />
      ) : null}

      <Formik
        initialValues={{
          subject: question?.subject || '',
          name: question?.name || '',
        }}
        onSubmit={onUpdate}
        validationSchema={newCompetencySchema}
        enableReinitialize
      >
        {formik => (
          <Form
            loading={gettingQuestion || updatingQuestion}
            onSubmit={formik.handleSubmit}
          >
            <FormikInput
              label="Name"
              name="name"
              placeholder="Enter Competency"
            />
            <FormikDropdown
              label="Subject"
              name="subject"
              loading={gettingSubjects}
              options={subjects}
            />
            <div className="my-8 flex justify-between">
              <Button size="tiny" type="submit" primary>
                Save
              </Button>
              <Button
                size="tiny"
                onClick={() => history.push(routes.competency.all)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </DefaultContainer>
  )
}

export default UpdateCompetency
