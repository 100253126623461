import React from 'react'
import {FieldArray, Formik, Form as FormFormik} from 'formik'
import {Form, Button, Message, Dimmer, Loader, Image, Segment} from 'semantic-ui-react'
import * as Yup from 'yup'

import useAsync from '../../hooks/use-async'
import {authAxios} from '../../config/axios-config'
import {formatResponseError} from '../../utils/format-response'
import FormikInput from '../formik/FormikInput'
import FormikDropdown from '../formik/FormikDropdown'
import {useHistory, useParams} from 'react-router'
import routes from '../../routes'
import useDifficulty from '../../hooks/use-difculty'
import {convertArrayOfObjToFormData} from '../../utils/form-data'
import FileAttachment from '../shared/FileAttachment'
import DefaultContainer from '../shared/default-container'
import { Fragment } from 'react/cjs/react.production.min'

const UpdateMcqQuestion = () => {
  const [subjects, setSubjects] = React.useState([])
  const [question, setQuestion] = React.useState()
  const [competency, setCompetency] = React.useState([])
  const [attachmentData, setAttachmentData] = React.useState(null)
  const [feedbackError, setFeedbackError] = React.useState(null)

  const history = useHistory()
  const {id} = useParams()

  const {difficulties, gettingDifficulties, errorGettingDifficulty} =
    useDifficulty()
  const answerOptions = React.useMemo(
    () => [
      {text: 'A', value: 'A', key: 'A'},
      {text: 'B', value: 'B', key: 'B'},
      {text: 'C', value: 'C', key: 'C'},
      {text: 'D', value: 'D', key: 'D'},
    ],
    [],
  )

  const {
    run: getSubjects,
    error: errorGettingSubjects,
    isLoading: gettingSubjects,
  } = useAsync()
  const {
    run: updateRequestQuestion,
    error: errorCreatingQuestion,
    isLoading: creatingQuestion,
  } = useAsync()

  const {
    run: getQuestion,
    error: errorGettingQuestion,
    isLoading: gettingQuestion,
  } = useAsync()

  const {
    run: getCompetency,
    error: errorGettingCompetency,
    isLoading: gettingCompetency,
  } = useAsync()

  const newMCQQuestionSchema = Yup.object({
    questionTitle: Yup.string().trim().required('Required'),
    question: Yup.string().trim().required('Required'),
    hint: Yup.string().trim().required('Required'),
    subject: Yup.string().trim().required(),
    answers: Yup.array().required(),
    difficulty: Yup.string().trim().required(),
    competency: Yup.string().trim().required(),

    choices: Yup.array()
      .of(
        Yup.object({
          choice: Yup.string().trim().required('Required'),
        }),
      )
      .length(4)
      .required(),
    feedback: Yup.array()
      .of(
        Yup.object({
          message: Yup.string().trim().required('Required'),
        }),
      )
      .min(1)
      .max(3)
      .required(),
  })

  const updateQuestion = (values, {resetForm}) => {
    const {answers, ...rest} = values
    const answersToSend = []
    answers.forEach(a => answersToSend.push({choiceNumber: a}))

    const formData = new FormData()

    for (let i in rest) {
      if (i === 'feedback') {
        convertArrayOfObjToFormData(formData, rest[i], 'feedback')
      } else if (i === 'choices') {
        convertArrayOfObjToFormData(formData, rest[i], 'choices')
      } else {
        formData.append(i, rest[i])
      }
    }
    convertArrayOfObjToFormData(formData, answersToSend, 'answers')

    if (attachmentData) formData.append('img', attachmentData)

    // for (let pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1])
    // }

    updateRequestQuestion(
      authAxios.patch(`/question/choice/${id}`, formData),
    ).then(({data}) => {
      resetForm({})
      setAttachmentData(null)
      history.push(routes.mcqQuestion.all)
    })
  }

  const getCompetencyOptions = React.useCallback(
    subjectId => {
      getCompetency(
        authAxios.get(`/school/subject/competency/all/?id=${subjectId}`),
      ).then(({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.name, key: s._id, value: s._id}),
        )
        setCompetency(options)
      })
    },
    [getCompetency],
  )

  const getFeedbacksToAdd = (answers, existingFeedbacks) => {
    console.log(answers, existingFeedbacks)
    // This only happens on the first render
    if (answers?.length > 0 && typeof answers[0] === 'object')
      return answerOptions
        .filter(op => Object.values(answers).includes(op.value))
        .map(a => ({
          choiceNumber: a.value,
          message: '',
        }))

    let feedbackAnswers = answerOptions
      .filter(op => !answers.includes(op.value))
      .map(a => ({choiceNumber: a.value, message: ''}))

    // if (existingFeedbacks?.length) {
      return feedbackAnswers.map(a => ({
        ...a,
        ...existingFeedbacks?.find(x => a?.choiceNumber === x?.choiceNumber),
      }))
    // }
    // return answers
  }

  React.useEffect(() => {
    getQuestion(authAxios.get(`/question/choice/?qid=${id}`)).then(({data}) => {
      getCompetency(
        authAxios.get(
          `/school/subject/competency/all/?id=${data.subject?._id}`,
        ),
      ).then(({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.name, key: s._id, value: s._id}),
        )
        setCompetency(options)
      })
      setAttachmentData(question?.image)

      setQuestion(data)
    })
  }, [getQuestion, id])

  React.useEffect(() => {
    getSubjects(authAxios.get('/school/subject/all')).then(({data}) => {
      let options = []
      data?.subjects.forEach(s =>
        options.push({text: s.name, key: s._id, value: s._id}),
      )
      setSubjects(options)
    })
  }, [getSubjects])

  return (
    <DefaultContainer>
      <Segment className="border-none shadow-none bg-transparent w-full">
        <h2 className="mb-8">Update MCQ question</h2>

        {errorGettingSubjects ? (
          <Message error list={formatResponseError(errorGettingSubjects)} />
        ) : null}

        {errorCreatingQuestion ? (
          <Message error list={formatResponseError(errorCreatingQuestion)} />
        ) : null}

        {errorGettingDifficulty ? (
          <Message error list={formatResponseError(errorGettingDifficulty)} />
        ) : null}

        {errorGettingQuestion ? (
          <Message error list={formatResponseError(errorGettingQuestion)} />
        ) : null}

        {errorGettingCompetency ? (
          <Message error list={formatResponseError(errorGettingCompetency)} />
        ) : null}

        {feedbackError ? <Message error content={feedbackError} /> : null}

        <Dimmer active={creatingQuestion || gettingQuestion} inverted>
          <Loader active={true} />
        </Dimmer>
        <div className="max-w-lg">
          <Formik
            initialValues={{
              subject: question?.subject?._id || '',
              difficulty: question?.difficulty || '',
              questionTitle: question?.questionTitle || '',
              question: question?.question || '',
              hint: question?.hint || '',
              competency: question?.competency || '',
              choices: question?.choices || [
                {
                  choice: '',
                  choiceNumber: 'A',
                },
                {
                  choice: '',
                  choiceNumber: 'B',
                },
                {
                  choice: '',
                  choiceNumber: 'C',
                },
                {
                  choice: '',
                  choiceNumber: 'D',
                },
              ],
              answers: question?.answers.map(a => a.choiceNumber) || [],
              feedback: question?.feedback?.length
                ? question?.feedback
                : getFeedbacksToAdd(question?.answers || []),
            }}
            validationSchema={newMCQQuestionSchema}
            onSubmit={updateQuestion}
            enableReinitialize
          >
            {formik => (
              <Form onSubmit={formik.handleSubmit}>
                <FormikInput name="questionTitle" label="Question title" />

                <Form.Field required>
                  <FormikInput name="question" label="Question" />
                </Form.Field>

                <FormikDropdown
                  label="Subject"
                  name="subject"
                  loading={gettingSubjects}
                  options={subjects}
                  onChangeCB={value => {
                    getCompetencyOptions(value)
                    formik.setFieldValue('competency', '')
                  }}
                />

                <FormikDropdown
                  label="Competency"
                  name="competency"
                  loading={gettingCompetency}
                  options={competency}
                  disabled={gettingCompetency}
                />

                <FormikDropdown
                  label="Difficulty"
                  options={difficulties}
                  name="difficulty"
                  loading={gettingDifficulties}
                />

                {/* /   Section for choices    / */}
                <FormFormik>
                  <FieldArray name="choices">
                    {({insert, remove, push}) => (
                      <div>
                        {formik.values.choices.length > 0 &&
                          formik.values.choices.map((choice, index) => {
                            const {touched, errors} = formik
                            const isTouched =
                              touched.choices && touched.choices[index]?.choice
                            const isError =
                              errors.choices && errors.choices[index]?.choice
                            return (
                              <Fragment key={index}>
                                <div className="flex">
                                  <FormikInput
                                    name={`choices.${index}.choiceNumber`}
                                    className="mr-4 w-10"
                                    onChange={() => null}
                                  />
                                  <FormikInput
                                    name={`choices.${index}.choice`}
                                    placeholder="Answer choice"
                                    className="mt-0 w-full"
                                    error={isTouched && isError}
                                  />
                                </div>
                              </Fragment>
                            )
                          })}
                      </div>
                    )}
                  </FieldArray>
                </FormFormik>
                {/* /   End of Section choices    / */}
                <FormikDropdown
                  label="Answers"
                  name="answers"
                  options={answerOptions}
                  multiple
                  onChangeCB={values => {
                    formik.setFieldValue(
                      'feedback',
                      getFeedbacksToAdd(values, formik.values.feedback),
                    )
                  }}
                />

                {/* /   Section for Feed-back    / */}
                <h3>Enter Feedback for each question</h3>
                <FormFormik>
                  <FieldArray name="feedback">
                    {({insert, remove, push}) => (
                      <div>
                        {formik.values.feedback.length > 0 &&
                          formik.values.feedback.map((choice, index) => {
                            const {touched, errors} = formik
                            const isTouched =
                              touched.feedback &&
                              touched.feedback[index]?.message
                            const isError =
                              errors.feedback && errors.feedback[index]?.message
                            return (
                              <Fragment key={index}>
                                <div className="flex">
                                  <FormikInput
                                    name={`feedback.${index}.choiceNumber`}
                                    className="mr-4 w-10"
                                    onChange={() => null}
                                  />
                                  <FormikInput
                                    name={`feedback.${index}.message`}
                                    placeholder="Enter the feedback"
                                    className="mt-0 w-full"
                                    error={isTouched && isError}
                                  />
                                </div>
                              </Fragment>
                            )
                          })}
                        {formik.touched.feedback &&
                        formik.errors.feedback &&
                        typeof formik.errors.feedback !== 'object'
                          ? setFeedbackError(formik.errors.feedback)
                          : null}
                      </div>
                    )}
                  </FieldArray>
                </FormFormik>
                {/* /   End of Section Feed-back    / */}

                <Form.Field required>
                  <FormikInput name="hint" label="Hint" />
                </Form.Field>

                {question?.image && (
                  <Image
                    size="medium"
                    src={question?.image}
                    rounded
                    className="mb-4 mt-8"
                  />
                )}

                <FileAttachment setAttachmentData={setAttachmentData} />

                <hr />
                <div className="my-8 flex justify-between">
                  <Button size="tiny" type="submit" primary>
                    Save
                  </Button>
                  <Button
                    size="tiny"
                    onClick={() => history.push(routes.mcqQuestion.all)}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Segment>
    </DefaultContainer>
  )
}

export default UpdateMcqQuestion
