/* eslint-disable react/display-name, jsx-a11y/click-events-have-key-events */
import {Navigation} from 'react-minimal-side-navigation'
import {useHistory, useLocation} from 'react-router-dom'
import Icon from 'awesome-react-icons'
import React, {useState} from 'react'

import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css'
import {
  BsAlignTop,
  BsCardText,
  BsChatRightDots,
  BsFileEarmarkText,
  BsGrid3X3Gap,
  BsListTask,
  BsPieChart,
  BsQuestionCircle,
  BsTable,
  BsFileEarmarkPost,
  BsListStars,
} from 'react-icons/bs'
import {RiDashboardLine} from 'react-icons/ri'

import {AiOutlineFilePdf, AiOutlineLogout, AiFillSetting} from 'react-icons/ai'

import routes from '../../routes'
import Auth from '../../utils/auth'
import AdminIcon from '../icon'
import teacher_icon from '../../assets/images/teacher_icon.svg'
import student_Icon from '../../assets/images/student_Icon.svg'
import Balance_sheet_icon from '../../assets/images/Balance_sheet_icon.svg'
import {Image} from 'semantic-ui-react'

export const Sidebar = ({isSmall}) => {
  const history = useHistory()
  const location = useLocation()
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const onLogout = () => {
    Auth.logout()
    history.push(routes.login)
  }

  return (
    <React.Fragment>
      {/* Sidebar Overlay */}
      <div
        onClick={() => setIsSidebarOpen(false)}
        className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
          isSidebarOpen ? 'block' : 'hidden'
        }`}
      />

      <div className={`${isSmall ? 'flex' : 'hidden'}`}>
        <button
          className="btn-menu "
          onClick={() => setIsSidebarOpen(true)}
          type="button"
        >
          <Icon name="burger" className="w-6 h-6" />
        </button>
      </div>

      {/* Sidebar */}
      <div
        className={`Sidebar fixed  inset-y-0 left-0 z-50 w-64 overflow-y-auto transition duration-300 ease-out h-screen min-h-full bg-gray-200 transform translate-x-0  border-r-2 lg:translate-x-0 lg:static lg:inset-0 ${
          isSidebarOpen ? 'ease-out translate-x-0' : 'ease-in -translate-x-full'
        } flex flex-col`}
      >
        <div className="flex items-center justify-center text-center py-10">
          <AdminIcon width="94" height="94" />
        </div>

        {/* https://github.com/abhijithvijayan/react-minimal-side-navigation */}
        <Navigation
          activeItemId={location.pathname}
          onSelect={({itemId}) => {
            history.push(itemId)
          }}
          items={[
            {
              title: 'Dashboard',
              itemId: '/',
              elemBefore: () => <RiDashboardLine size="16" />,
            },
            {
              title: 'teachers',
              itemId: routes.teachers,
              elemBefore: () => <Image src={teacher_icon}></Image>,
            },
            {
              title: 'Student with vouchers',
              itemId: routes.voucherstudent,
              elemBefore: () => <Image src={student_Icon}></Image>,
            },
            {
              title: 'Subscribing students',
              itemId: routes.nonvoucherstudent,
              elemBefore: () => <Image src={student_Icon}></Image>,
            },
            //Subjects
            {
              title: 'Subjects',
              itemId: routes.subjects.all,
              elemBefore: () => <BsFileEarmarkPost size="16" />,
            },
            //Competency
            {
              title: 'Competency',
              itemId: routes.competency.all,
              elemBefore: () => <BsFileEarmarkText size="16" />,
            },
           // Feedbacks
            {
              title: 'Feedbacks',
              itemId: routes.feedbacks.all,
              elemBefore: () => <BsChatRightDots size="16" />,
            },
            // //questions
            {
              title: 'Questions',
              elemBefore: () => <BsQuestionCircle size="16" />,
              itemId: 'QuestionsId',
              subNav: [
                {
                  title: 'Journal',
                  itemId: routes.jouralQusetion.all,
                  elemBefore: () => <BsGrid3X3Gap size="16" />,
                },
                {
                  title: 'MCQ',
                  itemId: routes.mcqQuestion.all,
                  elemBefore: () => <BsListTask size="16" />,
                },
                {
                  title: 'Blank',
                  itemId: routes.blankQuestion.all,
                  elemBefore: () => <BsCardText size="16" />,
                },
                {
                  title: 'T-Account',
                  itemId: routes.tAccountQuestion.all,
                  elemBefore: () => <BsAlignTop size="16" />,
                },
                {
                  title: 'Amortization',
                  itemId: routes.amortizationQuestion.all,
                  elemBefore: () => <BsTable size="16" />,
                },
                {
                  title: 'Balance-sheet',
                  itemId: routes.balanceSheetQuestion.all,
                  elemBefore: () => <Image src={Balance_sheet_icon}></Image>,
                },
              ],
            },

            //Exercise
            {
              title: 'Exercise',
              itemId: routes.exerice.all,
              elemBefore: () => <BsListStars size="16" />,
            },
            //PDFs
            {
              title: 'PDFs',
              itemId: routes.pdfs.all,
              elemBefore: () => <AiOutlineFilePdf size="16" />,
            },
            {
                title: 'Settings',
                itemId: routes.settings.all,
                elemBefore: () => <AiFillSetting size="16" />,
              },
          ]}
        />
        <div
          className="flex items-center text-red-600 mt-auto pl-4 ml-0.5 py-3 hover:bg-gray-100 cursor-pointer"
          onClick={onLogout}
        >
          <AiOutlineLogout size="16" className="mr-4" />
          Logout
        </div>
      </div>
    </React.Fragment>
  )
}
