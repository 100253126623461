import React from 'react'
import {Field} from 'formik'
import {Form} from 'semantic-ui-react'
import {truncateToDecimal} from '../../utils/numbers'

function FormikInput({label, name, isNumber, className, ...props}) {
  const handleBlur = (field, form) => {
    if (isNumber) {
      if (field.value?.trim() !== '') {
        if (field.value === '0') form.setFieldValue(name, '0')
        else
          form.setFieldValue(name, truncateToDecimal(field.value))
      } else {
        form.setFieldValue(name, '')
      }
    }
  }
  return (
    <>
      <label htmlFor={name} className="font-normal text-base text-labelColor">
        {label}
      </label>
      <Field name={name}>
        {({form, field}) => {
          const {errors, touched} = form
          return (
            <Form.Input
              id={name}
              error={touched[name] && errors[name]}
              className={`block mt-2 text-sm ${className}`}
              {...field}
              {...props}
              onBlur={() => handleBlur(field, form)}

            />
          )
        }}
      </Field>
    </>
  )
}

export default FormikInput
