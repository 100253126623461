import React from 'react'
import {BsPen} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import {Image} from 'semantic-ui-react'
import routes from '../../routes'
import {truncate} from '../../utils/string'

const SubjectCard = ({subjectSrc, name, id}) => {
  return (
    <div className="rounded-md w-72 h-56 shadow-md hover:shadow-xl  hover:opacity-95  flex flex-col border-2 solid ">
      <Link to={routes.subjects.chapters.all(id)}>
        <Image
          className="w-full h-40 rounded-t-md bg-indigo-50"
          src={subjectSrc}
          alt="no image provided"
        />
      </Link>

      <div className="flex justify-between my-3 px-3">
        <Link to={routes.subjects.chapters.all(id)}>
          <p className="text-primary font-semibold cursor-pointer overflow-y-auto">
            {truncate(name, 25)}
          </p>
        </Link>
        <Link to={routes.subjects.update(id)}>
          <BsPen size="14" />
        </Link>
      </div>
    </div>
  )
}

export default SubjectCard
