import {Formik} from 'formik'
import {Button, Form} from 'semantic-ui-react'
import FormikInput from '../../components/formik/FormikInput'
import * as Yup from 'yup'

import FormikCheckbox from '../../components/formik/FormikCheckbox'
import {adminService} from './services/settings.service'
import {useEffect, useState} from 'react'
import {useToasts} from 'react-toast-notifications'
import Auth from '../../utils/auth'

const AllSettings = () => {
  const registerSchema = Yup.object({
    email: Yup.string().email('required').required('required'),
    password: Yup.string()
      .min(8, 'must be more than 8 characters')
      .max(20, 'must be less than 20 characters'),
    newPassword: Yup.string()
      .when('isChangePassword', {
        is: true,
        then: Yup.string().required('required'),
      })
      .min(8, 'must be more than 8 characters')
      .max(20, 'must be less than 20 characters'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'notMatch')
      .when('isChangePassword', {
        is: true,
        then: Yup.string().required('required'),
      }),
  })
  const [loading, setLoading] = useState(false)
  const [adminInfo, setAdminInfo] = useState(null)

  const {addToast} = useToasts()

  useEffect(() => {
    async function fetchData() {
      setLoading(true)

      const admin = Auth.getAdminInfo()
      const id = admin.id
      const result = await adminService.getAdmin(id)

      setLoading(false)
      setAdminInfo(result)
    }
    fetchData()
  }, [])
  const handleOnSubmit = async values => {
    setLoading(true)
    try {
      const result = await adminService.updateAdmin(values)
      addToast(`data is updated ${result} `, {
        appearance: 'success',
        autoDismissTimeout: 1000,
      })
    } catch (e) {
      addToast('check input data', {
        appearance: 'error',
        autoDismissTimeout: 1000,
      })
    }

    setLoading(false)
  }

  return (
    <div className="flex relative items-center justify-start h-full w-full">
      <Formik
        initialValues={{
          email: '',
          password: '',
          newPassword: '',
          confirmNewPassword: '',
          has2fa:  adminInfo?.is2FARequired || false,
          isChangePassword: false,
        }}
        enableReinitialize
        validationSchema={registerSchema}
        onSubmit={handleOnSubmit}
      >
        {formik => (
          <Form onSubmit={formik.submitForm} loading={loading}>
            <Form.Field required>
              <FormikInput
                name="email"
                label="Email"
                control="input"
                type="email"
              />
            </Form.Field>
            <Form.Field required>
              <FormikInput
                name="password"
                label="Password"
                control="input"
                type="password"
              />
            </Form.Field>
            <Form.Field>
              <FormikCheckbox label="Change Password" name="isChangePassword" />
            </Form.Field>
            {formik.values.isChangePassword && (
              <fieldset className="form-group">
                <Form.Field required>
                  <FormikInput
                    name="newPassword"
                    label="new Password"
                    control="input"
                    type="password"
                  />
                </Form.Field>

                <Form.Field required>
                  <FormikInput
                    name="confirmNewPassword"
                    label="Confirm new Password"
                    control="input"
                    type="password"
                  />
                </Form.Field>
              </fieldset>
            )}

            <Form.Field>
              <FormikCheckbox
                name="has2fa"
                label="Use Two factor Authorization"
              />
            </Form.Field>
            <div className="text-center">
              <Button
                // disabled={!checkPrivacy}
                content="Update"
                fluid
                type="submit"
                className="bg-primary px-28 text-white"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AllSettings
