// export const truncateToDecimal = (num, dec = 2) =>
//   num ? (Math.floor(100 * num) / 100).toFixed(dec).replace(/\d(?=(\d{3})+\.)/g, '$&.') : num


export const truncateToDecimal = (num, dec = 2) =>{
  if(isNaN(new NumberParser('de-DE').parse(num)) ){
    return ''
  }
  return num ? format(new NumberParser('de-DE').parse(num)) : num
}

function format(num) {
  var p = num.toFixed(2).split(".");
  return  p[0].split("").reverse().reduce(function(acc, num, i, orig) {
      return num + (num !== "-" && i && !(i % 3) ? "." : "") + acc;
  }, "") + "," + p[1];
}

class NumberParser {
  constructor(locale) {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
    const numerals = [...new Intl.NumberFormat(locale, {useGrouping: false}).format(9876543210)].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this._group = new RegExp(`[${parts.find(d => d.type === "group").value}]`, "g");
    this._decimal = new RegExp(`[${parts.find(d => d.type === "decimal").value}]`);
    this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
    this._index = d => index.get(d);
  }
  parse(string) {
    // eslint-disable-next-line no-cond-assign
    return (string = string.toString().trim()
      .replace(this._group, "")
      .replace(this._decimal, ".")
      .replace(this._numeral, this._index)) ? +string : NaN;
  }
}

