import React from 'react'
import useAsync from './use-async'
import {authAxios} from '../config/axios-config'

function useCategory() {
  const [category, setCategory] = React.useState([])
  const {
    run: getCategory,
    error: errorGettingCategory,
    isLoading: gettingCategory,
  } = useAsync()

  React.useEffect(() => {
    getCategory(authAxios.get('/question/tablefeedback/category/all')).then(
      ({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.name, key: s._id, value: s._id}),
        )
        setCategory(options)
      },
    )
  }, [getCategory])

  return {category, gettingCategory, errorGettingCategory}
}

export default useCategory
