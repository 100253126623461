import React from 'react'
import {CgAttachment} from 'react-icons/cg'
import {RiDeleteBin7Fill} from 'react-icons/ri'
import { useLanguage } from '../../context/language-context'
import { content } from '../../localization/content'

function FileAttachment({setAttachmentData}) {
  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const [file, setFile] = React.useState(null)

  const handleOnSelectedFile = e => {
    setFile(e.target.files[0])
    setAttachmentData(e.target.files[0])
  }

  const clearAttachment = () => {
    setFile(null)
    setAttachmentData(null)
  }

  return (
    <>
      {file ? (
        <div className="flex justify-between bg-gray-100 p-3">
          <span>
            <strong>
           File Name
            </strong>: {file.name}
          </span>
          <div
            className="text-red-800 flex items-center justify-center cursor-pointer"
            onClick={clearAttachment}
          >
            <RiDeleteBin7Fill size="16" className="ltr:mr-3 rtl:ml-3" />
            clear
          </div>
        </div>
      ) : (
        <>
          <input
            type="file"
            alt='image'
            id="file-input"
            accept="image/png, image/jpeg"
            className="hidden"
            onChange={handleOnSelectedFile}
          />
          <label htmlFor="file-input" className="text-primaryRedColor-default flex bg-gray-100 p-3">
            <div className="flex items-center justify-center cursor-pointer">
              <CgAttachment size="24" className="ltr:mr-3 rtl:ml-3" />
              <span>
              select an image
              </span>
            </div>
          </label>
            <span className='ml-2 text-gray-800 text-sm'> please select png or jpeg only</span>
        </>
      )}
    </>
  )
}

export default FileAttachment
