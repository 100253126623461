// all routes pathname can be imported in this file
module.exports = {
  login: '/auth/login',

  dashboard: '/',

  teachers: '/teachers',

  voucherstudent: '/voucherstudent',

  nonvoucherstudent: '/nonvoucherstudent',

  customComp: '/customComp',

  teacherInfo: {
    teacherInfo(userid = ':userid') {
      return `/teacher/${userid}`
    },
  },

  voucherstudentInfo: {
    voucherstudentClasses(userid = ':userid') {
      return `/voucherstudent/${userid}/classes`
    },
  },

  nonvoucherstudentInfo: {
    nonvoucherstudentsubjects(userid = ':userid') {
      return `/nonvoucherstudent/${userid}/subjects`
    },
  },

  jouralQusetion: {
    all: '/questions/journal',
    new: '/questions/journal/new',
    update(id = ':id') {
      return `/questions/journal/${id}`
    },
  },

  mcqQuestion: {
    all: '/questions/mcq',
    new: '/questions/mcq/new',
    update(id = ':id') {
      return `/questions/mcq/${id}`
    },
  },

  blankQuestion: {
    all: '/questions/blank',
    new: '/questions/blank/new',
    update(id = ':id') {
      return `/questions/blank/${id}`
    },
  },

  tAccountQuestion: {
    all: '/questions/taccount',
    new: '/questions/taccount/new',
    update(id = ':id') {
      return `/questions/taccount/${id}`
    },
  },

  amortizationQuestion: {
    all: '/questions/amortization',
    new: '/questions/amortization/new',
    update(id = ':id') {
      return `/questions/amortization/${id}`
    },
  },

  balanceSheetQuestion: {
    all: '/questions/balance-sheet',
    new: '/questions/balance-sheet/new',
    update(id = ':id') {
      return `/questions/balance-sheet/${id}`
    },
  },

  competency: {
    all: '/competency',
    new: '/competency/new',
    update(id = ':id') {
      return `/competency/${id}`
    },
  },

  feedbacks: {
    all: '/feedbacks',
    new: '/feedbacks/new',
    update(id = ':id') {
      return `/feedbacks/${id}`
    },
  },

  subjects: {
    all: '/subjects',
    new: '/subjects/new',
    update(id = ':id') {
      return `/subjects/${id}`
    },
    chapters: {
      all(subjectid = ':subjectid') {
        return `/subjects/chapters/${subjectid}`
      },
      new(subjectid = ':subjectid') {
        return `/subjects/chapters/${subjectid}/new`
      },
      update(subjectid = ':subjectid', chapterid = ':chapterid') {
        return `/subjects/chapters/${subjectid}/update/${chapterid}`
      },
      content(subjectid = ':subjectid', chapterid = ':chapterid') {
        return `/subjects/chapters/${subjectid}/content/${chapterid}`
      },
      review(subjectid = ':subjectid', chapterid = ':chapterid') {
        return `/subjects/chapters/${subjectid}/review/${chapterid}`
      },
      sections: {
        all(subjectid = ':subjectid', chapterid = ':chapterid') {
          return `/subjects/chapters/${subjectid}/content/${chapterid}/sections`
        },
        new(subjectid = ':subjectid', chapterid = ':chapterid') {
          return `/subjects/chapters/${subjectid}/content/${chapterid}/sections/new`
        },
        update(
          subjectid = ':subjectid',
          chapterid = ':chapterid',
          sectionid = ':sectionid',
        ) {
          return `/subjects/chapters/${subjectid}/content/${chapterid}/sections/update/${sectionid}`
        },
      },
    },
  },

  exerice: {
    all: '/exerice',
    newTest: '/exerice/newTest',
    newPexWex: '/exerice/newPexWex',
    updateTest(id = ':id') {
      return `/exerice/newTest/${id}`
    },
    updatePexWex(id = ':id') {
      return `/exerice/newPexWex/${id}`
    },
  },

  pdfs: {
    all: '/pdfs',
    // new: '/pdfs/new',
    new(id = ':id') {
      return `/pdfs/new/${id}`
    },
    // update(id = ':id') {
    //   return `/pdfs/${id}`
    // },
  },
  settings: {
    all: '/settings'
  },
}
