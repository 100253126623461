import React from 'react'
import {ToastProvider} from 'react-toast-notifications'

import {LanguageProvider} from './context/language-context'
import ToastContainer from './components/toast-container'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'

const AppProviders = ({children}) => {
  return (
    <ToastProvider
      components={ToastContainer}
      autoDismiss={true}
      placement="top-center"
    >
      <LanguageProvider>
        <DndProvider backend={HTML5Backend}>{children}</DndProvider>
      </LanguageProvider>
    </ToastProvider>
  )
}

export default AppProviders
