import React from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  Button,
  Checkbox,
  Form,
  Image,
  Message,
  Segment,
} from 'semantic-ui-react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { authAxios } from '../../config/axios-config'
import { formatResponseError } from '../../utils/format-response'
import useAccountNames from '../../hooks/use-account-names'
import useAsync from '../../hooks/use-async'
import useDifficulty from '../../hooks/use-difculty'
import useSubject from '../../hooks/use-subjects'
import FieldArrayHook from '../react-hook-form/field-array-hook'
import FormDateController from '../react-hook-form/form-date-controller'
import FormDropdownController from '../react-hook-form/form-dropdown-controller'
import FormInputController from '../react-hook-form/form-input-controller'
import FormTextAreaController from '../react-hook-form/form-text-area-controller'
import useCategory from '../../hooks/use-category'
import FileAttachment from '../shared/FileAttachment'
import routes from '../../routes'

const BalanceSheet48al = () => {
  const { subjects, errorGettingSubjects, gettingSubjects } = useSubject()
  const { accountNames, errorGettingAccountNames, gettingAccountNames } =
    useAccountNames()
  const { difficulties, gettingDifficulties, errorGettingDifficulty } =
    useDifficulty()
  const { category, gettingCategory, errorGettingCategory } = useCategory()

  const [competency, setCompetency] = React.useState([])

  const [haveSections, setHaveSections] = React.useState(false)
  const [forceUpdate, setForceUpdate] = React.useState(false)
  const [showFinishBtn, setShowFinishBtn] = React.useState(false)
  const [sections, setSections] = React.useState([])

  const [selectedPassifOptions, setSelectedPassifOptions] = React.useState([])
  const [selectedActifOptions, setSelectedActifOptions] = React.useState([])

  const [questionImage, setQuestionImage] = React.useState()

  const [question, setQuestion] = React.useState()

  const [attachmentData, setAttachmentData] = React.useState(null)

  const history = useHistory()

  const location = useLocation()
  const { id } = useParams()
  const [state, setState] = React.useState(location?.state)

  const {
    run: getQuestion,
    error: errorGettingQuestion,
    isLoading: gettingQuestion,
  } = useAsync()

  const {
    run: createQuestion,
    error: errorCreatetingQuestion,
    isLoading: createtingQuestion,
  } = useAsync()

  const {
    run: sendImage,
    error: errorSendingImage,
    isLoading: sendingImage,
  } = useAsync()

  const {
    run: getSections,
    error: errorGettingSections,
    isLoading: gettingSections,
  } = useAsync()

  const {
    run: getCompetency,
    error: errorGettingCompetency,
    isLoading: gettingCompetency,
  } = useAsync()

  const defaultValues = {
    questionTitle: question?.questionTitle || state?.data?.questionTitle,
    subject: question?.subject._id || state?.data?.subject,
    competency: question?.competency || state?.data?.competency || '',
    hint: question?.hint || state?.data?.hint,
    difficulty: question?.difficulty || state?.data?.difficulty || '',
    feedbackCategory:
      question?.feedbackCategory || state?.data?.feedbackCategory || '',
    question: question?.question || '',
    date: '',
    table: [
      {
        sectionName: 'left',
        content: [
          {
            subSection: 'Fixed assets',
            answers: [
              {
                account: undefined,
                value: undefined,
                feedbackMessage: undefined,
              },
            ],
          },
          {
            subSection: 'sub-left-2',
            answers: [
              {
                account: undefined,
                value: undefined,
                feedbackMessage: undefined,
              },
            ],
          },
          {
            subSection: 'sub-left-3',
            answers: [
              {
                account: undefined,
                value: undefined,
                feedbackMessage: undefined,
              },
            ],
          },
        ],
        total: '',
      },
      {
        sectionName: 'right',
        subSections: [
          {
            subSection: 'sub-right-1',
            answers: [
              {
                account: undefined,
                value: undefined,
                feedbackMessage: undefined,
              },
            ],
          },
          {
            subSection: 'sub-right-2',
            answers: [
              {
                account: undefined,
                value: undefined,
                feedbackMessage: undefined,
              },
            ],
          },
          {
            subSection: 'sub-right-3',
            answers: [
              {
                account: undefined,
                value: undefined,
                feedbackMessage: undefined,
              },
            ],
          },
          {
            subSection: 'sub-right-4',
            answers: [
              {
                account: undefined,
                value: undefined,
                feedbackMessage: undefined,
              },
            ],
          },
        ],
        total: '',
      },
    ],
  }

  const balanceSheetQuestionSchema = Yup.object({
    questionTitle: Yup.string().trim().required('Required'),
    subject: Yup.string().trim().required('Required'),
    difficulty: Yup.string().trim().required('Required'),
    feedbackCategory: Yup.string().trim().required('Required'),
    hint: Yup.string().trim().required('Required'),
    question: Yup.string().trim().required('Required'),
    competency: Yup.string().trim().required('Required'),
    date: Yup.string()
      .matches(
        /^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-\d{4}$/,
        'Only valid dates are allowed',
      )
      .required('Required'),
  })

  const { control, handleSubmit, setValue, getValues, reset } = useForm({
    defaultValues,
    resolver: yupResolver(balanceSheetQuestionSchema),
  })

  const onSelectPassifChoice = choice => {
    const choiceIdx = selectedPassifOptions.findIndex(x => x === choice)
    if (choiceIdx !== -1) {
      setSelectedPassifOptions([
        ...selectedPassifOptions.slice(0, choiceIdx),
        ...selectedPassifOptions.slice(choiceIdx + 1),
      ])
    } else {
      setSelectedPassifOptions([...selectedPassifOptions, choice])
    }
  }

  const onSelectActifChoice = choice => {
    const choiceIdx = selectedActifOptions.findIndex(x => x === choice)
    if (choiceIdx !== -1) {
      setSelectedActifOptions([
        ...selectedActifOptions.slice(0, choiceIdx),
        ...selectedActifOptions.slice(choiceIdx + 1),
      ])
    } else {
      setSelectedActifOptions([...selectedActifOptions, choice])
    }
  }

  React.useEffect(() => {
    getSections(authAxios.get('/question/balancesheet/sections')).then(
      ({ data }) => {
        let arr = []
        for (let column of data?.sections) {
          let subS = []
          for (let i of column?.subSections) {
            i = {
              ...i,
              section: i?._id,
              answers: [
                {
                  account: undefined,
                  value: undefined,
                  feedbackMessage: undefined,
                },
              ],
            }
            subS.push(i)
          }
          arr.push({ ...column, content: subS, sectionName: column?._id })
        }
        reset({ ...getValues(), table: arr })
        setSections({ ...getValues, table: arr })
        setHaveSections(true)
      },
    )
  }, [getSections, getValues, reset, forceUpdate])

  const getCompetencyOptions = React.useCallback(
    subjectId => {
      getCompetency(
        authAxios.get(`/school/subject/competency/all/?id=${subjectId}`),
      )
        .then(({ data }) => {
          let options = []
          data?.forEach(s =>
            options.push({ text: s.name, key: s._id, value: s._id }),
          )
          setCompetency(options)
        })
        .catch(() => {
          setCompetency([])
        })
    },
    [getCompetency],
  )
  React.useEffect(() => {
    if (state?.new) {
      reset({ ...getValues(), state })
      return
    } else if (haveSections) {
      getQuestion(authAxios.get(`/question/balancesheet/${id}`)).then(
        ({ data }) => {
          setShowFinishBtn(true)
          setSelectedPassifOptions(data?.passifSection)
          setSelectedActifOptions(data?.actifSection)
          setQuestionImage(data?.image)
          getCompetency(
            authAxios.get(`/school/subject/competency/all/?id=${data.subject}`),
          ).then(({ data }) => {
            let options = []
            data?.forEach(s =>
              options.push({ text: s.name, key: s._id, value: s._id }),
            )
            setCompetency(options)
          })

          let tableData = []
          let counter = 0 // to know what side of table we're in

          /**
           * Formatting data to what we expect
           */
          for (let column of data?.table) {
            column = {
              ...column,
              nameEN: column?.sectionName,
              sectionName: column?.sectionId,
            }
            let subSection = []
            for (let box of column?.content) {
              box = {
                ...box,
                section: box?.subSection?._id,
                nameEN: box?.subSection?.nameEN,
                _id: box?.subSection?._id,
              }

              let answersArr = []
              for (let answer of box?.answers) {
                answer = {
                  ...answer,
                  account: answer?.account?._id,
                }
                answersArr.push(answer)
              }

              subSection.push({ ...box, answers: answersArr })
            }

            let colData = { ...column, content: subSection }
            let intialSection = sections.table[counter].content
            let questionSections = colData.content

            /**
             * Merging initial sections with question sections and maintaing the order
             */
            const mergedSections = intialSection.map(is => {
              const existInQuestionSections = questionSections.find(
                qs => qs._id === is._id,
              )
              if (existInQuestionSections) {
                return existInQuestionSections
              } else {
                return is
              }
            })

            tableData.push({ ...column, content: mergedSections })
            counter++
          }

          let maintianedData = { ...data, table: tableData }
          console.log({ maintianedData })

          reset(maintianedData)
          setQuestion(data)
          setAttachmentData(question?.image)
        },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCompetency,
    getQuestion,
    getValues,
    haveSections,
    id,
    question?.image,
    reset,
    state,
    state?.new,
    forceUpdate,
  ])

  React.useEffect(() => {
    if (state?.data?.subject) {
      getCompetency(
        authAxios.get(
          `/school/subject/competency/all/?id=${state?.data?.subject}`,
        ),
      ).then(({ data }) => {
        let options = []
        data?.forEach(s =>
          options.push({ text: s.name, key: s._id, value: s._id }),
        )
        setCompetency(options)
      })
    }
  }, [getCompetency, state?.data?.subject, forceUpdate])

  const onSubmit = data => {
    let obj = {
      ...data,
      passifSection: selectedPassifOptions,
      actifSection: selectedActifOptions, // filp to match
    }
    console.log(obj)
    createQuestion(authAxios.patch(`/question/balancesheet/${id}`, obj)).then(
      ({ data }) => {
        console.log(data)
        setForceUpdate(p => !p)
        setState(prev => ({ ...prev, new: false }))
        history.push(routes.balanceSheetQuestion.update(id), {
          ...location.state,
          new: false,
        })
      },
    )
    if (attachmentData) {
      const formData = new FormData()
      formData.append('img', attachmentData)
      sendImage(
        authAxios.patch(`/question/balancesheet/img/${id}`, formData),
      ).then(({ data }) => {
        console.log(data)
      })
    }
  }

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent w-full overflow-x-auto">
        <h2 className="mb-8">
          {state?.new
            ? 'Create new balance-sheet question'
            : 'Update balance-sheet'}
        </h2>

        {errorGettingSubjects ? (
          <Message error list={formatResponseError(errorGettingSubjects)} />
        ) : null}

        {errorGettingQuestion ? (
          <Message error list={formatResponseError(errorGettingQuestion)} />
        ) : null}

        {errorGettingAccountNames ? (
          <Message error list={formatResponseError(errorGettingAccountNames)} />
        ) : null}
        {errorGettingDifficulty ? (
          <Message error list={formatResponseError(errorGettingDifficulty)} />
        ) : null}

        {errorGettingCompetency ? (
          <Message error list={formatResponseError(errorGettingCompetency)} />
        ) : null}

        {errorGettingCategory ? (
          <Message error list={formatResponseError(errorGettingCategory)} />
        ) : null}

        {errorCreatetingQuestion ? (
          <Message error list={formatResponseError(errorCreatetingQuestion)} />
        ) : null}

        {errorSendingImage ? (
          <Message error list={formatResponseError(errorSendingImage)} />
        ) : null}

        {errorGettingSections ? (
          <Message error list={formatResponseError(errorGettingSections)} />
        ) : null}

        <Form
          onSubmit={handleSubmit(onSubmit)}
          loading={
            gettingQuestion ||
            createtingQuestion ||
            gettingSections ||
            sendingImage ||
            gettingAccountNames
          }
        >
          <Form.Field required>
            <FormInputController
              control={control}
              name="questionTitle"
              label="Question title"
            />
          </Form.Field>

          <FormDropdownController
            control={control}
            name={`subject`}
            label="Subject"
            inputProps={{
              selection: true,
              fluid: true,
              options: subjects,
              loading: gettingSubjects,
              onChangeCB: value => getCompetencyOptions(value),
            }}
          />

          <FormDropdownController
            control={control}
            name={`competency`}
            label="Competency"
            inputProps={{
              selection: true,
              fluid: true,
              options: competency,
              disabled: gettingCompetency,
            }}
          />

          <FormDropdownController
            control={control}
            name={`difficulty`}
            label="Difficulty"
            inputProps={{
              selection: true,
              fluid: true,
              options: difficulties,
              loading: gettingDifficulties,
            }}
          />
          <FormDropdownController
            control={control}
            name={`feedbackCategory`}
            label="Feedback Category"
            inputProps={{
              selection: true,
              fluid: true,
              options: category,
              loading: gettingCategory,
            }}
          />

          <FormInputController control={control} name="hint" label="Hint" />

          <FormTextAreaController
            control={control}
            name="question"
            label="Question"
          />

          <div className="  ">
            <div className="mx-auto  w-56">
              <FormDateController control={control} name="date" label="Date" />
            </div>
          </div>

          <FieldArrayHook control={control} name="table" setValue={setValue}>
            {({ fields }) => (
              <div className="flex md:max-w-4xl sm:max-w-lg max-w-sm mx-auto px-2 md:px-4 my-20 ">
                {fields.map((f, i) => {
                  return (
                    <div key={`field-key-${i}`} className="flex flex-col flex-grow">
                      <div
                        key={f.id}
                        className="flex-1 flex-grow  relative red-400 p-4 border border-gray-600  bg-gray-100"
                      >
                        {i === 0 ? (
                          <h1 className="absolute -top-10 left-1">
                            {f?.nameEN}
                          </h1>
                        ) : (
                          <h1 className="absolute -top-10 right-1">
                            {f?.nameEN}
                          </h1>
                        )}

                        <BalanceSheetBox
                          control={control}
                          name={`table.${i}.content`}
                          accountNames={accountNames}
                          setValue={setValue}
                        />
                      </div>
                      <div className="flex justify-between border border-gray-600 p-4 bg-gray-100 rounded-b-sm -mt-0.5">
                        <div>
                          <h3 className="ml-4">Total</h3>
                        </div>
                        <FormInputController
                          control={control}
                          name={`table.${i}.total`}
                          isNumber
                          setValue={setValue}
                          inputProps={{
                            className: 'w-52',
                          }}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </FieldArrayHook>
          {sections?.table?.length > 0 ? (
            <div className="flex md:max-w-4xl sm:max-w-lg max-w-sm mx-auto px-2 md:px-4  gap-5 mb-20">
              <div className="flex flex-1 flex-col 0 p-4">
                <h3 className="mb-2">{sections?.table[0]?.nameEN} : </h3>
                {sections?.table[0]?.content?.map((se, id) => (
                  <div key={`section-table-content-${id}`}>
                    <Checkbox
                      label={se?.nameEN}
                      value={se?._id}
                      onClick={() => {
                        console.log(se?._id)
                        onSelectActifChoice(se?._id)
                      }}
                      checked={selectedActifOptions.includes(se?._id)}
                    />
                  </div>
                ))}
              </div>
              <div className="flex flex-1 flex-col  p-4">
                <h3 className="mb-2">{sections?.table[1]?.nameEN} : </h3>

                {sections?.table[1]?.content?.map((se, id) => (
                  <div key={`section-table-content-${id}`}>
                    <Checkbox
                      label={se?.nameEN}
                      value={se?._id}
                      onClick={() => {
                        onSelectPassifChoice(se?._id)
                      }}
                      checked={selectedPassifOptions.includes(se?._id)}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {questionImage && (
            <Image
              size="medium"
              src={questionImage}
              rounded
              className="mb-4 mt-8"
            />
          )}

          <FileAttachment setAttachmentData={setAttachmentData} />
          <div className="my-5 mt-10 flex justify-between">
            <div>
              <Button size="tiny" primary type="submit">
                {state?.new ? 'Save' : 'Update'}
              </Button>
              {showFinishBtn && (
                <Button
                  size="tiny"
                  type="button"
                  positive
                  onClick={() => history.push(routes.balanceSheetQuestion.all)}
                >
                  Finish
                </Button>
              )}
            </div>
            <Button
              className="mr-2"
              size="tiny"
              onClick={() => history.push(routes.balanceSheetQuestion.all)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Segment>
    </div>
  )
}

export default BalanceSheet48al

const BalanceSheetBox = ({ control, name, accountNames, setValue }) => (
  <FieldArrayHook control={control} name={name}>
    {({ fields }) => (
      <div>
        {fields.map((field, i) => (
          <div key={`field-key-${i}`}>
            <h3 className="ml-4 underline">{field.nameEN}</h3>
            <div className="red-200  m-4 p-2  border-gray-600 rounded-md relative bg-gray-100">
              <BalanceSheetSubBox
                name={`${name}.${i}.answers`}
                control={control}
                accountNames={accountNames}
                setValue={setValue}
              />
            </div>
            {fields.length - 1 !== i ? (
              <hr className="my-2 h-0.5 bg-gray-300" />
            ) : null}
          </div>
        ))}
      </div>
    )}
  </FieldArrayHook>
)

const BalanceSheetSubBox = ({ control, name, accountNames, setValue }) => (
  <FieldArrayHook control={control} name={name}>
    {({ fields, remove, append }) => {
      return fields.map((field, i) => (
        <div key={field.id} className="blue-100 my-2 w-[23rem]">
          <div className="flex justify-between gap-2">
            <FormDropdownController
              control={control}
              name={`${name}.${i}.account`}
              // label="Account Name"
              inputProps={{
                selection: true,
                fluid: true,
                options: accountNames,
                search: true,
                placeholder: 'Account Name',
                className: 'w-52',
              }}
            />
            <FormInputController
              control={control}
              name={`${name}.${i}.value`}
              isNumber
              setValue={setValue}
              inputProps={{
                className: 'w-36',
                placeholder: 'Value',
              }}
            />
          </div>
          <div className=" mb-4">
            <FormInputController
              control={control}
              name={`${name}.${i}.feedbackMessage`}
              setValue={setValue}
              inputProps={{
                placeholder: 'feedback',
              }}
            />
          </div>
          <div className="text-xs text-gray-500 -mt-3 ml-0.5">
            you have to fill the 3 inputs to create this row *
          </div>

          <hr className="my-1 h-0.5 bg-gray-100"></hr>

          <div className="flex gray-400 justify-end">
            {i === 0 ? null : (
              <Button
                type="button"
                size="tiny"
                negative
                basic
                onClick={() => remove(i)}
              >
                -
              </Button>
            )}

            {i === fields.length - 1 && fields.length < 5 ? (
              <Button
                type="button"
                size="tiny"
                className="bg-transparent border-2 border-solid items-center border-green-400"
                onClick={() =>
                  append({
                    accountName: undefined,
                    value: undefined,
                    feedbackMessage: undefined,
                  })
                }
              >
                +
              </Button>
            ) : null}
          </div>
        </div>
      ))
    }}
  </FieldArrayHook>
)
