import React, {useCallback} from 'react'
import {Link} from 'react-router-dom'
import {
  Button,
  Confirm,
  Dimmer,
  Dropdown,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react'
import {useParams} from 'react-router-dom'
import update from 'immutability-helper'

import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import routes from '../../routes'
import {useLocation, useHistory} from 'react-router-dom'
import {formatResponseError} from '../../utils/format-response'
import ChapterCard from '../../components/shared/chapter-card'
import ChapterCardOrder from '../../components/shared/chapter-card-order'

const AllChapters = () => {
  const [chapters, setChapters] = React.useState([])

  const [isordering, setIsordering] = React.useState(false)

  const [currentChapter, setCurrentChapter] = React.useState('')
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [forceReload, setForceReload] = React.useState(false)

  const location = useLocation()
  const history = useHistory()

  const {subjectid} = useParams()

  const {
    run: getSubjectChapters,
    error: errorGettingSubjectChapters,
    isLoading: gettingSubjectChapters,
  } = useAsync()
  const {
    run: submitChaptersOrder,
    error: errorGettingChaptersOrder,
    isLoading: gettingChaptersOrder,
  } = useAsync()

  const {
    run: deletePdf,
    error: errorDeletingPdf,
    isLoading: deletingPdf,
  } = useAsync()

  React.useEffect(() => {
    getSubjectChapters(authAxios.get(`/chapter/all/?sid=${subjectid}`)).then(
      ({data}) => {
        setChapters(data)
      },
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSubjectChapters, location.search, isordering, forceReload])

  const handleCancel = () => {
    setIsDeleting(false)
  }
  const handleConfirm = () => {
    deletePdf(authAxios.delete(`/chapter/${currentChapter?._id}`)).then(
      ({data}) => {
        console.log(data)
        setForceReload(p => !p)
      },
    )
    setIsDeleting(false)
  }

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = chapters[dragIndex]
      setChapters(
        update(chapters, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [chapters],
  )

  const renderCard = (card, index) => {
    return (
      <ChapterCardOrder
        key={card.id}
        index={index}
        chapterid={card.id}
        moveCard={moveCard}
        name={card?.chapterName}
        brief={card?.brief}
        chapterSrc={card?.image}
        subjectid={subjectid}
      />
    )
  }

  const onReOrder = () => {
    let order = []
    if (chapters && chapters?.length > 0) {
      for (let i in chapters) {
        order.push(chapters[i]?._id)
      }
    }
    submitChaptersOrder(
      authAxios.patch(`/chapter/order?sid=${subjectid}`, order),
    ).then(({data}) => {
      setIsordering(p => !p)
    })
  }

  return (
    <div className="">
      <Segment className="border-none shadow-none bg-transparent w-full">
        {errorGettingSubjectChapters ? (
          <Message
            error
            list={formatResponseError(errorGettingSubjectChapters)}
          />
        ) : null}

        {errorGettingChaptersOrder ? (
          <Message
            error
            list={formatResponseError(errorGettingChaptersOrder)}
          />
        ) : null}

        {errorDeletingPdf ? (
          <Message error list={formatResponseError(errorDeletingPdf)} />
        ) : null}
        <Dimmer
          active={gettingSubjectChapters || gettingChaptersOrder || deletingPdf}
          inverted
        >
          <Loader active={true} />
        </Dimmer>
        <div className="flex justify-between align-center">
          <h1 className="text-3xl">Chapters </h1>
          <div>
            <Link
              className="text-white"
              to={routes.subjects.chapters.new(subjectid)}
            >
              <Button size="small" primary>
                New
              </Button>
            </Link>

            <Dropdown className="ml-3" text="Actions">
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Edit"
                  icon={isordering ? 'toggle on' : 'toggle off'}
                  onClick={() => {
                    setIsordering(p => !p)
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {/* <Breadcrumb className='mt-4'>
        <Breadcrumb.Section link>Home</Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section link>Subjects</Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section active>Chapters</Breadcrumb.Section>
      </Breadcrumb> */}

        <div className="mt-12">
          {!isordering ? (
            <div className="flex flex-wrap gap-x-5 gap-y-5 items-center justify-start">
              {chapters?.map((a, idx) => (
                <ChapterCard
                  key={a?.id}
                  chapter={a}
                  name={a?.chapterName}
                  brief={a?.brief}
                  chapterSrc={a?.image}
                  chapterid={a?._id}
                  subjectid={subjectid}
                  order={a?.order}
                  status={a?.status}
                  setCurrentChapter={setCurrentChapter}
                  setIsDeleting={setIsDeleting}
                />
              ))}
            </div>
          ) : (
            <>
              <div>{chapters.map((card, i) => renderCard(card, i))}</div>
              {chapters.length > 0 ? (
                <Button
                  className="flex justify-end ml-auto my-10"
                  primary
                  onClick={onReOrder}
                >
                  Save
                </Button>
              ) : null}
            </>
          )}
        </div>

        <Confirm
          content={
            <div className="p-6">
              <p className="text-xl font-semibold">
                Are you sure to delete {currentChapter?.chapterName} ?
              </p>
              <p className="text-sm mt-1">
                by removing this chapter you will affect the grades and records
                of all participant students
              </p>
            </div>
          }
          open={isDeleting}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          cancelButton="Cancel"
          confirmButton={
            <Button negative className="cofirmDelete bg-red-600 ">
              Delete
            </Button>
          }
        />
        {/* <div>
        <div>
          <div className='my-20'>
            <Step.Group  vertical className='border-none shadow-none gap-y-1 bg-transparent w-12'>
              <Step  className='border-none shadow-none bg-transparent circular bg-green-500 rounded-full py-3 px-0.5  flex justify-center items-center'>
                <Icon  name="check" className=' border-none shadow-none rounded-full circular mx-auto text-xs text-white place-self-center'/>
              </Step>
              <div class="border-l-4 solid h-24 border-green-500  place-self-center"></div>
              <Step  className='border-none shadow-none bg-transparent circular bg-green-500 rounded-full circular py-3 px-0.5 '>
                <Icon name="check" className='  border-none shadow-none rounded-full circular mx-auto text-sm text-white place-self-center' />
              </Step>
            </Step.Group>
          </div>
        </div>
      </div> */}
      </Segment>
    </div>
  )
}

export default AllChapters
