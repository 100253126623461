import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {
  Button,
  Confirm,
  Dimmer,
  Input,
  Loader,
  Message,
  Pagination,
  Segment,
  Table,
} from 'semantic-ui-react'
import {RiDeleteBin7Fill} from 'react-icons/ri'
import {BsPen} from 'react-icons/bs'

import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import routes from '../../routes'
import {useLocation, useHistory} from 'react-router-dom'
import queryString from 'query-string'
import {formatResponseError} from '../../utils/format-response'
import DefaultContainer from '../../components/shared/default-container'
import {truncate} from '../../utils/string'

const QuestMCQ = () => {
  const [questions, setQuestions] = React.useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [currentQuestion, setCurrentQuestion] = useState('')
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [forceReload, setForceReload] = React.useState(false)
  
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [limit, setLimit] = useState(10)

  const location = useLocation()
  const history = useHistory()

  const {
    run: getMCQQuestion,
    error: errorGettingMCQQuestion,
    isLoading: gettingMCQQuestion,
  } = useAsync()

  const {
    run: deletePdf,
    error: errorDeletingPdf,
    isLoading: deletingPdf,
  } = useAsync()

  React.useEffect(() => {
    const {
      page = 1,
      limit = 10,
      search = '',
    } = queryString.parse(location.search)

    setPage(page)
    setLimit(limit)
    setSearchKeyword(search)

    getMCQQuestion(
      authAxios.get(
        `/question/?type=choice&page=${page}&limit=${limit}&search=${search}`,
      ),
    ).then(({data}) => {
      setQuestions(data.docs)
      setTotalPages(data.totalPages)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMCQQuestion, location.search , forceReload])

  const handleCancel = () => {
    setIsDeleting(false)
  }
  const handleConfirm = () => {
    deletePdf(
      authAxios.delete(`/question/${currentQuestion?._id}?type=choice`),
    ).then(({data}) => {
      console.log(data)
      setForceReload(p => !p)
    })
    setIsDeleting(false)
  }

  const handleOnPageChange = (e, {activePage}) => {
    history.push(
      `${location.pathname}?page=${activePage}&limit=${limit}&search=${searchKeyword}`,
    )
  }

  const onSearchClick = () => {
    history.push(
      `${location.pathname}?page=${1}&limit=${limit}&search=${searchKeyword}`,
    )
  }

  return (
    <DefaultContainer>
      <Segment className="border-none shadow-none bg-transparent">
        {errorGettingMCQQuestion ? (
          <Message error list={formatResponseError(errorGettingMCQQuestion)} />
        ) : null}
        {errorDeletingPdf ? (
          <Message error list={formatResponseError(errorDeletingPdf)} />
        ) : null}
        <Dimmer active={gettingMCQQuestion || deletingPdf} inverted>
          <Loader active={true} />
        </Dimmer>
        <div className="flex justify-between align-center">
          <h1 className="text-3xl">MCQ Questions</h1>
          <div>
            <Input
              type="text"
              placeholder="Search..."
              size="small"
              className="mr-4"
              action
              value={searchKeyword}
              onChange={(e, data) => {
                setSearchKeyword(data.value)
              }}
            >
              <input />
              <Button type="button" onClick={onSearchClick}>
                Search
              </Button>
            </Input>

            <Link className="text-white" to={routes.mcqQuestion.new}>
              <Button size="small" primary>
                New
              </Button>
            </Link>
          </div>
        </div>

        <div className="mt-12">
          <Table celled striped className="shadow-lg">
            <Table.Header>
              <Table.Row className="text-sm">
                <Table.HeaderCell className="bg-tableHeader">
                  Question Title
                </Table.HeaderCell>
                <Table.HeaderCell className="bg-tableHeader">
                  Subject
                </Table.HeaderCell>
                <Table.HeaderCell className="bg-tableHeader">
                  Difficulty
                </Table.HeaderCell>
                <Table.HeaderCell className="bg-tableHeader">
                  Competency
                </Table.HeaderCell>

                <Table.HeaderCell className="bg-tableHeader">
                  Action
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {questions?.map((a, idx) => (
                <Table.Row key={a._id} textAlign="left" verticalAlign="top">
                  <Table.Cell>{truncate(a.questionTitle, 35)}</Table.Cell>
                  <Table.Cell>{a.subject.name}</Table.Cell>
                  <Table.Cell>{a?.difficulty?.name}</Table.Cell>
                  <Table.Cell>{a?.competency?.name}</Table.Cell>

                  <Table.Cell className='flex'>
                    <Button
                      basic
                      size="small"
                      className="px-3 py-2"
                      onClick={() =>
                        history.push(routes.mcqQuestion.update(a._id))
                      }
                    >
                      <BsPen size="14" />
                    </Button>
                    <Button
                      basic
                      size="small"
                      className="px-3 py-2"
                      onClick={() => {
                        setIsDeleting(p => !p)
                        setCurrentQuestion(a)
                      }}
                    >
                      <RiDeleteBin7Fill size="14" />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>

        
        <Confirm
          content={<div className='p-6'>
            <p className='text-xl font-semibold'>Are you sure to delete {currentQuestion?.questionTitle} ?</p>
            <p className='text-sm mt-1'>by removing this question you will affect the grades and records of all participant students</p>
          </div>}
          open={isDeleting}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          cancelButton="Cancel"
          confirmButton={
            <Button negative className="cofirmDelete bg-red-600 ">
              Delete
            </Button>
          }
        />

        <div className="flex justify-end my-8">
          <Pagination
            activePage={page}
            onPageChange={handleOnPageChange}
            totalPages={totalPages}
          />
        </div>
      </Segment>
    </DefaultContainer>
  )
}

export default QuestMCQ
