import React from 'react'
import {Route, Switch} from 'react-router'
import routes from '../routes'
import Dashboard from '../pages/dashboard'
import CustomComp from '../pages/custom-comp'
import {Sidebar} from '../components/shared/sidebar'
import JournalQuestions from '../pages/questions/journal-question'
import MCQQuestions from '../pages/questions/mcq-question'
import NewJournalQuestion from '../components/journal-question/new-journal-question'
import UpdateJournalQuestion from '../components/journal-question/update-journal-question'
import NewMcqQuestion from '../components/mcq-question/new-mcq-question'
import BlankQuestion from '../pages/questions/blank-question'
import NewBlankQuestion from '../components/blank-question/new-blank-question'
import UpdateMcqQuestion from '../components/mcq-question/update-mcq-question'
import UpdateBlankQuestion from '../components/blank-question/update-blank-question'
import TAccountQuestion from '../pages/questions/t-account-question'
import NewTAccountQuestion from '../components/t-account-question/new-t-account-question'
import UpdateTAccountQuestion from '../components/t-account-question/update-t-account-question'
import AmortizationQuestion from '../pages/questions/amortization-question'
import NewAmortizationQuestion from '../components/amortization-question/new-amortization-question'
import UpdateAmortizationQuestion from '../components/amortization-question/update-amortization-question'
import Competency from '../pages/competency/Competency'
import NewCompetency from '../components/competency/new-competency'
import UpdateCompetency from '../components/competency/update-competency'
import AllFeedbacks from '../pages/feedbacks/all-feedbacks'
import CreateEditFeedback from '../pages/feedbacks/create-edit-feedback'
import useMediaQuery from '../hooks/use-media-query'
import ErrorComp from '../pages/error/error'
import AllSubjects from '../pages/Subjects/all-subjects'
import CreateEditSubjects from '../pages/Subjects/create-edit-subjects'
import AllExercises from '../pages/exercises/all-exercise'
import CreateEditTest from '../pages/exercises/create-edit-test'
import CreateEditWexPex from '../pages/exercises/create-edit-wex-pex'
import AllChapters from '../pages/chapters/all-chapters'
import CreateEditChapter from '../pages/chapters/create-edit-chapter'
import ChapterContent from '../pages/chapters/chapter-content'
import CreateEditSection from '../pages/chapters/sections/crete-edit-section'
import PracticeRoom from '../pages/chapters/practice-room'
import ReviewChapter from '../pages/chapters/review-chapter'
import AllPdf from '../pages/pdf/all-pdf'
import CreateEditPdf from '../pages/pdf/create-edit-pdf'
import AllSettings from '../pages/settings/settings'
import Teacher from '../pages/teacher'
import VoucherStudent from '../pages/voucher-student'
import NonVoucherStudent from '../pages/non-voucher-student'
import VoucherStudentInfo from '../pages/voucherStudentInfo/voucher-student-info'
import TeacherInfo from '../pages/teacherInfo/teacher-info'
import NonVoucherStudentInfo from '../pages/nonVoucherStudentInfo/non-voucher-student-info'
import balanceSheetQuestion from '../pages/questions/balance-sheet-question'
import NewBalanceSheetQuestion from '../components/balance-sheet-question.js/new-balance-sheet-question'
import UpdateBalanceSheetQuestion from '../components/balance-sheet-question.js/update-balance-sheet-question'

const DefaultLayout = () => {
  const isSmall = useMediaQuery('(max-width: 1023px)')

  React.useEffect(() => {
    document.body.classList.add('bg-gray-50')
    return () => document.body.classList.remove('bg-gray-50')
  })

  return (
    <div className="flex">
      <div>
        <Sidebar isSmall={isSmall} />
      </div>

      <div
        className={`${
          isSmall ? '' : 'ml-64 md:px-4 xl:px-10'
        } mx-auto mt-10 w-full`}
      >
        <Switch>
          <Route exact path={routes.dashboard} component={Dashboard} />
          <Route path={routes.customComp} component={CustomComp} />
          
          <Route path={routes.teachers} exact component={Teacher} />
          <Route path={routes.teacherInfo.teacherInfo()} exact component={TeacherInfo} />

          <Route path={routes.voucherstudent} exact component={VoucherStudent} />
          <Route path={routes.voucherstudentInfo.voucherstudentClasses()} exact component={VoucherStudentInfo} />

          <Route exact path={routes.nonvoucherstudent} component={NonVoucherStudent} />
          <Route exact path={routes.nonvoucherstudentInfo.nonvoucherstudentsubjects()} component={NonVoucherStudentInfo} />

          {/* journal section */}
          <Route
            exact
            path={routes.jouralQusetion.all}
            component={JournalQuestions}
          />
          <Route
            path={routes.jouralQusetion.new}
            component={NewJournalQuestion}
          />
          <Route
            path={routes.jouralQusetion.update()}
            component={UpdateJournalQuestion}
          />

          {/* MCQ section */}
          <Route exact path={routes.mcqQuestion.all} component={MCQQuestions} />
          <Route path={routes.mcqQuestion.new} component={NewMcqQuestion} />
          <Route
            path={routes.mcqQuestion.update()}
            component={UpdateMcqQuestion}
          />

          {/* Blank section */}
          <Route
            exact
            path={routes.blankQuestion.all}
            component={BlankQuestion}
          />
          <Route path={routes.blankQuestion.new} component={NewBlankQuestion} />
          <Route
            path={routes.blankQuestion.update()}
            component={UpdateBlankQuestion}
          />

          {/* tAccount section */}
          <Route
            exact
            path={routes.tAccountQuestion.all}
            component={TAccountQuestion}
          />
          <Route
            path={routes.tAccountQuestion.new}
            component={NewTAccountQuestion}
          />
          <Route
            path={routes.tAccountQuestion.update()}
            component={UpdateTAccountQuestion}
          />

          {/* amotization section */}
          <Route
            exact
            path={routes.amortizationQuestion.all}
            component={AmortizationQuestion}
          />
          <Route
            path={routes.amortizationQuestion.new}
            component={NewAmortizationQuestion}
          />
          <Route
            path={routes.amortizationQuestion.update()}
            component={UpdateAmortizationQuestion}
          />
          {/* balance Sheet Question */}
          <Route
            exact
            path={routes.balanceSheetQuestion.all}
            component={balanceSheetQuestion}
          />
          <Route
            path={routes.balanceSheetQuestion.new}
            component={NewBalanceSheetQuestion}
          />
          <Route
            path={routes.balanceSheetQuestion.update()}
            component={UpdateBalanceSheetQuestion}
          />

          {/* competency */}
          <Route exact path={routes.competency.all} component={Competency} />
          <Route path={routes.competency.new} component={NewCompetency} />
          <Route
            path={routes.competency.update()}
            component={UpdateCompetency}
          />
          {/* category feedback */}
          <Route exact path={routes.feedbacks.all} component={AllFeedbacks} />
          <Route
            exact
            path={routes.feedbacks.new}
            component={CreateEditFeedback}
          />
          <Route
            exact
            path={routes.feedbacks.update()}
            component={CreateEditFeedback}
          />

          {/* Subjects */}
          <Route exact path={routes.subjects.all} component={AllSubjects} />
          <Route
            exact
            path={routes.subjects.new}
            component={CreateEditSubjects}
          />
          <Route
            exact
            path={routes.subjects.update()}
            component={CreateEditSubjects}
          />
          {/* routes for chapters */}
          <Route
            exact
            path={routes.subjects.chapters.all()}
            component={AllChapters}
          />
          <Route
            exact
            path={routes.subjects.chapters.new()}
            component={CreateEditChapter}
          />
          <Route
            exact
            path={routes.subjects.chapters.update()}
            component={CreateEditChapter}
          />
          <Route
            exact
            path={routes.subjects.chapters.content()}
            component={ChapterContent}
          />
          <Route
            exact
            path={routes.subjects.chapters.review()}
            component={ReviewChapter}
          />
          {/* routes for sections */}
          <Route
            exact
            path={routes.subjects.chapters.sections.all()}
            component={PracticeRoom}
          />
          <Route
            exact
            path={routes.subjects.chapters.sections.new()}
            component={CreateEditSection}
          />
          <Route
            exact
            path={routes.subjects.chapters.sections.update()}
            component={CreateEditSection}
          />

          {/* exercises */}
          <Route exact path={routes.exerice.all} component={AllExercises} />
          <Route
            exact
            path={routes.exerice.newTest}
            component={CreateEditTest}
          />
          <Route
            exact
            path={routes.exerice.newPexWex}
            component={CreateEditWexPex}
          />
          <Route
            exact
            path={routes.exerice.updateTest()}
            component={CreateEditTest}
          />
          <Route
            exact
            path={routes.exerice.updatePexWex()}
            component={CreateEditWexPex}
          />

          {/* pdfs */}
          <Route exact path={routes.pdfs.all} component={AllPdf} />
          <Route exact path={routes.pdfs.new()} component={CreateEditPdf} />         
          {/* <Route
            exact
            path={routes.pdfs.update()}
            component={CreateEditPdf}
          /> */}
           <Route exact path={routes.settings.all} component={AllSettings} />

          <Route path="*" component={ErrorComp} />
        </Switch>
      </div>
    </div>
  )
}

export default DefaultLayout
