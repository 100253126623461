import {Formik} from 'formik'
import React from 'react'
import {useHistory} from 'react-router'
import {Button, Form, Message} from 'semantic-ui-react'
import * as Yup from 'yup'

import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import useDifficulty from '../../hooks/use-difculty'
import routes from '../../routes'
import {formatResponseError} from '../../utils/format-response'
import FormikDropdown from '../formik/FormikDropdown'
import FormikInput from '../formik/FormikInput'
import DefaultContainer from '../shared/default-container'

const NewJournalQuestion = () => {
  const [subjects, setSubjects] = React.useState([])
  const history = useHistory()
  const {difficulties, gettingDifficulties, errorGettingDifficulty} =
    useDifficulty()
  const [competency, setCompetency] = React.useState([])

  const {
    run: getSubjects,
    error: errorGettingSubjects,
    isLoading: gettingSubjects,
  } = useAsync()
  const {
    run: createQuestion,
    error: errorCreatingQuestion,
    isLoading: creatingQuestion,
  } = useAsync()

  const {
    run: getCompetency,
    error: errorGettingCompetency,
    isLoading: gettingCompetency,
  } = useAsync()

  const newJournalQuestionSchema = Yup.object({
    questionTitle: Yup.string().trim().required('Required'),
    subject: Yup.string().trim().required(),
    difficulty: Yup.string().trim().required(),
    hint: Yup.string().trim().required(),
    competency: Yup.string().trim().required(),
  })

  const createNewQuestion = values => {
    createQuestion(authAxios.post('/question/journal', values)).then(
      ({data}) => {
        history.push(routes.jouralQusetion.update(data?._id), {
          new: true,
          data,
        })
      },
    )
  }

  React.useEffect(() => {
    getSubjects(authAxios.get('/school/subject/all')).then(({data}) => {
      let options = []
      data?.subjects.forEach(s =>
        options.push({text: s.name, key: s._id, value: s._id}),
      )
      setSubjects(options)
    })
  }, [getSubjects])

  const getCompetencyOptions = React.useCallback(
    subjectId => {
      getCompetency(
        authAxios.get(`/school/subject/competency/all/?id=${subjectId}`),
      ).then(({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.name, key: s._id, value: s._id}),
        )
        setCompetency(options)
      })
    },
    [getCompetency],
  )

  return (
    <DefaultContainer>
      <h2 className="mb-8">Create new Journal question</h2>

      {errorGettingSubjects ? (
        <Message error list={formatResponseError(errorGettingSubjects)} />
      ) : null}
      {errorGettingDifficulty ? (
        <Message error list={formatResponseError(errorGettingDifficulty)} />
      ) : null}
      {errorCreatingQuestion ? (
        <Message error list={formatResponseError(errorCreatingQuestion)} />
      ) : null}
      {errorGettingCompetency ? (
        <Message error list={formatResponseError(errorGettingCompetency)} />
      ) : null}
      <div className="max-w-lg">
        <Formik
          initialValues={{
            subject: '',
            questionTitle: '',
            difficulty: '',
            hint: '',
            competency: '',
          }}
          validationSchema={newJournalQuestionSchema}
          onSubmit={createNewQuestion}
        >
          {formik => (
            <Form loading={creatingQuestion} onSubmit={formik.handleSubmit}>
              <FormikInput name="questionTitle" label="Question title" />
              <FormikDropdown
                label="Subject"
                name="subject"
                loading={gettingSubjects}
                options={subjects}
                onChangeCB={value => {
                  getCompetencyOptions(value)
                  formik.setFieldValue('competency', '')
                }}
              />

              <FormikDropdown
                label="Competency"
                name="competency"
                placeholder="please select a subject first"
                loading={gettingCompetency}
                options={competency}
                disabled={gettingCompetency}
              />
              <FormikDropdown
                label="Difficulty"
                options={difficulties}
                name="difficulty"
                loading={gettingDifficulties}
              />
              <FormikInput
                label="Hint"
                name="hint"
                placeholder="Question hint"
              />
              <div className="flex justify-between my-8">
                <Button size="tiny" primary>
                  Continue
                </Button>
                <Button
                  className="mr-2"
                  size="tiny"
                  onClick={() => history.push(routes.jouralQusetion.all)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </DefaultContainer>
  )
}

export default NewJournalQuestion
