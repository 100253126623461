export const content = {
  en: {
    login: 'Login',
    register: 'Register',
    fullName: 'Full Name',
    email: 'Email Address',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    required: 'This Field is Required',
    notMatch: 'Password is not match',
    userName: "User Name (can't be changed later)",
    studentRegisterCheckBox1:
      'Email me platform updates, recommended courses, and the latest news',
    studentRegisterCheckBox2:
      'By clicking on register, I agree to the Terms of Service and Privacy Policy',
    studentRegisterCheckBox3: 'I agree that I am over 12 years old',
    instructorDegree: 'Degree',
    university: 'university',
    faculty: 'faculty',
    specialization: 'Specialization',
    phoneNumber: 'Phone Number',
    homePage: 'Home Page',
    aboutUs: 'About us',
    courses: 'Courses',
    contact: 'Contact',
    loginToAccount: 'Log In To Your Account',
    donntHaveAccount: "Don't have an account on Indil? ",
    createNewAccount: 'Create new Account',
    rememberPassword: 'Remember Password',
    forgotPassword: 'Forgot Password?',
    craeteFreeAccount: 'Create Free Account',
    haveAnAccount: 'Do you have an Account on Indil? ',
    homepageTitle1:
      'Excellence with Indil is stronger, faster, easier and more economical',
    homepageTitle2: 'By answering the course questions in an efficient manner',
    startLearning: 'Start Learning',
    homePageCardTitle1: 'One-time costs for your studies',
    homePageCardSemiTitle1: 'There are no more costs involved',
    homePageCardTitle2: 'Integrated learning environment',
    homePageCardSemiTitle2:
      'A perfect environment for communication between the teacher and his students',
    homePageCardTitle3: 'full technical support',
    homePageCardSemiTitle3: 'Technical support online or by phone',
    DepartmentsAndSpecializations: 'Departments And Specializations',
    DepartmentsSemiTitle:
      'Learn about a group of courses offered to you through the platform in cooperation with the largest faculty members in colleges in the KSA in order to prepare for the various qualification exams',
    All:'All',
    InformationTech:'Information Technology',
    FiqhAndSharia:'Fiqh And Sharia',
    petrolEnginering:'Petrol Engineering',
    biologicalAndEnv:'Biological and environment',
    nursing:'Nursing',
    MedicalScience:'Medical Sciences',
    loadMore:'Load More',
    exploreNow:'Explore Now',
    latestebooks:'Latest e-books and summaries',
    EliteFacultyMembers :'Elite faculty members',
    PartnershipAndCooperation:'Partnership And Cooperation',
    subject:'subject',
    school:'school',
  },



  fr: {
    login: 'Login',
    register: 'Register',
    fullName: 'Full Name',
    email: 'Email Address',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    required: 'This Field is Required',
    notMatch: 'Password is not match',
    userName: "User Name (can't be changed later)",
    studentRegisterCheckBox1:
      'Email me platform updates, recommended courses, and the latest news',
    studentRegisterCheckBox2:
      'By clicking on register, I agree to the Terms of Service and Privacy Policy',
    studentRegisterCheckBox3: 'I agree that I am over 12 years old',
    instructorDegree: 'Degree',
    university: 'university',
    faculty: 'faculty',
    specialization: 'Specialization',
    phoneNumber: 'Phone Number',
    homePage: 'le Home Pagea',
    aboutUs: 'About us',
    courses: 'Courses',
    contact: 'Contact',
    loginToAccount: 'Log In To Your Account',
    donntHaveAccount: "Don't have an account on Indil? ",
    createNewAccount: 'Create new Account',
    rememberPassword: 'Remember Password',
    forgotPassword: 'Forgot Password?',
    craeteFreeAccount: 'Create Free Account',
    haveAnAccount: 'Do you have an Account on Indil? ',
    homepageTitle1:
      'Excellence with Indil is stronger, faster, easier and more economical',
    homepageTitle2: 'By answering the course questions in an efficient manner',
    startLearning: 'Start Learning',
    homePageCardTitle1: 'One-time costs for your studies',
    homePageCardSemiTitle1: 'There are no more costs involved',
    homePageCardTitle2: 'Integrated learning environment',
    homePageCardSemiTitle2:
      'A perfect environment for communication between the teacher and his students',
    homePageCardTitle3: 'full technical support',
    homePageCardSemiTitle3: 'Technical support online or by phone',
    DepartmentsAndSpecializations: 'Departments And Specializations',
    DepartmentsSemiTitle:
      'Learn about a group of courses offered to you through the platform in cooperation with the largest faculty members in colleges in the KSA in order to prepare for the various qualification exams',
    All:'All',
    InformationTech:'Information Technology',
    FiqhAndSharia:'Fiqh And Sharia',
    petrolEnginering:'Petrol Engineering',
    biologicalAndEnv:'Biological and environment',
    nursing:'Nursing',
    MedicalScience:'Medical Sciences',
    loadMore:'Load More',
    exploreNow:'Explore Now',
    latestebooks:'Latest e-books and summaries',
    EliteFacultyMembers :'Elite faculty members',
    PartnershipAndCooperation:'Partnership And Cooperation',
    },
}
