import {FieldArray, Formik} from 'formik'
import React from 'react'
import {useHistory, useLocation, useParams} from 'react-router'
import {Button, Form, Image, Message, Table} from 'semantic-ui-react'
import {IoMdAdd, IoMdRemove} from 'react-icons/io'
import * as Yup from 'yup'

import {authAxios} from '../../config/axios-config'
import useAccountNames from '../../hooks/use-account-names'
import useAsync from '../../hooks/use-async'
import useCategory from '../../hooks/use-category'
import useDifficulty from '../../hooks/use-difculty'
import useSubject from '../../hooks/use-subjects'
import routes from '../../routes'
import {convertArrayOfObjToFormData} from '../../utils/form-data'
import {formatResponseError} from '../../utils/format-response'
import FormikDropdown from '../formik/FormikDropdown'
import FormikInput from '../formik/FormikInput'
import DefaultContainer from '../shared/default-container'
import FileAttachment from '../shared/FileAttachment'

const UpdateTAccountQuestion = () => {
  const [question, setQuestion] = React.useState({})
  const [sectionsError, setSectionsError] = React.useState(null)

  const [competency, setCompetency] = React.useState([])
  const [attachmentData, setAttachmentData] = React.useState(null)

  const location = useLocation()
  const {id} = useParams()
  const [state, _] = React.useState(location?.state)

  const history = useHistory()
  const {subjects, errorGettingSubjects, gettingSubjects} = useSubject()
  const {category, gettingCategory, errorGettingCategory} = useCategory()

  const {difficulties, gettingDifficulties, errorGettingDifficulty} =
    useDifficulty()
  const {accountNames, errorGettingAccountNames, gettingAccountNames} =
    useAccountNames()

  const {
    run: getQuestion,
    error: errorGettingQuestion,
    isLoading: gettingQuestion,
  } = useAsync()

  const {
    run: getSectionNames,
    data: sectionNames,
    error: errorGettingSectionNames,
    isLoading: gettingSectionNames,
  } = useAsync({data: []})

  const {
    run: updateQuestion,
    error: errorUpdatingQuestion,
    isLoading: updatingQuestion,
  } = useAsync({data: []})
  const {
    run: getCompetency,
    error: errorGettingCompetency,
    isLoading: gettingCompetency,
  } = useAsync()
  const updateTAccountQuestionSchema = Yup.object({
    questionTitle: Yup.string().trim().required('Required'),
    question: Yup.string().trim().required('Required'),
    subject: Yup.string().trim().required(),
    feedbackCategory: Yup.string().trim().required(),
    competency: Yup.string().trim().required(),
    difficulty: Yup.string().trim().required(),
    hint: Yup.string().trim().required(),
  })

  function onUpdate(values) {
    const errors = []
    let noValues = 0
    // console.log({values})

    values.sections.forEach((s, i) => {
      if (s?.answers?.length === 0) noValues++

      s?.answers?.forEach((ans, j) => {
        if (!ans.account) noValues++

        if (ans?.account?.trim() && !ans?.creditValue && !ans?.debitValue)
          errors.push(
            `You have to add credit or debit values in table ${i + 1} row ${
              j + 1
            }`,
          )
        if ((ans?.creditValue || ans?.debitValue) && !ans?.account?.trim())
          errors.push(
            `You have to add account name in table ${i + 1} row ${j + 1}`,
          )
        if (
          (ans?.creditValue &&
            !ans?.creditValue?.match(
              /(^\d{1,3}(\.?\d{3})*(,\d{2})?$)|(^\d{1,3}(,?\d{3})*(\.\d{2})?$)/,
            )) ||
          (ans?.debitValue &&
            !ans?.debitValue?.match(
              /(^\d{1,3}(\.?\d{3})*(,\d{2})?$)|(^\d{1,3}(,?\d{3})*(\.\d{2})?$)/,
            ))
        )
          errors.push(
            `Only numbers are allowed in debit and credit values in table ${
              i + 1
            } row ${j + 1}`,
          )

        // if (ans?.account?.trim() && !ans?.creditValue) {
        //   console.log('nnnn')
        //   ans.creditValue = undefined
        // }
      })
    })

    if (noValues === sectionNames.length) {
      setSectionsError([`You have to add values for at least one table`])
      return
    } else if (errors.length > 0) {
      setSectionsError(errors)
      return
    } else {
      setSectionsError(null)
    }

    const manipulatedValues = {...values}
    manipulatedValues.sections = values.sections.map(s => ({
      ...s,
      answers: s.answers.filter(ans => Boolean(ans.account)),
    }))
    // for(let d in manipulatedValues.sections){
    //   console.log(d[values]);
    // }

    const formData = new FormData()

    // sections[0][answers][0]

    // for (let i in manipulatedValues) {
    //   if (i === 'sections') {
    //     for (let j of manipulatedValues[i]) {
    //       formData(`${i}`)
    //       convertArrayOfObjToFormData(formData, j.answers, `${i}[answers]`)
    //     }
    //   } else {
    //     formData.append(i, values[i])
    //   }
    // }

    Object.entries(manipulatedValues).forEach(([k, v], i) => {
      if (k === 'sections') {
        manipulatedValues[k].forEach((s, i) => {
          formData.append(`${k}[${i}][sectionName]`, s.sectionName)
          convertArrayOfObjToFormData(
            formData,
            s.answers,
            `${k}[${i}][answers]`,
          )
        })
      } else {
        formData.append(k, v)
      }
    })

    for (let pair of formData.entries()) {
      // console.log(pair[0] + ', ' + pair[1])
      if (!pair[1]) {
        formData.delete(pair[0])
      }
    }

    if (attachmentData) formData.append('img', attachmentData)

    updateQuestion(authAxios.patch(`/question/taccount/${id}`, formData)).then(
      ({data}) => {
        history.push(routes.tAccountQuestion.all)
      },
    )
  }

  const getCompetencyOptions = React.useCallback(
    subjectId => {
      getCompetency(
        authAxios.get(`/school/subject/competency/all/?id=${subjectId}`),
      ).then(({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.name, key: s._id, value: s._id}),
        )
        setCompetency(options)
      })
    },
    [getCompetency],
  )

  React.useEffect(() => {
    if (state?.data?.subject) {
      getCompetency(
        authAxios.get(
          `/school/subject/competency/all/?id=${state?.data?.subject}`,
        ),
      ).then(({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.name, key: s._id, value: s._id}),
        )
        setCompetency(options)
      })
    }
  }, [getCompetency, state?.data?.subject])

  React.useEffect(() => {
    // ignore getting request when redirected from the create view
    if (state?.new) return

    if (sectionNames.length > 0)
      getQuestion(authAxios.get(`/question/taccount/?qid=${id}`)).then(
        ({data}) => {
          getCompetency(
            authAxios.get(
              `/school/subject/competency/all/?id=${data.subject._id}`,
            ),
          ).then(({data}) => {
            let options = []
            data?.forEach(s =>
              options.push({text: s.name, key: s._id, value: s._id}),
            )
            setCompetency(options)
          })

          let sections = []
          sections = sectionNames.map(s => {
            const sd = data?.sections.find(sd => sd.sectionName === s._id)
            if (sd?.sectionName === s._id && sd?.answers.length > 0) return sd
            else
              return {
                sectionName: s._id,
                answers: [
                  {
                    accountName: '',
                    debitValue: '',
                    creditValue: '',
                  },
                ],
              }
          })

          setQuestion({...data, sections})
        },
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuestion, id, state?.new, sectionNames?.length])

  React.useEffect(() => {
    getSectionNames(authAxios.get('/question/taccount/sectionname'))
  }, [getSectionNames])

  return (
    <DefaultContainer>
      <h2 className="mb-8">
        {state?.new ? 'Create new T-Account question' : 'Update T-Account'}
      </h2>

      {errorGettingSubjects ? (
        <Message error list={formatResponseError(errorGettingSubjects)} />
      ) : null}
      {errorGettingCategory ? (
        <Message error list={formatResponseError(errorGettingCategory)} />
      ) : null}
      {errorGettingSectionNames ? (
        <Message error list={formatResponseError(errorGettingSectionNames)} />
      ) : null}
      {errorGettingAccountNames ? (
        <Message error list={formatResponseError(errorGettingAccountNames)} />
      ) : null}
      {errorUpdatingQuestion ? (
        <Message error list={formatResponseError(errorUpdatingQuestion)} />
      ) : null}
      {errorGettingQuestion ? (
        <Message error list={formatResponseError(errorGettingQuestion)} />
      ) : null}
      {errorGettingDifficulty ? (
        <Message error list={formatResponseError(errorGettingDifficulty)} />
      ) : null}
      {sectionsError?.length > 0 ? (
        <Message error list={sectionsError} />
      ) : null}
      {errorGettingCompetency ? (
        <Message error list={formatResponseError(errorGettingCompetency)} />
      ) : null}

      <Formik
        initialValues={{
          subject: question?.subject?._id || state?.data?.subject || '',
          competency: question?.competency || state?.data?.competency || '',
          feedbackCategory: question?.feedbackCategory || '',

          difficulty: question?.difficulty || state?.data?.difficulty,
          questionTitle: question?.questionTitle || state?.data?.questionTitle,
          hint: question?.hint || state?.data?.hint,
          question: question?.question,
          sections:
            question?.sections ||
            sectionNames?.map(s => ({
              sectionName: s._id,
              answers: [
                {
                  account: '',
                  debitValue: '',
                  creditValue: '',
                },
              ],
            })),
        }}
        enableReinitialize
        onSubmit={onUpdate}
        validationSchema={updateTAccountQuestionSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {formik => (
          <Form
            onSubmit={formik.handleSubmit}
            loading={
              gettingSectionNames ||
              gettingAccountNames ||
              updatingQuestion ||
              gettingQuestion
            }
          >
            <div class="max-w-xl">
              <FormikDropdown
                label="Subject"
                name="subject"
                loading={gettingSubjects}
                options={subjects}
                onChangeCB={value => {
                  getCompetencyOptions(value)
                  formik.setFieldValue('competency', '')
                }}
              />

              <FormikDropdown
                label="Competency"
                name="competency"
                loading={gettingCompetency}
                options={competency}
                disabled={gettingCompetency}
              />

              <FormikDropdown
                label="Difficulty"
                options={difficulties}
                name="difficulty"
                loading={gettingDifficulties}
              />

              <FormikInput
                label="Question title"
                name="questionTitle"
                placeholder="Question title"
              />
              <FormikInput
                label="Hint"
                name="hint"
                placeholder="Question hint"
              />
              <FormikDropdown
                label="Feedback Category"
                name="feedbackCategory"
                loading={gettingCategory}
                options={category}
              />
              <FormikInput
                label="Question"
                name="question"
                placeholder="Question"
              />
            </div>

            <div className="mt-10">
              <h3 className="mb-5">T-Account Table</h3>
              <div class="flex flex-wrap">
                <FieldArray name="sections">
                  {() =>
                    formik.values.sections?.length > 0
                      ? formik.values.sections.map((s, i) => (
                          <div class="flex flex-col mb-8" key={i}>
                            <p
                              className={`mb-2 font-semibold ${
                                i % 2 === 0 ? '' : 'ml-4'
                              }`}
                            >
                              {
                                sectionNames?.find(
                                  sn => sn._id === s.sectionName,
                                )?.name
                              }
                            </p>
                            <FieldArray key={i} name={`sections.${i}.answers`}>
                              {({push, remove}) =>
                                formik.values.sections[i].answers
                                  ? formik.values.sections[i].answers?.map(
                                      (a, j) => {
                                        return (
                                          <div
                                            key={j}
                                            className={`mb-4 w-111 ${
                                              i % 2 === 0
                                                ? 'sm:mr-4'
                                                : 'sm:ml-4'
                                            }`}
                                          >
                                            <div class="flex items-end">
                                              <div className="flex-grow mr-2">
                                                <FormikDropdown
                                                  name={`sections.${i}.answers.${j}.account`}
                                                  options={accountNames}
                                                  placeholder="Account name"
                                                  search
                                                  clearable
                                                />
                                              </div>
                                              <div class="flex items-center">
                                                {formik.values.sections[i]
                                                  .answers.length -
                                                  1 ===
                                                  j &&
                                                formik.values.sections[i]
                                                  .answers.length < 3 ? (
                                                  <Button
                                                    basic
                                                    type="button"
                                                    size="small"
                                                    className="mr-2"
                                                    onClick={() =>
                                                      push({
                                                        account: '',
                                                        debitValue: '',
                                                        creditValue: '',
                                                      })
                                                    }
                                                  >
                                                    <IoMdAdd />
                                                  </Button>
                                                ) : null}
                                                {j !== 0 ? (
                                                  <Button
                                                    basic
                                                    type="button"
                                                    size="small"
                                                    onClick={() => remove(j)}
                                                  >
                                                    <IoMdRemove />
                                                  </Button>
                                                ) : null}
                                              </div>
                                            </div>
                                            <Table basic="very" singleLine>
                                              <Table.Row>
                                                <Table.HeaderCell>
                                                  D
                                                </Table.HeaderCell>
                                                <Table.HeaderCell textAlign="right">
                                                  C
                                                </Table.HeaderCell>
                                              </Table.Row>
                                              <Table.Row>
                                                <Table.Cell>
                                                  <FormikInput
                                                    name={`sections.${i}.answers.${j}.debitValue`}
                                                    isNumber
                                                    disabled={Boolean(
                                                      formik.values.sections[i]
                                                        .answers[j].creditValue,
                                                    )}
                                                  />
                                                </Table.Cell>
                                                <Table.Cell>
                                                  <FormikInput
                                                    name={`sections.${i}.answers.${j}.creditValue`}
                                                    isNumber
                                                    disabled={Boolean(
                                                      formik.values.sections[i]
                                                        .answers[j].debitValue,
                                                    )}
                                                  />
                                                </Table.Cell>
                                              </Table.Row>
                                            </Table>
                                          </div>
                                        )
                                      },
                                    )
                                  : null
                              }
                            </FieldArray>
                          </div>
                        ))
                      : null
                  }
                </FieldArray>
              </div>
            </div>

            {question?.image && (
              <Image
                size="medium"
                src={question?.image}
                rounded
                className="mb-4 mt-8"
              />
            )}

            <FileAttachment setAttachmentData={setAttachmentData} />

            <div className="my-5 flex justify-between">
              <Button size="tiny" primary type="submit">
                {state?.new ? 'Save' : 'Update'}
              </Button>
              <Button
                className="mr-2"
                size="tiny"
                onClick={() => history.push(routes.tAccountQuestion.all)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </DefaultContainer>
  )
}

export default UpdateTAccountQuestion
