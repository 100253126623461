import React from "react";
import { Controller } from "react-hook-form";
import { FormInput } from "semantic-ui-react";
import { truncateToDecimal } from "../../utils/numbers";

const FormInputController = ({
  control,
  name,
  label,
  isNumber,
  setValue,
  controlProps,
  inputProps,
  hookFormErrors,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      {...controlProps}
      render={({ field }) => {
        // console.log(control)
        // console.log({FieldERror: hookFormErrors?.[name]})
        // console.log(control?._formState?.errors?.[name])
        const controllerOnBlur = (e) => {
          // debugger
          // if(isNumber)
          // field.onBlur({
          //   ...e,
          //   target: { ...e.target, value: truncateToDecimal(e.target.value) },
          // });
          // console.log(e.target.value)
          if (isNumber) {
            if (field.value.toString()?.trim() !== "") {
              if (field.value === "0") setValue(name, "0");
              else setValue(name, truncateToDecimal(field.value));
            } else {
              setValue(name, "");
            }
          }
        };
        // My name is Essam Qabel, and this is me trying Desoqy's keyborad, it's a shity keyboard, like his face
        return (
          <FormInput
            // error={`${hookFormErrors}.${name}`}
            // error={hookFormErrors?.[name] && hookFormErrors?.[name]?.message}
            error={control?._formState?.errors?.[name] && control?._formState?.errors?.[name]?.message}
            autoComplete="off"
            {...field}
            label={label}
            {...inputProps}
            onBlur={(e) => controllerOnBlur(e)}
          />
        );
      }}
    />
  );
};

export default FormInputController;
