import React, {useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Accordion, Button, Dimmer, Loader, Segment} from 'semantic-ui-react'

import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import routes from '../../routes'
import BasicChapterReview from './review-compoents/basic-chapter-review'
import HomwworkRoomReview from './review-compoents/homework-room-review'
import PracticeRoomReview from './review-compoents/practice-room-review'

const ReviewChapter = () => {
  const [forceReload, setForceReload] = React.useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const history = useHistory()
  const {chapterid, subjectid} = useParams()

  const {run: reviewChapter, isLoading: reviewingChapter} = useAsync()

  const level3Panels = [
    {
      key: 'panel-2a',
      title: 'Beginner',
      content: {
        content: (
          <div>
            {[2].includes(activeIndex) && (
              <HomwworkRoomReview
                level="beginner"
                onUpdate={forceReload}
                onReload={() => setForceReload(p => !p)}
              />
            )}
          </div>
        ),
      },
    },
    {
      key: 'panel-2b',
      title: 'Intermidate',
      content: {
        content: (
          <div>
            {[2].includes(activeIndex) && (
              <HomwworkRoomReview
                level="intermidate"
                onUpdate={forceReload}
                onReload={() => setForceReload(p => !p)}
              />
            )}
          </div>
        ),
      },
    },
    {
      key: 'panel-2b',
      title: 'Advanced',
      content: {
        content: (
          <div>
            {[2].includes(activeIndex) && (
              <HomwworkRoomReview
                level="advanced"
                onUpdate={forceReload}
                onReload={() => setForceReload(p => !p)}
              />
            )}
          </div>
        ),
      },
    },
  ]

  const Level3Content = (
    <div>
      {/* Welcome to level 2 */}
      <Accordion.Accordion panels={level3Panels} />
    </div>
  )

  const rootPanels = [
    {
      key: 'panel-1',
      title: 'Basic Info',
      content: {
        content: (
          <div>{[0].includes(activeIndex) && <BasicChapterReview />}</div>
        ),
      },
    },
    {
      key: 'panel-2',
      title: 'Practice Room',
      content: {
        content: (
          <div>{[1].includes(activeIndex) && <PracticeRoomReview />}</div>
        ),
      },
    },
    {key: 'panel-3', title: 'Homework Room', content: {content: Level3Content}},
  ]

  const handleOnClickTitle = (e, itemProps) => {
    console.log(itemProps)
    setActiveIndex(itemProps.index)
  }

  const onReview = () => {
    let obj = {
      status: 'Publish',
    }
    reviewChapter(
      authAxios.patch(`/chapter/status?cid=${chapterid}`, obj),
    ).then(({data}) => {
      history.push(routes.subjects.chapters.all(subjectid))
    })
  }

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent w-full">
        <Dimmer active={reviewingChapter} inverted>
          <Loader active={true} />
        </Dimmer>
        <p className="my-2 text-3xl font-semibold  ">Chapter Review</p>
        <Accordion
          defaultActiveIndex={0}
          activeIndex={activeIndex}
          onTitleClick={handleOnClickTitle}
          panels={rootPanels}
          styled
          className="w-full my-5 "
        />
        <div className="my-5 mt-10 flex justify-between">
          <Button size="tiny" primary onClick={onReview}>
            Pubish
          </Button>
          <Button
            className=""
            size="tiny"
            onClick={() =>
              history.push(
                routes.subjects.chapters.content(subjectid, chapterid),
              )
            }
          >
            Cancel
          </Button>
        </div>
      </Segment>
    </div>
  )
}

export default ReviewChapter
