import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {
  Button,
  Dimmer,
  Form,
  Input,
  Loader,
  Message,
  Modal,
  Pagination,
  Segment,
  Table,
} from 'semantic-ui-react'
import queryString from 'query-string'
import {useLocation, useHistory} from 'react-router-dom'
import {AiOutlineCaretDown, AiOutlineCaretRight} from 'react-icons/ai'
import {BsPen} from 'react-icons/bs'
import {AiFillCopy} from 'react-icons/ai'
import * as Yup from 'yup'

import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import routes from '../../routes'
import {formatResponseError} from '../../utils/format-response'
import DefaultContainer from '../../components/shared/default-container'
import FormikInput from '../../components/formik/FormikInput'
import {Formik} from 'formik'
import { truncate } from '../../utils/string'
import { Fragment } from 'react/cjs/react.production.min'

const AllFeedbacks = () => {
  const [feedbacks, setFeedbacks] = React.useState([])
  const [categoryToExpand, setCategoryToExpand] = React.useState(null)
  const [categoryDetails, setCategoryDetails] = React.useState(null)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [addingCategoryName, setAddingCategoryName] =
  React.useState(false)
  const[dataToSend,setDataToSend]=React.useState()
  const [forceReload, setForceReload] = React.useState(false)

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [limit, setLimit] = useState(10)

  const location = useLocation()
  const history = useHistory()

  const {
    run: getFeedbackCategories,
    error: errorGettingFeedbackCategories,
    isLoading: gettingFeedbackCategories,
  } = useAsync()

  const {
    run: getFeedbackCategoryDetails,
    error: errorGettingFeedbackCategoryDetails,
    isLoading: gettingFeedbackCategoryDetails,
  } = useAsync()
  const {
    run: getFeedback,
    isLoading: gettingFeedback,
    error: errorGettingFeedback,
  } = useAsync({data: []})


  const handleOnPageChange = (e, {activePage}) => {
    history.push(
      `${location.pathname}?page=${activePage}&limit=${limit}&search=${searchKeyword}`,
    )
  }

  const onSearchClick = () => {
    history.push(
      `${location.pathname}?page=${page}&limit=${limit}&search=${searchKeyword}`,
    )
  }

  React.useEffect(() => {
    if (categoryToExpand?._id)
      getFeedbackCategoryDetails(
        authAxios.get(
          `/question/tablefeedback/category?id=${categoryToExpand?._id}`,
        ),
      ).then(({data}) => {
        console.log(data);
        setCategoryDetails(data)
      })
  }, [categoryToExpand?._id, getFeedbackCategoryDetails ,forceReload])

  React.useEffect(() => {
    const {
      page = 1,
      limit = 10,
      search = '',
    } = queryString.parse(location.search)

    setPage(page)
    setLimit(limit)
    setSearchKeyword(search)

    getFeedbackCategories(
      authAxios.get(
        `/question/?type=feedbackCategory&page=${page}&limit=${limit}&search=${search}`,
      ),
    ).then(({data}) => {
      setFeedbacks(data.docs)
      setTotalPages(data.totalPages)
    })

  }, [getFeedbackCategories, location.search,forceReload])

  const onDublicate = id => {
    getFeedback(
      authAxios.get(`/question/tablefeedback/category?id=${id}`),
    ).then(({data}) => {
      setDataToSend(data)
      setAddingCategoryName(true)
    
    })
  }

  return (
    <DefaultContainer>
    <Segment className="border-none shadow-none bg-transparent">

      {errorGettingFeedbackCategories ? (
        <Message
          error
          list={formatResponseError(errorGettingFeedbackCategories)}
        />
      ) : null}
      {errorGettingFeedbackCategoryDetails ? (
        <Message
          error
          list={formatResponseError(errorGettingFeedbackCategoryDetails)}
        />
      ) : null}
      
      {errorGettingFeedback ? (
        <Message
          error
          list={formatResponseError(errorGettingFeedback)}
        />
      ) : null}
      <Dimmer
        active={
          gettingFeedbackCategories || gettingFeedback 
        }
        inverted
      >
        <Loader active={true} />
      </Dimmer>
      <div className="flex justify-between align-center">
        <h1 className="text-3xl">Feedbacks</h1>
        <div>
          <Input
            type="text"
            placeholder="Search..."
            size="small"
            className="mr-4"
            action
            value={searchKeyword}
            onChange={(e, data) => {
              setSearchKeyword(data.value)
            }}
          >
            <input />
            <Button type="button" onClick={onSearchClick}>
              Search
            </Button>
          </Input>

          <Link className="text-white" to={routes.feedbacks.new}>
            <Button size="small" primary>
              New
            </Button>
          </Link>
        </div>
      </div>

      <div className="mt-12">
        <Table celled>
          <Table.Header>
            <Table.Row className="text-sm">
              <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
              <Table.HeaderCell>Category name</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {feedbacks?.map((a, idx) => (
              <Fragment key={a?._id}>
                <Table.Row key={a._id} textAlign="left" verticalAlign="top">
                  <Table.Cell
                    textAlign="center"
                    selectable
                    onClick={() =>
                      categoryToExpand?._id === a?._id
                        ? setCategoryToExpand(null)
                        : setCategoryToExpand(a)
                    }
                  >
                    {categoryToExpand?._id === a._id ? (
                      <AiOutlineCaretDown className="mx-auto mt-4" size="16" />
                    ) : (
                      <AiOutlineCaretRight className="mx-auto mt-4" size="16" />
                    )}
                  </Table.Cell>
                  <Table.Cell>{truncate(a?.name,35)}</Table.Cell>

                  <Table.Cell>
                    <Button
                      basic
                      size="small"
                      className="px-3 py-2"
                      onClick={() =>
                        history.push(routes.feedbacks.update(a._id))
                      }
                    >
                      <BsPen size="14" />
                    </Button>
                    <Button
                      basic
                      size="small"
                      className="px-3 py-2"
                      onClick={() => onDublicate(a._id)}
                    >
                      <AiFillCopy size="14" />
                    </Button>
                  </Table.Cell>
                </Table.Row>

                {categoryToExpand?._id === a._id ? (
                  <Table.Row key={a?._id}>
                    <Table.Cell colSpan={8} selectable={false}>
                      {gettingFeedbackCategoryDetails ? (
                        <div class="py-4 flex items-center justify-center">
                          <Loader inline active={true} />
                        </div>
                      ) : (
                        <Table basic="very" size="small">
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell width={2}>
                                Account
                              </Table.HeaderCell>
                              <Table.HeaderCell width={2}>
                                Feedback
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {categoryDetails?.map((c, i) => (
                              <Table.Row key={i}>
                                <Table.Cell>
                                  {truncate(String(c?.account?.accountNumber),20)}-
                                  {truncate(c?.account?.accountName,35)}
                                </Table.Cell>
                                <Table.Cell>
                                  {truncate(c?.feedbackMessage?.title,35)}
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ) : null}
              </Fragment>
            ))}
          </Table.Body>
        </Table>
      </div>

      <div className="flex justify-end my-8">
        <Pagination
          activePage={page}
          onPageChange={handleOnPageChange}
          totalPages={totalPages}
        />
      </div>
      <NewCategoryNameModal
        open={addingCategoryName}
        dataToSend={dataToSend}
        onClose={() => setAddingCategoryName(false)}
        reload={() => setForceReload(p => !p)}

      />
    </Segment>

    </DefaultContainer>
  )
}

export default AllFeedbacks

const NewCategoryNameModal = ({open, onClose, reload,dataToSend}) => {
  const {
    run: submitFeedback,
    isLoading: creatingFeedback,
  } = useAsync()
  const newCategorySchema = Yup.object({
    name: Yup.string().trim().required('Required'),
  })

  const onCreate = values => {
    let objToSend = {
      name: '',
      relations: [
       
      ],
    }
    objToSend.name = values?.name
    for (let i of dataToSend) {
        objToSend.relations.push({ account: i?.account?._id, feedbackMessage: i?.feedbackMessage?._id, 
        sectionName: i?.sectionName?._id })
    }
    submitFeedback(
      authAxios.post('/question/tablefeedback/category', objToSend).then(() => {
        reload()
        onClose()
      })
    )
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      size="tiny"
      closeIcon
    >
      <Modal.Header>New category name</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{
            name: '',
          }}
          onSubmit={onCreate}
          validationSchema={newCategorySchema}
        >
          {formik => (
            <Form onSubmit={formik.submitForm} loading={creatingFeedback}>
              <FormikInput
                label="Name"
                placeholder="enter name for dublicated category"
                name="name"
              />
              <Button primary className="my-5">
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}
