import {Formik} from 'formik'
import React from 'react'
import {useHistory, useParams} from 'react-router'
import {Button, Form, Image, Message} from 'semantic-ui-react'
import * as Yup from 'yup'

import FormikInput from '../../components/formik/FormikInput'
import DefaultContainer from '../../components/shared/default-container'
import FileAttachment from '../../components/shared/FileAttachment'
import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import routes from '../../routes'
import {formatResponseError} from '../../utils/format-response'

const CreateEditSubjects = () => {
  const [attachmentData, setAttachmentData] = React.useState(null)
  const [question, setQuestion] = React.useState()

  const {
    run: getSubject,
    data: subjectData,
    isLoading: gettingSubject,
    error: errorGettingSubject,
  } = useAsync({data: []})
  const {
    run: submitSubject,
    isLoading: creatingSubject,
    error: errorCreatingSubject,
  } = useAsync()

  const history = useHistory()
  const {id} = useParams()

  const newSubjectSchema = Yup.object({
    name: Yup.string().trim().required('Required'),
  })

  const onSubmit = values => {
    console.log(values)

    const formData = new FormData()
    for (let i in values) {
      formData.append(i, values[i])
    }
    if (attachmentData) formData.append('img', attachmentData)

    if (id) {
      // update
      submitSubject(authAxios.patch(`/school/subject/${id}`, formData)).then(
        () => history.push(routes.subjects.all),
      )
    } else {
      // create
      submitSubject(authAxios.post('/school/subject', formData)).then(() =>
        history.push(routes.subjects.all),
      )
    }
  }

  React.useEffect(() => {
    if (id)
      getSubject(authAxios.get(`/school/subject/${id}`)).then(({data}) => {
        console.log(data)
      })
  }, [id, getSubject])

  return (
    <DefaultContainer>
      <h3 className="mt-4 mb-8">{id ? 'Update' : 'Create new'} Subject</h3>

      {errorGettingSubject ? (
        <Message error list={formatResponseError(errorGettingSubject)} />
      ) : null}
      {errorCreatingSubject ? (
        <Message error list={formatResponseError(errorCreatingSubject)} />
      ) : null}

      <Formik
        initialValues={{
          name: subjectData?.name || '',
        }}
        validationSchema={newSubjectSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {formik => (
          <Form
            loading={gettingSubject || creatingSubject}
            onSubmit={formik.handleSubmit}
          >
            <FormikInput label="Subject name" name="name" className="w-2/5" />

            {subjectData?.image && (
              <Image
                size="medium"
                src={subjectData?.image}
                rounded
                className="mb-4 mt-8"
              />
            )}

            {question?.image && (
              <Image
                size="medium"
                src={question?.image}
                rounded
                className="mb-4 mt-8"
              />
            )}

            <FileAttachment setAttachmentData={setAttachmentData} />

            <div class="flex items-center justify-between mt-8">
              <Button primary type="submit">
                {id ? 'Update' : 'Create'}
              </Button>
              <Button onClick={() => history.push(routes.subjects.all)}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </DefaultContainer>
  )
}

export default CreateEditSubjects
