import React from 'react'
import {BsCheck, BsPen} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import {
  Accordion,
  Dimmer,
  Icon,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react'

import {useParams} from 'react-router-dom'
import {Fragment} from 'react/cjs/react.production.min'

import {truncate} from '../../utils/string'
import useAsync from '../../hooks/use-async'
import {authAxios} from '../../config/axios-config'
import {formatResponseError} from '../../utils/format-response'

const TeacherClassAccordion = ({
  classid,
  index,
  activeIndex,
  clickAccordion,
  name,
  classData,
}) => {
  return (
    <Accordion
      fluid
      styled
      className="rounded-t-md w-full shadow-sm mb-4 hover:shadow hover:opacity-95 p-3 border-2 border-solid animate__animated animate__fadeInUp"
    >
      <Accordion.Title
        active={activeIndex === index}
        index={index}
        onClick={clickAccordion}
        className="flex items-center"
      >
        <Icon name="dropdown" />
        <div className="flex justify-between items-center">
          <p className=" font-semibold">{name}</p>
        </div>
      </Accordion.Title>
      <Accordion.Content active={activeIndex === index}>
        <Segment className="border-none shadow-none bg-transparent">
          {/* <Dimmer active={gettingclassData} inverted>
            <Loader active={true} />
          </Dimmer>

          {errorGettingClassData ? (
            <Message error list={formatResponseError(errorGettingClassData)} />
          ) : null} */}

          <div>
            <div className="flex my-6">
              <div className=" w-1/6 p-3 rounded-md">Class Name</div>
              <div className="bg-gray-200 w-2/6 p-3 rounded-md ml-4">
                {classData?.name}
              </div>
            </div>
            <div className="flex my-6">
              <div className=" w-1/6 p-3 rounded-md">Country</div>
              <div className="bg-gray-200 w-2/6 p-3 rounded-md ml-4">
                {classData?.country?.name}
              </div>
            </div>
            <div className="flex my-6">
              <div className=" w-1/6 p-3 rounded-md">School</div>
              <div className="bg-gray-200 w-2/6 p-3 rounded-md ml-4">
                {classData?.school?.name}
              </div>
            </div>
            <div className="flex my-6">
              <div className=" w-1/6 p-3 rounded-md">Subject</div>
              <div className="bg-gray-200 w-2/6 p-3 rounded-md ml-4">
                {classData?.subject?.name}
              </div>
            </div>{' '}
            <div className="flex my-6">
              <div className=" w-1/6 p-3 rounded-md">Level</div>
              <div className="bg-gray-200 w-2/6 p-3 rounded-md ml-4">
                {classData?.level?.name}
              </div>
            </div>
          </div>
        </Segment>
      </Accordion.Content>
    </Accordion>
  )
}

export default TeacherClassAccordion
