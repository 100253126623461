import React from 'react'
import {Link} from 'react-router-dom'
import {
  Breadcrumb,
  Button,
  Dimmer,
  Loader,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react'
import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import routes from '../../routes'
import {useHistory} from 'react-router-dom'
import {formatResponseError} from '../../utils/format-response'
import SubjectCard from '../../components/shared/subject-card'

const QuestMCQ = () => {
  const [subjects, setSubjects] = React.useState([])
  const {
    run: getSubjects,
    error: errorGettingSubjects,
    isLoading: gettingSubjects,
  } = useAsync()

  const history = useHistory()

  React.useEffect(() => {
    getSubjects(authAxios.get('/school/subject/all')).then(({data}) => {
      setSubjects(data.subjects)
      //   let options = []
      //   data?.subjects.forEach(s =>
      //     options.push({text: s.name, key: s._id, value: s._id}),
      //   )
      //   setSubjects(options)
    })
  }, [getSubjects])

  return (
    <div className='px-16'>
      <Segment className="border-none shadow-none bg-transparent w-full">
      {errorGettingSubjects ? (
        <Message error list={formatResponseError(errorGettingSubjects)} />
      ) : null}
      <Dimmer active={gettingSubjects} inverted>
        <Loader active={true} />
      </Dimmer>
      <div className="flex justify-between align-center">
        <h1 className="text-3xl">Subjects</h1>
        <div>
          <Link className="text-white" to={routes.subjects.new}>
            <Button size="small" primary>
              New
            </Button>
          </Link>
        </div>
      </div>
      {/* <Breadcrumb className='mt-4'>
        <Breadcrumb.Section link>Home</Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section active>Subjects</Breadcrumb.Section>
      </Breadcrumb> */}
      <div className="my-12">
        <div className="flex flex-wrap gap-x-5 gap-y-5 items-center justify-start mt-12">
          {subjects?.map((a, idx) => (
            <SubjectCard name={a?.name} subjectSrc={a?.image} id={a?._id} />
          ))}
        </div>
      </div>
      </Segment>

    </div>
  )
}

export default QuestMCQ
