import React from 'react'
import {FieldArray, Formik, Form as FormFormik} from 'formik'
import {
  Form,
  Button,
  Message,
  Dimmer,
  Loader,
  Image,
  Segment,
} from 'semantic-ui-react'
import * as Yup from 'yup'
import useAsync from '../../hooks/use-async'
import {authAxios} from '../../config/axios-config'
import {formatResponseError} from '../../utils/format-response'
import FormikInput from '../formik/FormikInput'
import FormikDropdown from '../formik/FormikDropdown'
import {useHistory, useParams} from 'react-router'
import routes from '../../routes'
import useDifficulty from '../../hooks/use-difculty'
import FileAttachment from '../shared/FileAttachment'
import {convertArrayOfObjToFormData} from '../../utils/form-data'
import DefaultContainer from '../shared/default-container'

const UpdateBlankQuestion = () => {
  const history = useHistory()
  const [subjects, setSubjects] = React.useState([])
  const [question, setQuestion] = React.useState()
  const [competency, setCompetency] = React.useState([])

  const [attachmentData, setAttachmentData] = React.useState(null)

  const {id} = useParams()
  const {difficulties, gettingDifficulties, errorGettingDifficulty} =
    useDifficulty()
  const {
    run: getSubjects,
    error: errorGettingSubjects,
    isLoading: gettingSubjects,
  } = useAsync()

  const {
    run: updateQuestionReq,
    error: errorupdatingQuestion,
    isLoading: updatingQuestion,
  } = useAsync()

  const {
    run: getQuestion,
    error: errorGettingQuestion,
    isLoading: gettingQuestion,
  } = useAsync()

  const {
    run: getCompetency,
    error: errorGettingCompetency,
    isLoading: gettingCompetency,
  } = useAsync()

  const newBlankQuestionSchema = Yup.object({
    questionTitle: Yup.string().trim().required('Required'),
    question: Yup.string().trim().required('Required'),
    answer:  Yup.string()
    .matches(/(^\d{1,3}(\.?\d{3})*(,\d{2})?$)|(^\d{1,3}(,?\d{3})*(\.\d{2})?$)/, 'Enter a valid value')
    .required('Required'),
    hint: Yup.string().trim().required('Required'),
    subject: Yup.string().trim().required('Required'),
    difficulty: Yup.string().trim().required('Required'),
    competency: Yup.string().trim().required('Required'),

    feedback: Yup.array()
      .of(
        Yup.object({
          message: Yup.string().trim().required('Required'),
          answer:  Yup.string()
          .matches(/(^\d{1,3}(\.?\d{3})*(,\d{2})?$)|(^\d{1,3}(,?\d{3})*(\.\d{2})?$)/, 'Enter a valid value')
          .required('Required'),
        }),
      )
      .required(),
  })

  const updateQuestion = (values, {resetForm}) => {
    const formData = new FormData()

    for (let i in values) {
      if (i === 'feedback') {
        convertArrayOfObjToFormData(formData, values[i], 'feedback')
      } else {
        formData.append(i, values[i])
      }
    }

    if (attachmentData) formData.append('img', attachmentData)
    updateQuestionReq(authAxios.patch(`/question/blank/${id}`, formData)).then(
      ({data}) => {
        resetForm({})
        setAttachmentData(null)
        history.push(routes.blankQuestion.all)
      },
    )
  }

  React.useEffect(() => {
    getSubjects(authAxios.get('/school/subject/all')).then(({data}) => {
      let options = []
      data?.subjects.forEach(s =>
        options.push({text: s.name, key: s._id, value: s._id}),
      )
      setSubjects(options)
    })
  }, [getSubjects])

  React.useEffect(() => {
    getQuestion(authAxios.get(`/question/blank/?qid=${id}`)).then(({data}) => {
      getCompetency(
        authAxios.get(
          `/school/subject/competency/all/?id=${data.subject?._id}`,
        ),
      ).then(({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.name, key: s._id, value: s._id}),
        )
        setCompetency(options)
      })

      setAttachmentData(question?.image)
      setQuestion(data)
    })
  }, [getCompetency, getQuestion, id, question?.image])

  const getCompetencyOptions = React.useCallback(
    subjectId => {
      getCompetency(
        authAxios.get(`/school/subject/competency/all/?id=${subjectId}`),
      ).then(({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.name, key: s._id, value: s._id}),
        )
        setCompetency(options)
      })
    },
    [getCompetency],
  )

  return (
    <DefaultContainer>
      <Segment className="border-none shadow-none bg-transparent w-full">
        <h2 className="mb-8">Update Blank question</h2>

        {errorGettingSubjects ? (
          <Message error list={formatResponseError(errorGettingSubjects)} />
        ) : null}
        {errorupdatingQuestion ? (
          <Message error list={formatResponseError(errorupdatingQuestion)} />
        ) : null}
        {errorGettingDifficulty ? (
          <Message error list={formatResponseError(errorGettingDifficulty)} />
        ) : null}
        {errorGettingQuestion ? (
          <Message error list={formatResponseError(errorGettingQuestion)} />
        ) : null}
        {errorGettingCompetency ? (
          <Message error list={formatResponseError(errorGettingCompetency)} />
        ) : null}
        <Dimmer active={updatingQuestion || gettingQuestion} inverted>
          <Loader active={true} />
        </Dimmer>
        <div className="max-w-lg">
          <Formik
            initialValues={{
              subject: question?.subject?._id || '',
              difficulty: question?.difficulty || '',
              questionTitle: question?.questionTitle || '',
              answer: question?.answer || '',
              question: question?.question || '',
              competency: question?.competency || '',
              hint: question?.hint || '',
              feedback: question?.feedback || [
                {
                  message: '',
                  answer: '',
                  type: 'other',
                },
                {
                  message: '',
                  answer: '',
                  type: 'private',
                },
                {
                  message: '',
                  answer: '',
                  type: 'private',
                },
              ],
            }}
            validationSchema={newBlankQuestionSchema}
            onSubmit={updateQuestion}
            enableReinitialize
          >
            {formik => (
              <Form onSubmit={formik.handleSubmit}>
                <FormikInput name="questionTitle" label="Question title" />

                <Form.Field required>
                  <FormikInput name="question" label="Question" />
                </Form.Field>

                <Form.Field required>
                  <FormikInput name="answer" label="Answer" isNumber/>
                </Form.Field>

                <FormikDropdown
                  label="Subject"
                  name="subject"
                  loading={gettingSubjects}
                  options={subjects}
                  onChangeCB={value => {
                    getCompetencyOptions(value)
                    formik.setFieldValue('competency', '')
                  }}
                />

                <FormikDropdown
                  label="Competency"
                  name="competency"
                  placeholder="please select a subject first"
                  loading={gettingCompetency}
                  options={competency}
                  disabled={gettingCompetency}
                />

                <FormikDropdown
                  label="Difficulty"
                  options={difficulties}
                  name="difficulty"
                  loading={gettingDifficulties}
                />

                {/* /   Section for Feed-back    / */}
                <h3>Enter Feedback for the question</h3>
                {formik.values.feedback[0] && (
                  <p className="font-semibold my-2">
                    Other (first field for other wrong answers)
                  </p>
                )}
                <FormFormik>
                  <FieldArray name="feedback">
                    {({insert, remove, push}) => (
                      <div>
                        {formik.values.feedback.length > 0 &&
                          formik.values.feedback.map((choice, index) => {
                            const {touched, errors} = formik
                            const isTouchedMessage =
                              touched.feedback &&
                              touched.feedback[index]?.message
                            const isTouchedAnswer =
                              touched.feedback &&
                              touched.feedback[index]?.answer
                            const isErrorMessage =
                              errors.feedback && errors.feedback[index]?.message
                            const isErrorAnswer =
                              errors.feedback && errors.feedback[index]?.answer
                            return (
                              <>
                                <div className="flex flex-col" key={index}>
                                  <FormikInput
                                    name={`feedback.${index}.answer`}
                                    placeholder="Enter the message"
                                    error={isTouchedAnswer && isErrorAnswer}
                                    isNumber
                                  />
                                  <FormikInput
                                    name={`feedback.${index}.message`}
                                    placeholder="Enter the feedback"
                                    className="mt-0"
                                    error={isTouchedMessage && isErrorMessage}
                                  />
                                </div>
                                <div className="mt-0  mb-8 flex w-full  justify-end place-items-end">
                                  {index === 0 ? null : (
                                    <Button
                                      className="justify-end mt-1 ml-auto"
                                      size="tiny"
                                      negative
                                      basic
                                      onClick={() => remove(index)}
                                      type="button"
                                    >
                                      -
                                    </Button>
                                  )}
                                </div>
                              </>
                            )
                          })}

                        <div className="mt-0 mb-8 flex w-full justify-end place-items-end">
                          <Button
                            className="justify-end mt-1 ml-auto"
                            size="tiny"
                            basic
                            onClick={() =>
                              push({message: '', answer: '', type: 'private'})
                            }
                            type="button"
                          >
                            Add feedback
                          </Button>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </FormFormik>
                {/* /   End of Section Feed-back    / */}

                <Form.Field required>
                  <FormikInput name="hint" label="Hint" />
                </Form.Field>

                {question?.image && (
                  <Image
                    size="medium"
                    src={question?.image}
                    rounded
                    className="mb-4 mt-8"
                  />
                )}
                <FileAttachment setAttachmentData={setAttachmentData} />

                <hr />
                <div className="flex justify-between">
                  <Button
                    className="mt-4 mb-8 mr-2"
                    size="tiny"
                    type="submit"
                    primary
                  >
                    Save
                  </Button>
                  <Button
                    className="mt-4 mb-8"
                    size="tiny"
                    onClick={() => history.push(routes.blankQuestion.all)}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Segment>
    </DefaultContainer>
  )
}

export default UpdateBlankQuestion
