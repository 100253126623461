import {FieldArray, Formik} from 'formik'
import React from 'react'
import {useHistory, useLocation, useParams} from 'react-router'
import {Button, Form, Image, Message, Segment, Table} from 'semantic-ui-react'
import * as Yup from 'yup'

import {authAxios} from '../../config/axios-config'
import useAccountNames from '../../hooks/use-account-names'
import useAsync from '../../hooks/use-async'
import useDifficulty from '../../hooks/use-difculty'
import useSubject from '../../hooks/use-subjects'
import routes from '../../routes'
import {convertArrayOfObjToFormData} from '../../utils/form-data'
import {formatResponseError} from '../../utils/format-response'
import FormikDate from '../formik/FormikDate'
import FormikDropdown from '../formik/FormikDropdown'
import FormikInput from '../formik/FormikInput'
import FileAttachment from '../shared/FileAttachment'

const UpdateAmortizationQuestion = () => {
  const {subjects, errorGettingSubjects, gettingSubjects} = useSubject()
  const {accountNames, errorGettingAccountNames, gettingAccountNames} =
    useAccountNames()
  const {difficulties, gettingDifficulties, errorGettingDifficulty} =
    useDifficulty()

  const [competency, setCompetency] = React.useState([])

  const [question, setQuestion] = React.useState()

  const [attachmentData, setAttachmentData] = React.useState(null)

  const history = useHistory()
  const [assets, setAssets] = React.useState([])
  const [answersCount, setAnswersCount] = React.useState([])

  const location = useLocation()
  const {id} = useParams()
  const [state] = React.useState(location?.state)

  const {
    run: getQuestion,
    error: errorGettingQuestion,
    isLoading: gettingQuestion,
  } = useAsync()

  const {
    run: getCompetency,
    error: errorGettingCompetency,
    isLoading: gettingCompetency,
  } = useAsync()

  const {
    run: updateQuestion,
    error: errorUpdatingQuestion,
    isLoading: updatingQuestion,
  } = useAsync({data: []})

  const amortizationQuestionSchema = Yup.object({
    questionTitle: Yup.string().trim().required('Required'),
    subject: Yup.string().trim().required('Required'),
    difficulty: Yup.string().trim().required('Required'),
    fixedAsset: Yup.string().trim().required('Required'),
    hint: Yup.string().trim().required('Required'),
    question: Yup.string().trim().required('Required'),
    competency: Yup.string().trim().required(),
    date: Yup.string()
    .matches(/^(0?[1-9]|[12][0-9]|3[01])\-(0?[1-9]|1[012])\-\d{4}$/, 'Only valid dates are allowed').required('Required'),
    depreciationRate: Yup.number()
      .typeError('Only numbers are allowed')
      .required('Required'),
    originalValue: Yup.string()
    .matches(/(^\d{1,3}(\.?\d{3})*(,\d{2})?$)|(^\d{1,3}(,?\d{3})*(\.\d{2})?$)/, 'Enter a valid value')
    .required('Required'),
    originalValueFeedback: Yup.string().trim().required('Required'),
    answers: Yup.array()
      .of(
        Yup.object({
          year: Yup.number()
            .positive('Must be positive')
            .typeError('Only numbers are allowed')
            .required('Required'),
          netValueBegin: Yup.string()
            .matches(/(^\d{1,3}(\.?\d{3})*(,\d{2})?$)|(^\d{1,3}(,?\d{3})*(\.\d{2})?$)/, 'Enter a valid value')
            .required('Required'),
          netValueEnd: Yup.string()
            .matches(/(^\d{1,3}(\.?\d{3})*(,\d{2})?$)|(^\d{1,3}(,?\d{3})*(\.\d{2})?$)/, 'Enter a valid value')
            .required('Required'),
          lossValue: Yup.string()
            .matches(/(^\d{1,3}(\.?\d{3})*(,\d{2})?$)|(^\d{1,3}(,?\d{3})*(\.\d{2})?$)/, 'Enter a valid value')
            .required('Required'),
          accumulatedLoss: Yup.string()
            .matches(/(^\d{1,3}(\.?\d{3})*(,\d{2})?$)|(^\d{1,3}(,?\d{3})*(\.\d{2})?$)/, 'Enter a valid value')
            .required('Required'),
          lossValueFeedback: Yup.string().trim().required('Required'),
        }),
      )
      .required(),
  })

  const {
    run: getAssets,
    error: errorGettingAssets,
    isLoading: gettingAssets,
  } = useAsync()
  // waite for end point from shiefo
  React.useEffect(() => {
    getAssets(authAxios.get('/question/amortization/fixedasset')).then(
      ({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.name, key: s._id, value: s._id}),
        )
        setAssets(options)
      },
    )
  }, [getAssets])

  function onUpdate(values, {resetForm}) {
    const formData = new FormData()

    for (let i in values) {
      if (i === 'answers') {
        convertArrayOfObjToFormData(formData, values[i], 'answers')
      } else {
        formData.append(i, values[i])
      }
    }

    if (attachmentData) formData.append('img', attachmentData)

    updateQuestion(
      authAxios.patch(`/question/amortization/${id}`, formData),
    ).then(({data}) => {
      history.push(routes.amortizationQuestion.all)
    })
  }
  const getCompetencyOptions = React.useCallback(
    subjectId => {
      getCompetency(
        authAxios.get(`/school/subject/competency/all/?id=${subjectId}`),
      ).then(({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.name, key: s._id, value: s._id}),
        )
        setCompetency(options)
      })
    },
    [getCompetency],
  )

  React.useEffect(() => {
    if (state?.data?.subject) {
      getCompetency(
        authAxios.get(
          `/school/subject/competency/all/?id=${state?.data?.subject}`,
        ),
      ).then(({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.name, key: s._id, value: s._id}),
        )
        setCompetency(options)
      })
    }
  }, [getCompetency, state?.data?.subject])

  React.useEffect(() => {
    // ignore getting request when redirected from the create view
    if (state?.new) return

    getQuestion(authAxios.get(`/question/amortization/?qid=${id}`)).then(
      ({data}) => {
        getCompetency(
          authAxios.get(
            `/school/subject/competency/all/?id=${data.subject._id}`,
          ),
        ).then(({data}) => {
          let options = []
          data?.forEach(s =>
            options.push({text: s.name, key: s._id, value: s._id}),
          )
          setCompetency(options)
        })
        setQuestion(data)
        setAttachmentData(question?.image)
      },
    )
  }, [getCompetency, getQuestion, id, question?.image, state?.new])

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent w-full overflow-x-auto">
        <h2 className="mb-8">
          {state?.new
            ? 'Create new Amortization question'
            : 'Update Amortization'}
        </h2>

        {errorGettingSubjects ? (
          <Message error list={formatResponseError(errorGettingSubjects)} />
        ) : null}

        {errorGettingQuestion ? (
          <Message error list={formatResponseError(errorGettingQuestion)} />
        ) : null}

        {errorGettingAccountNames ? (
          <Message error list={formatResponseError(errorGettingAccountNames)} />
        ) : null}
        {errorUpdatingQuestion ? (
          <Message error list={formatResponseError(errorUpdatingQuestion)} />
        ) : null}
        {errorGettingAssets ? (
          <Message error list={formatResponseError(errorGettingAssets)} />
        ) : null}
        {errorGettingDifficulty ? (
          <Message error list={formatResponseError(errorGettingDifficulty)} />
        ) : null}

        {errorGettingCompetency ? (
          <Message error list={formatResponseError(errorGettingCompetency)} />
        ) : null}
        <Formik
          initialValues={{
            subject: question?.subject._id || state?.data?.subject || '',
            competency: question?.competency || state?.data?.competency || '',

            difficulty: question?.difficulty || state?.data?.difficulty || '',
            questionTitle:
              question?.questionTitle || state?.data?.questionTitle || '',
            hint: question?.hint || state?.data?.hint || '',
            question: question?.question || '',
            originalValue: question?.originalValue || '',
            depreciationRate: question?.depreciationRate || '',
            date: question?.date || '',
            fixedAsset: question?.fixedAsset || '',
            originalValueFeedback: question?.originalValueFeedback || '',
            answers: question?.answers || [
              {
                year: '',
                netValueBegin: '',
                netValueEnd: '',
                lossValue: '',
                accumulatedLoss: '',
                lossValueFeedback: '',
              },
            ],
          }}
          enableReinitialize
          onSubmit={onUpdate}
          validationSchema={amortizationQuestionSchema}
        >
          {formik => (
            <Form
              onSubmit={formik.handleSubmit}
              loading={
                gettingAccountNames || updatingQuestion || gettingQuestion
              }
            >
              <div className="max-w-xl">
                <FormikDropdown
                  label="Subject"
                  name="subject"
                  loading={gettingSubjects}
                  options={subjects}
                  onChangeCB={value => {
                    getCompetencyOptions(value)
                    formik.setFieldValue('competency', '')
                    setCompetency()
                  }}
                />

                <FormikDropdown
                  label="Competency"
                  name="competency"
                  loading={gettingCompetency}
                  options={competency}
                  disabled={gettingCompetency}
                />
                <FormikDropdown
                  label="Difficulty"
                  options={difficulties}
                  name="difficulty"
                  loading={gettingDifficulties}
                />
                <FormikInput
                  label="Question title"
                  name="questionTitle"
                  placeholder="Question title"
                />
                <FormikInput
                  label="Hint"
                  name="hint"
                  placeholder="Question hint"
                />
                <FormikInput
                  label="Question"
                  name="question"
                  placeholder="Question"
                />
              </div>

              <div className="my-10 ">
                <h3 className="mb-5">Amortization Table</h3>

                <Table celled structured className=''>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan="2">
                        Fixed assets
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        {/* dropdown for assets */}
                        <FormikDropdown
                          loading={gettingAssets}
                          name="fixedAsset"
                          placeholder="Assets"
                          options={assets}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        Acquistion date
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        {/* date input */}
                        <FormikDate placeholder="Enter date: (dd-mm-yyyy)" name="date" />
                      </Table.HeaderCell>
                      <Table.HeaderCell rowSpan="3">Actions</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell colSpan="2">
                        Original value
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        {/* original value input */}
                        <FormikInput
                          name="originalValue"
                          placeholder="Original Value"
                          isNumber
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        Depreciation rate
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="1">
                        {/* depreciation rate input */}
                        <FormikInput
                          name="depreciationRate"
                          placeholder="Depreciation rate"
                          icon="percent"
                        />
                      </Table.HeaderCell>
                    </Table.Row>

                    <Table.Row>
                      <Table.HeaderCell rowSpan="1">Year</Table.HeaderCell>
                      <Table.HeaderCell rowSpan="1">
                        Net value at beginning of the year
                      </Table.HeaderCell>
                      <Table.HeaderCell rowSpan="1">
                        Loss in value per year
                      </Table.HeaderCell>
                      <Table.HeaderCell rowSpan="1">
                        Accumulated loss
                      </Table.HeaderCell>
                      <Table.HeaderCell rowSpan="1">
                        Net value at the end of the year.
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <FieldArray name="answers">
                      {({insert, remove, push}) => (
                        <>
                          {formik.values.answers.length > 0 &&
                            formik.values.answers.map((choice, index, arr) => {
                              const {touched, errors} = formik

                              const isTouchedYear =
                                touched.answers && touched.answers[index]?.year
                              const isTouchedNetValueBegin =
                                touched.answers &&
                                touched.answers[index]?.netValueBegin
                              const isTouchednetValueEnd =
                                touched.answers &&
                                touched.answers[index]?.netValueEnd
                              const isTouchedlossValue =
                                touched.answers &&
                                touched.answers[index]?.lossValue
                              const isTouchedaccumulatedLossr =
                                touched.answers &&
                                touched.answers[index]?.accumulatedLoss

                              const isErroryear =
                                errors.answers && errors.answers[index]?.year
                              const isErronetValueBegin =
                                errors.answers &&
                                errors.answers[index]?.netValueBegin
                              const isErrornetValueEnd =
                                errors.answers &&
                                errors.answers[index]?.netValueEnd
                              const isErrorlossValue =
                                errors.answers &&
                                errors.answers[index]?.lossValue
                              const isErroraccumulatedLoss =
                                errors.answers &&
                                errors.answers[index]?.accumulatedLoss

                              setAnswersCount(arr.legnth)
                              return (
                                <Table.Row key={index}>
                                  <>
                                    <Table.Cell>
                                      <FormikInput
                                        name={`answers.${index}.year`}
                                        error={isTouchedYear && isErroryear}
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <FormikInput
                                        name={`answers.${index}.netValueBegin`}
                                        error={
                                          isTouchedNetValueBegin &&
                                          isErronetValueBegin
                                        }
                                        isNumber
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <FormikInput
                                        name={`answers.${index}.lossValue`}
                                        error={
                                          isTouchedlossValue && isErrorlossValue
                                        }
                                        isNumber
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <FormikInput
                                        name={`answers.${index}.accumulatedLoss`}
                                        error={
                                          isTouchedaccumulatedLossr &&
                                          isErroraccumulatedLoss
                                        }
                                        isNumber
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <FormikInput
                                        name={`answers.${index}.netValueEnd`}
                                        error={
                                          isTouchednetValueEnd &&
                                          isErrornetValueEnd
                                        }
                                        isNumber
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <div className="flex">
                                        {index === 0 ? null : (
                                          <Button
                                            size="tiny"
                                            negative
                                            basic
                                            onClick={() => remove(index)}
                                            type="button"
                                          >
                                            -
                                          </Button>
                                        )}

                                        {index ===
                                          formik.values.answers.length - 1 &&
                                        formik.values.answers.length < 6 ? (
                                          <Button
                                            size="tiny"
                                            className="bg-white border-2 border-solid  items-center  border-blue-400"
                                            onClick={() =>
                                              push({
                                                year: '',
                                                netValueBegin: '',
                                                netValueEnd: '',
                                                lossValue: '',
                                                accumulatedLoss: '',
                                                lossValueFeedback: '',
                                              })
                                            }
                                            type="button"
                                          >
                                            +
                                          </Button>
                                        ) : null}
                                      </div>
                                    </Table.Cell>
                                  </>
                                </Table.Row>
                              )
                            })}
                        </>
                      )}
                    </FieldArray>
                  </Table.Body>
                </Table>
              </div>

              <FormikInput
                className="my-8"
                label="Feadback for original Value"
                name="originalValueFeedback"
                placeholder="Enter feadback for original Value"
              />

              {formik.values.answers.map((a, index) => {
                const {touched, errors} = formik

                const isTouchedlossValueFeedback =
                  touched.answers && touched.answers[index]?.lossValueFeedback
                const isErrorlossValueFeedback =
                  errors.answers && errors.answers[index]?.lossValueFeedback

                return (
                  <FormikInput
                    label={`Loss in value per year feadback for row ${
                      index + 1
                    }`}
                    key={index}
                    name={`answers.${index}.lossValueFeedback`}
                    placeholder="Enter feadback for loss Value"
                    error={
                      isTouchedlossValueFeedback && isErrorlossValueFeedback
                    }
                  />
                )
              })}

              {question?.image && (
                <Image
                  size="medium"
                  src={question?.image}
                  rounded
                  className="mb-4 mt-8"
                />
              )}
              <FileAttachment setAttachmentData={setAttachmentData} />

              <div className="my-5 mt-10 flex justify-between">
                <Button size="tiny" primary type="submit">
                  {state?.new ? 'Save' : 'Update'}
                </Button>
                <Button
                  className="mr-2"
                  size="tiny"
                  onClick={() => history.push(routes.amortizationQuestion.all)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Segment>
    </div>
  )
}

export default UpdateAmortizationQuestion
