import React from "react";
import { Controller } from "react-hook-form";
import { DateInput } from "semantic-ui-calendar-react";

const FormDateController = ({
  control,
  name,
  label,
  controlProps,
  inputProps,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      {...controlProps}
      render={({ field }) => {
        const controllerOnChnage = (e, data) => {
          // debugger
          field.onChange({
            ...e,
            target: { ...e.target, value: data.value },
          });
        };

        return (
          <DateInput

            autoComplete="off"
            id={name}
            closable
            {...field}
            label={label}
            onChange={controllerOnChnage}
            onBlur={field.onBlur()}
            {...inputProps}
            iconPosition="left"
            className="mt-2"
          error={control?._formState?.errors?.[name] && control?._formState?.errors?.[name]?.message}

          />
        );
      }}
    />
  );
};

export default FormDateController;
