import React from 'react'
import {Formik} from 'formik'
import {Form, Button, Message, Dimmer, Loader, Segment} from 'semantic-ui-react'
import * as Yup from 'yup'
import {useHistory, useParams} from 'react-router'

import useAsync from '../../../hooks/use-async'
import {authAxios} from '../../../config/axios-config'
import {formatResponseError} from '../../../utils/format-response'
import routes from '../../../routes'
import FormikInput from '../../../components/formik/FormikInput'
import FormikDropdown from '../../../components/formik/FormikDropdown'
import DefaultContainer from '../../../components/shared/default-container'

const CreateEditSection = () => {
  const history = useHistory()
  const [question, setQuestion] = React.useState()
  const [competency, setCompetency] = React.useState([])

  const [unpairPexs, setUnpairPexs] = React.useState([])

  const {chapterid, subjectid, sectionid} = useParams()
  
  const {
    run: submitchapter,
    error: errorSubmitingChapter,
    isLoading: submitingChapter,
  } = useAsync()

  
  const {
    run: getchapter,
    error: errorGettingchapter,
    isLoading: gettingchapter,
  } = useAsync()
  
  const {
    run: getCompetency,
    error: errorGettingCompetency,
    isLoading: gettingCompetency,
  } = useAsync()
  
 
  const {
    run: getUnpairPexs,
    error: errorGettingUnpairPexs,
    isLoading: gettingUnpairPexs,
  } = useAsync()
  
  const newSectionSchema = Yup.object({
    name: Yup.string().trim().required('Required'),
    videoTitle: Yup.string().trim().required('Required'),
    videoLink: Yup.string().trim().required('Required'),
    description: Yup.string().trim().required('Required'),
    competency: Yup.array().required(),
    pexs: Yup.array().min(1,'At leaste one element').required(),
  })

  const submitSection = (values, {resetForm}) => {
    let objTosend = {...values, chapter: chapterid}
    console.log({objTosend:objTosend})
    if (!sectionid) {
      submitchapter(
        authAxios.post(
          `/chapter/section?sid=${subjectid}&cid=${chapterid}`,
          values,
        ),
      ).then(({data}) => {
        resetForm({})
        console.log({resposneDataOnSubmit:data})
        history.push(routes.subjects.chapters.content(subjectid, chapterid))
      })
    } else {
      submitchapter(
        authAxios.patch(`/chapter/section/${sectionid}`, values),
      ).then(({data}) => {
        console.log({resposneDataOnSubmit:data})
        resetForm({})
        history.push(routes.subjects.chapters.content(subjectid, chapterid))
      })
    }
  }

  React.useEffect(() => {
    if (sectionid)
      getchapter(authAxios.get(`/chapter/section/${sectionid}`)).then(
        ({data}) => {
          console.log({getData:data})
          setQuestion(data)
        },
      )
  }, [sectionid, getchapter, chapterid, getCompetency, subjectid])

  React.useEffect(() => {
    getCompetency(
      authAxios.get(`/school/subject/competency/all/?id=${subjectid}`),
    ).then(({data}) => {
      let options = []
      data?.forEach(s => options.push({text: s.name, key: s._id, value: s._id}))
      setCompetency(options)
    })
  }, [getCompetency, subjectid])


  React.useEffect(() => {
    getUnpairPexs(
      authAxios.get(`/chapter/exercise/all?type=unpair&exercise=pex&sid=${subjectid}`),
    ).then(({data}) => {
      console.log({unpairedPEXS:data})
      let options = []
      data?.forEach(s => options.push({text: s.name, key: s._id, value: s._id}))
      setUnpairPexs(options)
    })
  }, [getUnpairPexs, subjectid])

  const getAllpexs = () => {
    let options = [...unpairPexs]
    let optionsIds = options.map(o => o.value)

    if (question?.pexs) {
      let questionPexs = []
      question?.pexs?.forEach(s =>
        !optionsIds.includes(s._id) && questionPexs.push({text: s.name, key: s._id, value: s._id}),
      )
      options = options.concat([...questionPexs])
    } else {
      options = [...unpairPexs]
    }

    return options
  }

  return (
    <DefaultContainer>
      <Segment className="border-none shadow-none bg-transparent w-full">
      <h3 className="mt-4 mb-8">
        {sectionid ? 'Update' : 'Create new'} Section
      </h3>

      {errorGettingchapter ? (
        <Message error list={formatResponseError(errorGettingchapter)} />
      ) : null}
      {errorGettingUnpairPexs ? (
        <Message error list={formatResponseError(errorGettingUnpairPexs)} />
      ) : null}
      {errorSubmitingChapter ? (
        <Message error list={formatResponseError(errorSubmitingChapter)} />
      ) : null}
      {errorGettingCompetency ? (
        <Message error list={formatResponseError(errorGettingCompetency)} />
      ) : null}
      <Dimmer active={submitingChapter || gettingchapter} inverted>
        <Loader active={true} />
      </Dimmer>
      <div className="max-w-lg">
        <Formik
          initialValues={{
            name: question?.name || '',
            videoTitle: question?.videoTitle || '',
            videoLink: question?.videoLink || '',
            description: question?.description || '',
            competency: question?.competency.map(s => s._id) || [],
            pexs: question?.pexs.map(s => s._id) || [],
          }}
          validationSchema={newSectionSchema}
          onSubmit={submitSection}
          enableReinitialize
        >
          {formik => {
            return (
              <Form onSubmit={formik.handleSubmit}>
                <FormikInput
                  name="name"
                  label="Section Name"
                  placeholder="Add the name of the section"
                />
                <FormikDropdown
                  label="Competency"
                  name="competency"
                  loading={gettingCompetency}
                  options={competency}
                  placeholder="Add the competency of the section"
                  multiple
                />

                <FormikInput
                  name="description"
                  label="description"
                  placeholder="Add the description of the section"
                />
                <FormikInput
                  name="videoTitle"
                  label="Video Title"
                  placeholder="Add the title of the video"
                />
                <FormikInput
                  name="videoLink"
                  label="Video Link"
                  placeholder="Add the link of the video"
                />

                <FormikDropdown
                  label="Pexs related to this section"
                  placeholder="choose pexs related to this section"
                  name="pexs"
                  loading={gettingUnpairPexs}
                  // options={pexs}
                  options={getAllpexs()}
                  multiple
                />

                <hr />
                <div className="flex justify-between">
                  <Button
                    className="mt-4 mb-8 mr-2"
                    size="tiny"
                    type="submit"
                    primary
                  >
                    Save
                  </Button>
                  <Button
                    className="mt-4 mb-8"
                    size="tiny"
                    onClick={() =>
                      history.push(
                        routes.subjects.chapters.content(subjectid, chapterid),
                      )
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
      </Segment>
    </DefaultContainer>
  )
}

export default CreateEditSection
