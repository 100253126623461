import React from 'react'

const DefaultContainer = ({children, className = ''}) => {
  return (
    <div
      className={`md:max-w-4xl sm:max-w-lg max-w-sm mx-auto px-2 md:px-4 ${className}`}
    >
      {children}
    </div>
  )
}

export default DefaultContainer
