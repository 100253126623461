import React from 'react'
import {Formik} from 'formik'
import {Form, Button, Message, Dimmer, Loader, Image, Segment} from 'semantic-ui-react'
import * as Yup from 'yup'
import {useHistory, useParams} from 'react-router'

import useAsync from '../../hooks/use-async'
import {authAxios} from '../../config/axios-config'
import {formatResponseError} from '../../utils/format-response'
import routes from '../../routes'
import FormikInput from '../../components/formik/FormikInput'
import FileAttachment from '../../components/shared/FileAttachment'

const CreateEditChapter = () => {
  const history = useHistory()
  const [question, setQuestion] = React.useState()
  const [formErrors, setFormErrors] = React.useState(null)

  const [attachmentData, setAttachmentData] = React.useState(null)

  const {chapterid, subjectid} = useParams()

  const {
    run: submitchapter,
    error: errorSubmitingChapter,
    isLoading: submitingChapter,
  } = useAsync()

  const {
    run: getchapter,
    error: errorGettingchapter,
    isLoading: gettingchapter,
  } = useAsync()

  const newBlankQuestionSchema = Yup.object({
    chapterName: Yup.string().trim().required('Required'),
    brief: Yup.string().trim().max(200, 'over').required('Required'),
  })

  const submitChapter = (values, {resetForm}) => {
    let errors = []

    if (!attachmentData) {
      errors.push('you have to add image')
    } else {
      setFormErrors(null)
    }

    if (errors.length > 0) {
      setFormErrors(errors)
      return
    }

    const formData = new FormData()

    for (let i in values) {
      formData.append(i, values[i])
    }
    formData.append('subject', subjectid)
    if (attachmentData) formData.append('img', attachmentData)

    if (chapterid) {
      submitchapter(authAxios.patch(`/chapter/${chapterid}`, formData)).then(({data}) => {
        resetForm({})
        setAttachmentData(null)
        history.push(routes.subjects.chapters.all(subjectid))
      })
    } else {
      submitchapter(authAxios.post(`/chapter`, formData)).then(({data}) => {
        resetForm({})
        setAttachmentData(null)
        history.push(routes.subjects.chapters.all(subjectid))
      })
    }
  }

  React.useEffect(() => {
    if (chapterid)
      getchapter(authAxios.get(`/chapter/${chapterid}`)).then(({data}) => {
        setQuestion(data)
        setAttachmentData(question?.image)
      })
  }, [chapterid, getchapter, question?.image])

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent w-full">
      <h3 className="mt-4 mb-8">
        {chapterid ? 'Update' : 'Create new'} Chapter
      </h3>

      {errorGettingchapter ? (
        <Message error list={formatResponseError(errorGettingchapter)} />
      ) : null}
      {errorSubmitingChapter ? (
        <Message error list={formatResponseError(errorSubmitingChapter)} />
      ) : null}
      {formErrors?.length > 0 ? <Message error list={formErrors} /> : null}

      <Dimmer active={submitingChapter || gettingchapter} inverted>
        <Loader active={true} />
      </Dimmer>
      <div className="max-w-lg">
        <Formik
          initialValues={{
            chapterName: question?.chapterName || '',
            brief: question?.brief || '',
          }}
          validationSchema={newBlankQuestionSchema}
          onSubmit={submitChapter}
          enableReinitialize
        >
          {formik => {
            return (
              <Form onSubmit={formik.handleSubmit}>
                <FormikInput name="chapterName" label="Chapter Name" />

                <FormikInput name="brief" label="Brief about chapter" />

                {question?.image && (
                  <Image
                    size="medium"
                    src={question?.image}
                    rounded
                    className="mb-4 mt-8"
                  />
                )}
                <FileAttachment setAttachmentData={setAttachmentData} />

                <hr />
                <div className="flex justify-between">
                  <Button
                    className="mt-4 mb-8 mr-2"
                    size="tiny"
                    type="submit"
                    primary
                  >
                    Save
                  </Button>
                  <Button
                    className="mt-4 mb-8"
                    size="tiny"
                    onClick={() =>
                      history.push(routes.subjects.chapters.all(subjectid))
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
      </Segment>
    </div>
  )
}

export default CreateEditChapter
