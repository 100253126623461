import React, {useState} from 'react'
import {
  Button,
  Dimmer,
  Dropdown,
  Input,
  Loader,
  Message,
  Pagination,
  Segment,
} from 'semantic-ui-react'
import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import routes from '../../routes'
import {useLocation, useHistory} from 'react-router-dom'
import queryString from 'query-string'
import {formatResponseError} from '../../utils/format-response'
import useSubject from '../../hooks/use-subjects'
import PdfCard from '../../components/shared/pdf-card'
import {truncate} from '../../utils/string'

const AllPdf = () => {
  const [pdfsData, setPdfsData] = React.useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const {subjects, errorGettingSubjects, gettingSubjects} = useSubject()
  const [truncateSubject, setTruncateSubject] = React.useState([])

  const [forceReload, setForceReload] = React.useState(false)

  const [currentSubject, setCurrentSubject] = useState()

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [limit, setLimit] = useState(10)

  const location = useLocation()
  const history = useHistory()


  const [state] = React.useState(location?.state)

  const {
    run: getPdfs,
    error: errorGettingPdfs,
    isLoading: gettingPdfs,
  } = useAsync()

  React.useEffect(() => {
    const {
      page = 1,
      limit = 10,
      search = '',
      currentSubjectid
    } = queryString.parse(location.search)

    setPage(page)
    setLimit(limit)
    setSearchKeyword(search)
    setCurrentSubject(currentSubjectid)

    if (currentSubject) {
      getPdfs(
        authAxios.get(
          `/school/subject/pdf/all?sid=${currentSubject}&page=${page}&limit=${limit}&search=${search}`,
        ),
      ).then(({data}) => {
        setPdfsData(data.docs)
        setTotalPages(data.totalPages)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPdfs, location.search, currentSubject, forceReload])

  const handleOnPageChange = (e, {activePage}) => {
    history.push(
      `${location.pathname}?page=${activePage}&limit=${limit}&search=${searchKeyword}&currentSubjectid=${currentSubject}`,
    )
  }

  const onSearchClick = () => {
    history.push(
      `${
        location.pathname
      }?page=${1}&limit=${limit}&search=${searchKeyword}&currentSubjectid=${currentSubject}`,
    )
  }

  React.useEffect(() => {
    // for truncate text on dropdown
    if (subjects.length > 0) {
      let arr = []
      subjects?.forEach(s =>
        arr.push({
          text: truncate(s?.text, 20),
          key: s?.key,
          value: s?.value,
        }),
      )
      setTruncateSubject(arr)
    }

    if (subjects.length && !currentSubject) {
        if (state?.currentSubject) {
        setCurrentSubject(state?.currentSubject)
        history.push(
          `${
            location.pathname
          }?page=${1}&limit=${limit}&search=${searchKeyword}&currentSubjectid=${
            state?.currentSubject
          }`,
        )
      } else {
        setCurrentSubject(subjects[0]?.value)
        history.push(
          `${
            location.pathname
          }?page=${1}&limit=${limit}&search=${searchKeyword}&currentSubjectid=${
            subjects[0]?.value
          }`,
        )
      }
    } 
    // else {
    //   setCurrentSubject(currentSubject)
    // }
  }, [
    currentSubject,
    history,
    limit,
    location.pathname,
    searchKeyword,
    state,
    subjects,
  ])

  const onSubjectChange = (e, {value}) => {
    setCurrentSubject(value)
    history.push(
      `${
        location.pathname
      }?page=${1}&limit=${limit}&search=${searchKeyword}&currentSubjectid=${value}`,
    )
  }

  const handelOnNew = () => {
    if (currentSubject) {
      history.push(routes.pdfs.new(currentSubject), {
        currentSubject: currentSubject,
      })
    }
  }
  return (
    <div className="relative h-screen px-10">
      <Segment className="border-none shadow-none  bg-transparent h-screen relative ">
        {errorGettingPdfs ? (
          <Message error list={formatResponseError(errorGettingPdfs)} />
        ) : null}
        {errorGettingSubjects ? (
          <Message error list={formatResponseError(errorGettingSubjects)} />
        ) : null}
        <Dimmer active={gettingPdfs || gettingSubjects} inverted>
          <Loader active={true} />
        </Dimmer>
        <div className="flex justify-between align-center items-center">
          {/* <h1 className="text-3xl">PDFs</h1> */}
          <Input
            type="text"
            placeholder="Search..."
            size="small"
            className="mr-4 shadow-sm"
            action
            value={searchKeyword}
            onChange={(e, data) => {
              setSearchKeyword(data.value)
            }}
          >
            <input />
            <Button type="button" onClick={onSearchClick}>
              Search
            </Button>
          </Input>
          <div className="flex items-center">
            <Dropdown
              placeholder="Select a subject"
              search
              selection
              value={currentSubject}
              loading={gettingSubjects}
              options={truncateSubject}
              onChange={onSubjectChange}
              className="mx-2 drop-shadow-md shadow-md  "
            />
            <Button
              size="small"
              className="px-10"
              primary
              onClick={handelOnNew}
            >
              Add
            </Button>
          </div>
        </div>
        {/* <Breadcrumb className="mt-4">
        <Breadcrumb.Section link>Home</Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section active>PDFs</Breadcrumb.Section>
      </Breadcrumb> */}

        <div className="mt-12 flex flex-wrap gap-x-5 gap-y-5 items-center justify-start">
          {pdfsData?.map((a, idx) => (
            <PdfCard
              key={a?._id}
              title={a?.title}
              pdfLink={a?.link}
              access={a?.access}
              data={a}
              id={a?._id}
              reload={() => setForceReload(p => !p)}
            />
          ))}
        </div>

        {pdfsData?.length > 0 ?(
          <div className="my-8 flex justify-end ">
          <Pagination
            className="shadow-md my-8"
            activePage={page}
            onPageChange={handleOnPageChange}
            totalPages={totalPages}
          />
        </div>
        ):(null)}
        
      </Segment>
    </div>
  )
}

export default AllPdf
