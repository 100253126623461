import React, {useCallback} from 'react'
import {Link} from 'react-router-dom'
import {
  Button,
  Dimmer,
  Dropdown,
  Loader,
  Message,
  Segment,
} from 'semantic-ui-react'
import {useParams} from 'react-router-dom'
import update from 'immutability-helper'

import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import routes from '../../routes'
import {useLocation} from 'react-router-dom'
import {formatResponseError} from '../../utils/format-response'
import SectionCardOrder from '../../components/shared/section-card-order'
import SectionCard from '../../components/shared/section-card'

// for get all sections
const PracticeRoom = () => {
  const [sections, setSections] = React.useState([])

  const [isordering, setIsordering] = React.useState(false)

  const location = useLocation()

  const {subjectid, chapterid} = useParams()

  const {
    run: getSections,
    error: errorGettingSections,
    isLoading: gettingSections,
  } = useAsync()

  const {
    run: submitSectionsOrder,
    error: errorGettingSectionsOrder,
    isLoading: gettingSectionsOrder,
  } = useAsync()

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = sections[dragIndex]
      setSections(
        update(sections, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [sections],
  )

  const renderCard = (card, index) => {
    return (
      <SectionCardOrder
        key={card.id}
        index={index}
        chapterid={card.id}
        moveCard={moveCard}
        name={card?.name}
        description={card?.description}
        sectionId={subjectid}
      />
    )
  }

  React.useEffect(() => {
    getSections(authAxios.get(`/chapter/section/all?cid=${chapterid}`)).then(
      ({data}) => {
        console.log(data);
        setSections(data)
      },
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSections, location.search, isordering])

  const onReOrder = () => {
    let order = []
    // for(let i of sections){
    //   console.log(i)
    //   for(let j in i){
    //     console.log(j , i[j])
    //   }
    // }
    if (sections && sections?.length > 0) {
      for (let i in sections) {
        order.push(sections[i]?._id)
      }
    }

    submitSectionsOrder(authAxios.patch(`/chapter/section/order`, order)).then(
      ({data}) => {
        console.log(data)
        setIsordering(p => !p)
      },
    )
  }

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent w-full">
        {errorGettingSections ? (
          <Message error list={formatResponseError(errorGettingSections)} />
        ) : null}
        {errorGettingSectionsOrder ? (
          <Message
            error
            list={formatResponseError(errorGettingSectionsOrder)}
          />
        ) : null}
        <Dimmer active={gettingSections || gettingSectionsOrder} inverted>
          {/* <Loader active={true} /> */}
        </Dimmer>
        <div className="flex justify-between align-center">
          <h1 className="text-3xl">Sections </h1>
          <div>
            <Link
              className="text-white"
              to={routes.subjects.chapters.sections.new(subjectid, chapterid)}
            >
              <Button size="small" primary>
                New
              </Button>
            </Link>

            <Dropdown className="ml-3" text="Actions">
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Edit"
                  icon={isordering ? 'toggle on' : 'toggle off'}
                  onClick={() => {
                    setIsordering(p => !p)
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="mt-12 ">
          {!isordering ? (
            <div className="flex flex-wrap gap-x-5 gap-y-5 items-center justify-start  ">
              {sections?.map((a, idx) => (
                <SectionCard
                  key={a._id}
                  name={a?.name}
                  description={a?.description}
                  sectionId={a?._id}
                  chapterid={chapterid}
                  subjectid={subjectid}
                  order={a?.order}
                />
              ))}
            </div>
          ) : (
            <>
              <div>{sections.map((card, i) => renderCard(card, i))}</div>
              <Button
                className="flex justify-end ml-auto"
                primary
                onClick={onReOrder}
              >
                Save
              </Button>
            </>
          )}
        </div>
      </Segment>
    </div>
  )
}

export default PracticeRoom
