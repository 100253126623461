import React from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {Dimmer, Loader, Message, Segment} from 'semantic-ui-react'

import SectionCard from '../../../components/shared/section-card'
import {authAxios} from '../../../config/axios-config'
import useAsync from '../../../hooks/use-async'
import {formatResponseError} from '../../../utils/format-response'

const PracticeRoomReview = () => {
  const [sections, setSections] = React.useState([])

  const [isordering, setIsordering] = React.useState(false)

  const location = useLocation()

  const {subjectid, chapterid} = useParams()

  const {
    run: getSections,
    error: errorGettingSections,
    isLoading: gettingSections,
  } = useAsync()

  React.useEffect(() => {
    getSections(authAxios.get(`/chapter/section/all?cid=${chapterid}`)).then(
      ({data}) => {
        setSections(data)
      },
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSections, location.search, isordering])

  return (
    <div>
      <Segment>
        {errorGettingSections ? (
          <Message error list={formatResponseError(errorGettingSections)} />
        ) : null}

        <Dimmer active={gettingSections} inverted>
          <Loader active={true} />
        </Dimmer>

        <div className="mt-4 ">
          <div className="flex flex-wrap gap-x-5 gap-y-5 items-center justify-start  ">
            {sections?.map((a, idx) => (
              <SectionCard
                name={a?.name}
                key={a?._id}
                description={a?.description}
                sectionId={a?._id}
                chapterid={chapterid}
                subjectid={subjectid}
                order={a?.order}
              />
            ))}
          </div>
        </div>
      </Segment>
    </div>
  )
}

export default PracticeRoomReview
