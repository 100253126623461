import jwt_decode from 'jwt-decode'
import {ROLES} from './constants'

let accessToken = localStorage.getItem('access-token')

const Auth = {
  getToken() {
    return accessToken
  },

  setToken(token) {
    accessToken = token
    localStorage.setItem('access-token', token)
  },

  checkAuth() {
    if (accessToken) return true
    else return false
  },

  // TODO: Implement later
  async updateAccessToken() {},

  _hasExpired() {
    const decodeToken = jwt_decode(accessToken)
    const now = new Date().getTime() / 1000

    if (now > decodeToken.exp) {
      return true
    } else {
      return false
    }
  },

  isAdmin() {
    const user = jwt_decode(accessToken)

    // saftley returning if no token was found
    if (!user) return false

    if (![ROLES.admin].includes(user.role)) {
      return false
    }

    return true
  },

  logout() {
    this.setToken('')
  },
  getAdminInfo() {
    const user = jwt_decode(accessToken);
    return user;
  }
}

export default Auth
