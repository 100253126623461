import {adminsApi} from './settings.api.service'
async function updateAdmin(data) {
  return await adminsApi.updateAdmin(data)
}
async function getAdmin(id) {
  try {
    return await adminsApi.getAdmin(id)
  } catch (e) {
    return null
  }
}

export const adminService = {
  updateAdmin,
  getAdmin,
}
