import React from 'react'
import {useParams} from 'react-router-dom'
import {Dimmer, Loader, Message, Segment} from 'semantic-ui-react'
import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import {formatResponseError} from '../../utils/format-response'
import TeacherClassAccordion from './teacher-class-accordion'

const TeacherClasses = () => {
  const [activeIndex, setActiveIndex] = React.useState(-1)

  const [classData, setClassData] = React.useState([])
  const {userid} = useParams()

  const {
    run: getClasses,
    error: errorGettingClasses,
    isLoading: gettingclasses,
  } = useAsync()

  React.useEffect(() => {
    getClasses(authAxios.get(`/admin/users/instructor/classes/${userid}`)).then(
      ({data}) => {
        setClassData(data)
        console.log(data)
      },
    )
  }, [getClasses, userid])

  const handleClickAccordion = (e, titleProps) => {
    const {index} = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }
  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent">
        <Dimmer active={gettingclasses} inverted>
          <Loader active={true} />
        </Dimmer>

        {errorGettingClasses ? (
          <Message error list={formatResponseError(errorGettingClasses)} />
        ) : null}
     
        <div className="mt-20">
          {classData?.map((a, idx) => (
            <TeacherClassAccordion
              index={idx}
              classid={a?._id}
              name={a?.name}
              activeIndex={activeIndex}
              clickAccordion={handleClickAccordion}
              classData={a}
            />
          ))}
        </div>
      </Segment>
    </div>
  )
}

export default TeacherClasses
