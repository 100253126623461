import React from 'react'
import {FieldArray, Formik, Form as FormFormik} from 'formik'
import {Form, Button, Message} from 'semantic-ui-react'
import * as Yup from 'yup'
import useAsync from '../../hooks/use-async'
import {authAxios} from '../../config/axios-config'
import {formatResponseError} from '../../utils/format-response'
import FormikInput from '../formik/FormikInput'
import FormikDropdown from '../formik/FormikDropdown'
import {useHistory} from 'react-router'
import routes from '../../routes'
import useSubject from '../../hooks/use-subjects'
import useDifficulty from '../../hooks/use-difculty'
import {convertArrayOfObjToFormData} from '../../utils/form-data'
import FileAttachment from '../shared/FileAttachment'
import DefaultContainer from '../shared/default-container'
import { Fragment } from 'react/cjs/react.production.min'

const NewMcqQuestion = () => {
  const [competency, setCompetency] = React.useState([])
  const [attachmentData, setAttachmentData] = React.useState(null)
  const [feedbackError, setFeedbackError] = React.useState(null)

  const history = useHistory()
  const answerOptions = React.useMemo(
    () => [
      {text: 'A', value: 'A', key: 'A'},
      {text: 'B', value: 'B', key: 'B'},
      {text: 'C', value: 'C', key: 'C'},
      {text: 'D', value: 'D', key: 'D'},
    ],
    [],
  )

  const {subjects, errorGettingSubjects, gettingSubjects} = useSubject()
  const {difficulties, gettingDifficulties, errorGettingDifficulty} =
    useDifficulty()

  const {
    run: getCompetency,
    error: errorGettingCompetency,
    isLoading: gettingCompetency,
  } = useAsync()

  const {
    run: createQuestion,
    error: errorCreatingQuestion,
    isLoading: creatingQuestion,
  } = useAsync()

  const newMCQQuestionSchema = Yup.object({
    questionTitle: Yup.string().trim().required('Required'),
    question: Yup.string().trim().required('Required'),
    hint: Yup.string().trim().required('Required'),
    subject: Yup.string().trim().required(),
    answers: Yup.array().required(),
    difficulty: Yup.string().trim().required(),
    competency: Yup.string().trim().required(),

    choices: Yup.array()
      .of(
        Yup.object({
          choice: Yup.string().trim().required('Required'),
        }),
      )
      .length(4)
      .required(),
    feedback: Yup.array()
      .of(
        Yup.object({
          message: Yup.string().trim().required('Required'),
        }),
      )
      .min(1)
      .max(3)
      .required(),
  })

  const getCompetencyOptions = React.useCallback(
    subjectId => {
      getCompetency(
        authAxios.get(`/school/subject/competency/all/?id=${subjectId}`),
      ).then(({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.name, key: s._id, value: s._id}),
        )
        setCompetency(options)
      })
    },
    [getCompetency],
  )

  const createNewQuestion = (values, {resetForm}) => {
    const {answers, ...rest} = values
    const answersToSend = []
    answers.forEach(a => answersToSend.push({choiceNumber: a}))

    const formData = new FormData()

    for (let i in rest) {
      if (i === 'feedback') {
        convertArrayOfObjToFormData(formData, rest[i], 'feedback')
      } else if (i === 'choices') {
        convertArrayOfObjToFormData(formData, rest[i], 'choices')
      } else {
        formData.append(i, rest[i])
      }
    }
    convertArrayOfObjToFormData(formData, answersToSend, 'answers')

    if (attachmentData) formData.append('img', attachmentData)

    createQuestion(authAxios.post('/question/choice', formData)).then(
      ({data}) => {
        resetForm({})
        setAttachmentData(null)

        history.push(routes.mcqQuestion.all)
      },
    )
  }

  return (
    <DefaultContainer>
      <h2 className="mb-8">Create new MCQ question</h2>

      {errorGettingSubjects ? (
        <Message error list={formatResponseError(errorGettingSubjects)} />
      ) : null}
      {errorCreatingQuestion ? (
        <Message error list={formatResponseError(errorCreatingQuestion)} />
      ) : null}
      {errorGettingDifficulty ? (
        <Message error list={formatResponseError(errorGettingDifficulty)} />
      ) : null}

      {feedbackError ? <Message error content={feedbackError} /> : null}

      {errorGettingCompetency ? (
        <Message error list={formatResponseError(errorGettingCompetency)} />
      ) : null}
      <div className="max-w-lg">
        <Formik
          initialValues={{
            subject: '',
            questionTitle: '',
            question: '',
            hint: '',
            difficulty: '',
            competency: '',

            choices: [
              {
                choice: '',
                choiceNumber: 'A',
              },
              {
                choice: '',
                choiceNumber: 'B',
              },
              {
                choice: '',
                choiceNumber: 'C',
              },
              {
                choice: '',
                choiceNumber: 'D',
              },
            ],
            answers: [],
            feedback: [],
          }}
          validationSchema={newMCQQuestionSchema}
          onSubmit={createNewQuestion}
        >
          {formik => (
            <Form onSubmit={formik.handleSubmit} loading={creatingQuestion}>
              <FormikInput name="questionTitle" label="Question title" />

              <Form.Field required>
                <FormikInput name="question" label="Question" />
              </Form.Field>

              <FormikDropdown
                label="Subject"
                name="subject"
                loading={gettingSubjects}
                options={subjects}
                onChangeCB={value => {
                  getCompetencyOptions(value)
                  formik.setFieldValue('competency', '')
                }}
              />

              <FormikDropdown
                label="Competency"
                name="competency"
                placeholder="please select a subject first"
                loading={gettingCompetency}
                options={competency}
                disabled={gettingCompetency}
              />

              <FormikDropdown
                label="Difficulty"
                options={difficulties}
                name="difficulty"
                loading={gettingDifficulties}
              />

              {/* /   Section for choices    / */}
              <FormFormik>
                <FieldArray name="choices">
                  {({insert, remove, push}) => (
                    <div>
                      {formik.values.choices.length > 0 &&
                        formik.values.choices.map((choice, index) => {
                          const {touched, errors} = formik
                          const isTouched =
                            touched.choices && touched.choices[index]?.choice
                          const isError =
                            errors.choices && errors.choices[index]?.choice

                          return (
                            <>
                              <div className="flex" key={index}>
                                <FormikInput
                                  name={`choices.${index}.choiceNumber`}
                                  className="mr-4 w-10"
                                  onChange={() => null}
                                />
                                <FormikInput
                                  name={`choices.${index}.choice`}
                                  placeholder="Answer choice"
                                  className="mt-0 w-full"
                                  error={isTouched && isError}
                                />
                              </div>
                            </>
                          )
                        })}
                    </div>
                  )}
                </FieldArray>
              </FormFormik>
              {/* /   End of Section choices    / */}

              <FormikDropdown
                label="Answers"
                name="answers"
                options={answerOptions}
                multiple
                onChangeCB={values => {
                  formik.setFieldValue(
                    'feedback',
                    answerOptions
                      .filter(op => !values.includes(op.value))
                      .map(a => ({choiceNumber: a.value, message: ''})),
                  )
                }}
              />

              {/* /   Section for Feed-back    / */}
              <h3>Enter Feedback for each question</h3>
              <FieldArray name="feedback">
                {({insert, remove, push}) => (
                  <div>
                    {formik.values.feedback.length > 0 &&
                      formik.values.feedback.map((choice, index) => {
                        const {touched, errors} = formik
                        const isTouched =
                          touched.feedback && touched.feedback[index]?.message
                        const isError =
                          errors.feedback && errors.feedback[index]?.message

                        return (
                          <Fragment key={index}>
                            <div className="flex">
                              <FormikInput
                                name={`feedback.${index}.choiceNumber`}
                                className="mr-4 w-10"
                                onChange={() => null}
                              />
                              <FormikInput
                                name={`feedback.${index}.message`}
                                placeholder="Enter the feedback"
                                className="mt-0 w-full"
                                error={isTouched && isError}
                              />
                            </div>
                          </Fragment>
                        )
                      })}
                    {formik.touched.feedback &&
                    formik.errors.feedback &&
                    typeof formik.errors.feedback !== 'object'
                      ? setFeedbackError(formik.errors.feedback)
                      : null}
                  </div>
                )}
              </FieldArray>
              {/* /   End of Section Feed-back    / */}

              <Form.Field required>
                <FormikInput name="hint" label="Hint" />
              </Form.Field>

              <FileAttachment setAttachmentData={setAttachmentData} />

              <hr />
              <div className="my-8 flex justify-between">
                <Button size="tiny" type="submit" primary>
                  Save
                </Button>
                <Button
                  size="tiny"
                  onClick={() => history.push(routes.mcqQuestion.all)}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </DefaultContainer>
  )
}

export default NewMcqQuestion
