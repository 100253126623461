import React from 'react'
import {
  Accordion,
  Dimmer,
  Icon,
  Loader,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react'
import {AiOutlineCaretDown, AiOutlineCaretRight} from 'react-icons/ai'
import {useParams} from 'react-router-dom'
import {Fragment} from 'react/cjs/react.production.min'

import {truncate} from '../../utils/string'
import useAsync from '../../hooks/use-async'
import {authAxios} from '../../config/axios-config'
import {formatResponseError} from '../../utils/format-response'

const NonVoucherStudentClassAccordion = ({
  subjectid,
  index,
  activeIndex,
  clickAccordion,
  name,
  subject,
  school,
  level,
  teacherincharge,
  status,
}) => {
  const [rowToExpand, setRowToExpand] = React.useState(null)

  const [classData, setClassData] = React.useState([])
  const {userid} = useParams()

  const {
    run: getClassData,
    error: errorGettingClassData,
    isLoading: gettingclassData,
  } = useAsync()

  React.useEffect(() => {
    if (index === activeIndex) {
      getClassData(
        authAxios.get(
          `/admin/users/subscriber/log/${userid}?subject=${subjectid}`,
        ),
      ).then(({data}) => {
        setClassData(data)
        console.log(data)
      })
    }
  }, [getClassData, subjectid, userid, index, activeIndex])

  return (
    <Accordion
      fluid
      styled
      className="rounded-t-md w-full shadow-sm mb-4 hover:shadow hover:opacity-95 p-3 border-2 border-solid animate__animated animate__fadeInUp"
    >
      <Accordion.Title
        active={activeIndex === index}
        index={index}
        onClick={clickAccordion}
        className="flex items-center"
      >
        <Icon name="dropdown" />
        <div className="flex justify-between items-center">
          <p className=" font-semibold">{name}</p>
        </div>
      </Accordion.Title>
      <Accordion.Content active={activeIndex === index}>
        <Segment className="border-none shadow-none bg-transparent">
          <Dimmer active={gettingclassData} inverted>
            <Loader active={true} />
          </Dimmer>

          {errorGettingClassData ? (
            <Message error list={formatResponseError(errorGettingClassData)} />
          ) : null}

          <div>
            <div className="flex my-6">
              <div className=" w-1/6 p-3 rounded-md">Subject</div>
              <div className="bg-gray-200 w-2/6 p-3 rounded-md ml-4">
                {classData?.info?.subject}
              </div>
            </div>
          </div>

          <div className="mt-14">
            <Table className="shadow-sm my-5">
              <Table.Header>
                <Table.Row className="text-sm">
                  <Table.HeaderCell
                    textAlign="center"
                    className="bg-tableHeader"
                  >
                    #
                  </Table.HeaderCell>

                  <Table.HeaderCell className="bg-tableHeader">
                    Chapter
                  </Table.HeaderCell>

                  <Table.HeaderCell className="bg-tableHeader">
                    Average PEX score
                  </Table.HeaderCell>
                  <Table.HeaderCell className="bg-tableHeader">
                    Average WEX score
                  </Table.HeaderCell>
                  <Table.HeaderCell className="bg-tableHeader">
                    Average Tests score
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {classData?.chapters?.map((a, idx) => (
                  <Fragment key={idx}>
                    <Table.Row textAlign="left" verticalAlign="top">
                      <Table.Cell
                        textAlign="center"
                        selectable
                        onClick={() =>
                          rowToExpand?.chapterId === a?.chapterId
                            ? setRowToExpand(null)
                            : setRowToExpand(a)
                        }
                      >
                        {rowToExpand?.chapterId === a.chapterId ? (
                          <AiOutlineCaretDown
                            className="mx-auto mt-4"
                            size="16"
                          />
                        ) : (
                          <AiOutlineCaretRight
                            className="mx-auto mt-4"
                            size="16"
                          />
                        )}
                      </Table.Cell>
                      {/* <Table.Cell>{truncate(a?.name, 15)}</Table.Cell> */}
                      <Table.Cell>{truncate(a?.chapterName, 30)}</Table.Cell>
                      {/* <Table.Cell>{a?.totalScore}</Table.Cell> */}
                      <Table.Cell>{a?.chapterInfo?.pexsAverage} %</Table.Cell>
                      <Table.Cell>{a?.chapterInfo?.wexsAverage} %</Table.Cell>
                      <Table.Cell>{a?.chapterInfo?.testsAverage} %</Table.Cell>
                    </Table.Row>

                    {rowToExpand?.chapterId === a.chapterId ? (
                      <Table.Row
                        key={a.chapterId}
                        className="bg-transparent shadow-none border-none"
                      >
                        <Table.Cell
                          colSpan={16}
                          selectable={false}
                          className="bg-gray-50"
                        >
                          <div className="flex">
                            <Table.Cell
                              selectable={false}
                              className="border solid border-gray-300 bg-primaryLight rounded-md mr-2 flex-1 shadow-none"
                            >
                              <div>
                                <p className="text-center text-primary font-semibold">
                                  PEXs completed{' '}
                                </p>

                                <Table
                                  celled
                                  basic="very"
                                  collapsing
                                  size="small"
                                >
                                  <Table.Header>
                                    <Table.Row>
                                      <Table.HeaderCell width={2}>
                                        Name
                                      </Table.HeaderCell>
                                      <Table.HeaderCell width={2}>
                                        Score
                                      </Table.HeaderCell>
                                      <Table.HeaderCell width={2}>
                                        Duration
                                      </Table.HeaderCell>
                                      <Table.HeaderCell width={2}>
                                        Hints
                                      </Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>
                                  <Table.Body>
                                    {rowToExpand?.chapterExerciseInfo?.pexs?.map(
                                      (c, q) => (
                                        <Table.Row key={q}>
                                          <Table.Cell>{c?.pex}</Table.Cell>
                                          <Table.Cell>
                                            {c?.message ? (
                                              <p>{c?.message}</p>
                                            ) : (
                                              <p>{c?.score?.toFixed(0)}%</p>
                                            )}
                                          </Table.Cell>

                                          <Table.Cell>
                                            {!c?.message && (
                                              <p>
                                                {c?.duration?.hours} :{' '}
                                                {c?.duration?.minutes} :{' '}
                                                {c?.duration?.seconds}
                                              </p>
                                            )}
                                          </Table.Cell>
                                          <Table.Cell>{c?.hint}</Table.Cell>
                                        </Table.Row>
                                      ),
                                    )}
                                  </Table.Body>
                                </Table>
                              </div>
                            </Table.Cell>

                            {/* <div class="border-l-2 m-2 h-40 border-gray-300 p-0 w-5 place-self-center "/> */}

                            <Table.Cell
                              selectable={false}
                              className="border solid border-gray-300 rounded-md bg-primaryLight flex-1 shadow-none"
                            >
                              <p className="text-center text-primary font-semibold">
                                WEXs completed{' '}
                              </p>
                              <Table celled basic="very" size="small">
                                <Table.Header>
                                  <Table.Row>
                                    <Table.HeaderCell width={2}>
                                      Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                      Level
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                      Score
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                      Duration
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                      Hints
                                    </Table.HeaderCell>
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  {rowToExpand?.chapterExerciseInfo?.wexs?.beginner?.map(
                                    (c, q) => (
                                      <Table.Row key={q}>
                                        <Table.Cell>{c?.wex}</Table.Cell>
                                        <Table.Cell>beginner</Table.Cell>
                                        <Table.Cell>
                                          {c?.message ? (
                                            <p>{c?.message}</p>
                                          ) : (
                                            <p>{c?.score?.toFixed(0)}%</p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {!c?.message && (
                                            <p>
                                              {c?.duration?.hours} :{' '}
                                              {c?.duration?.minutes} :{' '}
                                              {c?.duration?.seconds}
                                            </p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>{c?.hint}</Table.Cell>
                                      </Table.Row>
                                    ),
                                  )}
                                  {rowToExpand?.chapterExerciseInfo?.wexs?.intermidate?.map(
                                    (c, q) => (
                                      <Table.Row key={q}>
                                        <Table.Cell>{c?.wex}</Table.Cell>
                                        <Table.Cell>intermediate</Table.Cell>
                                        <Table.Cell>
                                          {c?.message ? (
                                            <p>{c?.message}</p>
                                          ) : (
                                            <p>{c?.score?.toFixed(0)}%</p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {!c?.message && (
                                            <p>
                                              {c?.duration?.hours} :{' '}
                                              {c?.duration?.minutes} :{' '}
                                              {c?.duration?.seconds}
                                            </p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>{c?.hint}</Table.Cell>
                                      </Table.Row>
                                    ),
                                  )}
                                  {rowToExpand?.chapterExerciseInfo?.wexs?.advanced?.map(
                                    (c, q) => (
                                      <Table.Row key={q}>
                                        <Table.Cell>{c?.wex}</Table.Cell>
                                        <Table.Cell>advanced</Table.Cell>
                                        <Table.Cell>
                                          {c?.message ? (
                                            <p>{c?.message}</p>
                                          ) : (
                                            <p>{c?.score?.toFixed(0)}%</p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {!c?.message && (
                                            <p>
                                              {c?.duration?.hours} :{' '}
                                              {c?.duration?.minutes} :{' '}
                                              {c?.duration?.seconds}
                                            </p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>{c?.hint}</Table.Cell>
                                      </Table.Row>
                                    ),
                                  )}
                                </Table.Body>
                              </Table>
                            </Table.Cell>

                            <Table.Cell
                              selectable={false}
                              className="border solid ml-2 border-gray-300 bg-primaryLight  rounded-md flex-1 shadow-none"
                            >
                              <p className="text-center text-primary font-semibold">
                                Tests completed{' '}
                              </p>
                              <Table celled basic="very" size="small">
                                <Table.Header>
                                  <Table.Row>
                                    <Table.HeaderCell width={2}>
                                      Name
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                      Level
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                      Score
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                      Duration
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={2}>
                                      Hints
                                    </Table.HeaderCell>
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  {rowToExpand?.chapterExerciseInfo?.tests?.beginner?.map(
                                    (c, q) => (
                                      <Table.Row key={q}>
                                        <Table.Cell>{c?.test}</Table.Cell>
                                        <Table.Cell>beginner</Table.Cell>
                                        <Table.Cell>
                                          {c?.message ? (
                                            <p>{c?.message}</p>
                                          ) : (
                                            <p>{c?.score?.toFixed(0)}%</p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {!c?.message && (
                                            <p>
                                              {c?.duration?.hours} :{' '}
                                              {c?.duration?.minutes} :{' '}
                                              {c?.duration?.seconds}
                                            </p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>{c?.hint}</Table.Cell>
                                      </Table.Row>
                                    ),
                                  )}
                                  {rowToExpand?.chapterExerciseInfo?.tests?.intermidate?.map(
                                    (c, q) => (
                                      <Table.Row key={q}>
                                        <Table.Cell>{c?.test}</Table.Cell>
                                        <Table.Cell>intermediate</Table.Cell>
                                        <Table.Cell>
                                          {c?.message ? (
                                            <p>{c?.message}</p>
                                          ) : (
                                            <p>{c?.score?.toFixed(0)}%</p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {!c?.message && (
                                            <p>
                                              {c?.duration?.hours} :{' '}
                                              {c?.duration?.minutes} :{' '}
                                              {c?.duration?.seconds}
                                            </p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>{c?.hint}</Table.Cell>
                                      </Table.Row>
                                    ),
                                  )}
                                  {rowToExpand?.chapterExerciseInfo?.tests?.advanced?.map(
                                    (c, q) => (
                                      <Table.Row key={q}>
                                        <Table.Cell>{c?.test}</Table.Cell>
                                        <Table.Cell>advanced</Table.Cell>
                                        <Table.Cell>
                                          {c?.message ? (
                                            <p>{c?.message}</p>
                                          ) : (
                                            <p>{c?.score?.toFixed(0)}%</p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>
                                          {!c?.message && (
                                            <p>
                                              {c?.duration?.hours} :{' '}
                                              {c?.duration?.minutes} :{' '}
                                              {c?.duration?.seconds}
                                            </p>
                                          )}
                                        </Table.Cell>
                                        <Table.Cell>{c?.hint}</Table.Cell>
                                      </Table.Row>
                                    ),
                                  )}
                                </Table.Body>
                              </Table>
                            </Table.Cell>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    ) : null}
                  </Fragment>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Segment>
      </Accordion.Content>
    </Accordion>
  )
}

export default NonVoucherStudentClassAccordion
