import React from 'react'
import {FieldArray, Formik, Form as FormFormik} from 'formik'
import {
  Form,
  Button,
  Message,
  Dimmer,
  Loader,
  FormGroup,
  Segment,
} from 'semantic-ui-react'
import * as Yup from 'yup'
import {useHistory, useParams} from 'react-router'

import useDifficulty from '../../hooks/use-difculty'
import useAsync from '../../hooks/use-async'
import {authAxios} from '../../config/axios-config'
import routes from '../../routes'
import {formatResponseError} from '../../utils/format-response'
import FormikDropdown from '../../components/formik/FormikDropdown'
import FormikInput from '../../components/formik/FormikInput'
import FormikTextArea from '../../components/formik/FormikTextArea'
import QuestionModal from '../../components/shared/question-modal'
import DefaultContainer from '../../components/shared/default-container'

const CreateEditTest = () => {
  const [subjects, setSubjects] = React.useState([])
  const [question, setQuestion] = React.useState()
  const [addingQuestionModal, setAddingQuestionModal] = React.useState()
  const [typeQuestionModal, setTypeQuestionModal] = React.useState()
  const [onSubmitQuestion, setOnSubmitQuestion] = React.useState(null)
  const [maxQuestion, setMaxQuestion] = React.useState(5)

  const history = useHistory()
  const {id} = useParams()

  const {difficulties, gettingDifficulties, errorGettingDifficulty} =
    useDifficulty()
  const typeOptions = React.useMemo(
    () => [{text: 'test', value: 'test', key: 'test'}],
    [],
  )

  const questionOptions = React.useMemo(
    () => [
      {text: 'journal', value: 'journal', key: 'journal'},
      {text: 'blank', value: 'blank', key: 'blank'},
      {text: 'choice', value: 'choice', key: 'choice'},
      {text: 'taccount', value: 'taccount', key: 'taccount'},
      {text: 'amortization', value: 'amortization', key: 'amortization'},
      {text: 'balance-sheet', value: 'balanceSheet', key: 'balanceSheet'},
      ],
    [],
  )

  const {
    run: getSubjects,
    error: errorGettingSubjects,
    isLoading: gettingSubjects,
  } = useAsync()
  const {
    run: createRequestTest,
    error: errorCreatingTest,
    isLoading: creatingTest,
  } = useAsync()

  const {
    run: updateRequestTest,
    error: errorUpdatingTest,
    isLoading: updatingTest,
  } = useAsync()

  const {
    run: getTest,
    error: errorGettingTest,
    isLoading: gettingTest,
  } = useAsync()

  const newTestSchema = Yup.object({
    name: Yup.string().trim().required(),
    type: Yup.string().trim().required(),

    instructions: Yup.string().trim().required(),
    subject: Yup.string().trim().required(),
    difficulty: Yup.string().trim().required(),
    duration: Yup.object().shape({
      miniute: Yup.number()
        .typeError('Only numbers are allowed')
        .min(0, 'Must be more than 0')
        .max(60, "hours can't be more than 60 ")
        .required('Required'),
      hour: Yup.number()
        .typeError('Only numbers are allowed')
        .min(0, 'Must be a valid number')
        .max(10, "hours can't be more than 10 ")
        .required('Required'),
    }),
    maxQuestion: Yup.number()
      .typeError('Only numbers are allowed')
      .integer('Enter a valid number')
      .min(5, 'Must be more than or equal 5')
      .max(20, 'Must be less than or equal 20')
      .required('Required'),
    questions: Yup.array()
      .of(
        Yup.object({
          questionTitle: Yup.string()
            .trim()
            .required('you have to add question'),
        }),
      )
      .min(maxQuestion)
      .max(maxQuestion)
      .required(),
  })

  // const sendDataToParent = valuesFromChild => {
  //   // the callback. Use a better name
  //   console.log('valuesFromChild', valuesFromChild)
  //   setCurrentQuestiontitle(valuesFromChild)
  //   // setCurrentQuestiontitle(valuesFromChild?.questionTitle)
  // }

  const updateQuestion = values => {
    const withoutQuestionTitle = {
      ...values,
      questions: values.questions.map(q => ({...q, questionTitle: undefined})),
    }
    if (id) {
      updateRequestTest(
        authAxios.put(`/exercise/${id}`, withoutQuestionTitle),
      ).then(({data}) => {
        history.push(routes.exerice.all)
      })
    } else {
      createRequestTest(authAxios.post(`/exercise`, withoutQuestionTitle)).then(
        ({data}) => {
          history.push(routes.exerice.all)
        },
      )
    }
  }

  React.useEffect(() => {
    if (id)
      getTest(authAxios.get(`/exercise/${id}`)).then(({data}) => {
        setMaxQuestion(data.maxQuestion)
        setQuestion(data)
      })
  }, [getTest, id])

  React.useEffect(() => {
    getSubjects(authAxios.get('/school/subject/all')).then(({data}) => {
      let options = []
      data?.subjects.forEach(s =>
        options.push({text: s.name, key: s._id, value: s._id}),
      )
      setSubjects(options)
    })
  }, [getSubjects])

  return (
    <DefaultContainer>
      <Segment className="border-none shadow-none  bg-transparent">
        <h3 className="mt-4 mb-8">{id ? 'Update' : 'Create new'} Test </h3>

        {errorGettingSubjects ? (
          <Message error list={formatResponseError(errorGettingSubjects)} />
        ) : null}

        {errorCreatingTest ? (
          <Message error list={formatResponseError(errorCreatingTest)} />
        ) : null}

        {errorGettingDifficulty ? (
          <Message error list={formatResponseError(errorGettingDifficulty)} />
        ) : null}

        {errorGettingTest ? (
          <Message error list={formatResponseError(errorGettingTest)} />
        ) : null}
        {errorUpdatingTest ? (
          <Message error list={formatResponseError(errorUpdatingTest)} />
        ) : null}
        <Dimmer active={creatingTest || gettingTest || updatingTest} inverted>
          <Loader active={true} />
        </Dimmer>
        <div className="max-w-lg">
          <Formik
            initialValues={{
              name: question?.name || '',
              type: question?.type || '',
              subject: question?.subject || '',
              difficulty: question?.difficulty || '',
              maxQuestion: question?.maxQuestion || 5,
              instructions: question?.instructions || '',
              duration: question?.duration || {
                hour: '',
                miniute: '',
              },
              questions: question?.questions || [
                {
                  questionId: '',
                  type: '',
                  questionTitle: '',
                },
              ],
            }}
            validationSchema={newTestSchema}
            onSubmit={updateQuestion}
            enableReinitialize
          >
            {formik => (
              <Form onSubmit={formik.handleSubmit} loading={creatingTest}>
                <FormikInput name="name" label="Name" />

                <FormikDropdown
                  label="Type"
                  name="type"
                  options={typeOptions}
                />
                <FormikDropdown
                  label="Subject"
                  name="subject"
                  loading={gettingSubjects}
                  options={subjects}
                  onChangeCB={() => {
                    formik.setFieldValue(`questions`, [
                      {
                        questionId: '',
                        type: '',
                        questionTitle: '',
                      },
                    ])
                  }}
                />

                <FormikDropdown
                  label="Difficulty"
                  options={difficulties}
                  name="difficulty"
                  loading={gettingDifficulties}
                />
                <FormikInput
                  name="maxQuestion"
                  label="Max number of Question"
                  onChange={(e, v) => {
                    formik.setFieldValue('maxQuestion', e.target.value)
                    setMaxQuestion(e.target.value)
                  }}
                />
                <div className="flex flex-col">
                  <p>Duration :</p>
                  <FormGroup className="flex items-center pl-2 mt-2">
                    <FormikInput
                      type="number"
                      name="duration.hour"
                      label="Hour"
                      error={
                        formik.touched?.duration?.hour &&
                        formik.errors?.duration?.hour
                      }
                    />
                    <FormikInput
                      type="number"
                      name="duration.miniute"
                      label="Minutes"
                      error={
                        formik.touched?.duration?.miniute &&
                        formik.errors?.duration?.miniute
                      }
                    />
                  </FormGroup>
                </div>

                <FormikTextArea name="instructions" label="instructions" />

                <FormFormik>
                  {formik.touched.questions &&
                    formik.errors.questions &&
                    !Array.isArray(formik.errors.questions) && (
                      <p className="bg-white border border-red-300 px-3 py-2 rounded-md text-red-700 font-bold">
                        {' '}
                        Questions lenght has to match max questions
                      </p>
                    )}

                  <FieldArray name="questions">
                    {({insert, remove, push}) => (
                      <div>
                        {formik.values.questions.length > 0 &&
                          formik.values.questions.map((choice, index) => {
                            const {touched, errors} = formik
                            const isTouchedQuestion =
                              touched.questions &&
                              touched.questions[index]?.questionTitle

                            const isErrorQuestion =
                              errors.questions &&
                              errors.questions[index]?.questionTitle

                            return (
                              <div className="" key={index}>
                                <div class="border-2 my-4 p-2">
                                  <div className="flex flex-col">
                                    <FormikDropdown
                                      label="Type"
                                      disabled={!Boolean(formik.values.subject)}
                                      placeholder="please select a subject first"
                                      name={`questions.${index}.type`}
                                      options={questionOptions}
                                      onChangeCB={() => {
                                        formik.setFieldValue(
                                          `questions.${index}.questionTitle`,
                                          '',
                                        )
                                      }}
                                    />
                                    <FormikInput
                                      label="Question"
                                      name={`questions.${index}.questionTitle`}
                                      onChange={() => null}
                                      disabled
                                      placeholder="Select a type first and choose a question"
                                      error={
                                        isTouchedQuestion && isErrorQuestion
                                      }
                                    />
                                    <Button
                                      primary
                                      type="button"
                                      disabled={
                                        !Boolean(
                                          formik.values.questions[index].type,
                                        )
                                      }
                                      onClick={() => {
                                        setTypeQuestionModal(
                                          formik.values.questions[index].type,
                                        )
                                        setOnSubmitQuestion(() => {
                                          return val => {
                                            formik.setFieldValue(
                                              `questions.${index}.questionId`,
                                              val.questionId,
                                            )
                                            formik.setFieldValue(
                                              `questions.${index}.questionTitle`,
                                              val.questionTitle,
                                            )
                                          }
                                        })
                                        setAddingQuestionModal(true)
                                        // console.log('here',{currentQuestiontitle})
                                        // formik.setFieldValue(`questions.${index}.questionTitle`,{currentQuestiontitle})
                                      }}
                                    >
                                      Choose a Question
                                    </Button>
                                  </div>
                                  <div className="mt-0  mb-4 flex w-full  justify-end place-items-end">
                                    {index === 0 ? null : (
                                      <Button
                                        className="justify-end mt-1 ml-auto"
                                        size="tiny"
                                        negative
                                        basic
                                        onClick={() => remove(index)}
                                        type="button"
                                      >
                                        -
                                      </Button>
                                    )}
                                  </div>
                                </div>
                                <div className="mt-0 mb-4  flex w-full  justify-end place-items-end">
                                  {index ===
                                    formik.values.questions.length - 1 &&
                                  index + 1 < formik.values.maxQuestion ? (
                                    <Button
                                      className="justify-end mt-1 ml-auto"
                                      size="tiny"
                                      primary
                                      disabled={
                                        !Boolean(formik.values.maxQuestion)
                                      }
                                      onClick={() => {
                                        push({
                                          questionId: '',
                                          questionTitle: '',
                                          type: '',
                                        })
                                      }}
                                      type="button"
                                    >
                                      Add new question
                                    </Button>
                                  ) : null}
                                </div>
                              </div>
                            )
                          })}
                      </div>
                    )}
                  </FieldArray>
                </FormFormik>
                {/* /   End of Section add question    / */}
                <QuestionModal
                  open={Boolean(addingQuestionModal)}
                  subject={formik.values.subject}
                  onClose={cb => {
                    setAddingQuestionModal(false)
                    setOnSubmitQuestion(null)
                    if (typeof cb === 'function') {
                      console.log('called CB')
                      cb()
                    }
                  }}
                  type={typeQuestionModal}
                  sendDataToParent={onSubmitQuestion}
                />

                <hr />
                <div className="my-8 flex justify-between">
                  <Button size="tiny" type="submit" primary>
                    Save
                  </Button>
                  <Button
                    size="tiny"
                    onClick={() => history.push(routes.exerice.all)}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Segment>
    </DefaultContainer>
  )
}

export default CreateEditTest
