import React from 'react'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import {CgAttachment} from 'react-icons/cg'
import {RiDeleteBin7Fill} from 'react-icons/ri'
import {useLanguage} from '../../context/language-context'
import {content} from '../../localization/content'

function PdfsAttachments({setAttachmentData}) {
  const [lang] = useLanguage()
  const selectedContent = content[lang]

  const [files, setFiles] = React.useState([])

  const [pdfs, setPdfs] = React.useState([])




  const handleOnSelectedFile = e => {
    setFiles(e.target.files)
    setAttachmentData([...e.target.files])
    setPdfs([...e.target.files])
  }

  const clearAttachment = () => {
    setFiles([])
    setAttachmentData([])
  }

  return (
    <>
      {files.length > 0 ? (
        <div className="flex justify-between bg-white rounded-md p-3">
          <span className='break-all'>
            <strong>File Name</strong>: {pdfs.map((file, i) => <div key={i}>{file.name}</div>)}
          </span>
          <div
            className="text-red-800 flex items-center justify-center cursor-pointer"
            onClick={clearAttachment}
          >
            <RiDeleteBin7Fill size="16" className="ltr:mr-3 rtl:ml-3" />
            clear
          </div>
        </div>
      ) : (
        <>
          <input
            type="file"
            alt="image"
            id="file-input"
            accept=".pdf"
            multiple="multiple"
            className="hidden"
            onChange={handleOnSelectedFile}
          />
          <label
            htmlFor="file-input"
            className="text-primaryRedColor-default flex border rounded-md bg-white p-2"
          >
            <div className="flex justify-between cursor-pointer w-full">
              <span>Select File(s)</span>
              <AiOutlineCloudUpload size="24" className="ltr:mr-3 rtl:ml-3" />
            </div>
          </label>
          <span className="ml-2 text-gray-400 text-sm">
            Please select a pdf format only.
          </span>
        </>
      )}
    </>
  )
}

export default PdfsAttachments
