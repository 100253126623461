import React from 'react'

const CustomComp = () => {
    return (
        <div>
            customComp
        </div>
    )
}

export default CustomComp
