export const ROLES = {
  admin: 'admin',
}

export const JOURNAL_QUESTION_FIELDS = {
  ACCOUNT_NAME_ON_DEBIT: 'debitAccountName',
  ACCOUNT_NAME_ON_CREDIT: 'creditAccountName',
  DEBIT: 'debitValue',
  CREDIT: 'creditValue',
}
