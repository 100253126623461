import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import queryString from 'query-string'
import {
  Button,
  Dimmer,
  Dropdown,
  Input,
  Loader,
  Message,
  Pagination,
  Segment,
  Table,
} from 'semantic-ui-react'
import {AiOutlineEye, AiOutlineMinusCircle} from 'react-icons/ai'

import useAsync from '../hooks/use-async'
import {authAxios, axios} from '../config/axios-config'
import {formatResponseError} from '../utils/format-response'
import routes from '../routes'

const VoucherStudent = () => {
  const [students, setStudents] = React.useState([])
  const [classes, setClasses] = useState([])
  const [subjects, setSubjects] = useState([])

  const [searchKeyword, setSearchKeyword] = useState('')
  const [currentClass, setCurrentClass] = useState('')
  const [currentSubject, setCurrentSubject] = useState('')

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [limit, setLimit] = useState(10)

  const location = useLocation()
  const history = useHistory()

  const {
    run: getSubjects,
    isLoading: gettingSubjects,
    error: errorGettingSubjects,
  } = useAsync()
  const {
    run: getClasses,
    isLoading: gettingClasses,
    error: errorGettingClasses,
  } = useAsync()
  const {
    run: getStudents,
    isLoading: gettingStudents,
    error: errorGettingStudents,
  } = useAsync()

  React.useEffect(() => {
    const {
      page = 1,
      limit = 10,
      search = '',
    } = queryString.parse(location.search)

    setPage(page)
    setLimit(limit)
    setSearchKeyword(search)

    getStudents(
      authAxios.get(
        `/admin/users/student?page=${page}&limit=${limit}&search=${search}&subject=${currentSubject}&class=${currentClass}`,
      ),
    ).then(({data}) => {
      console.log(data)
      setStudents(data.docs)
      setTotalPages(data.totalPages)
    })
  }, [currentClass, currentSubject, getStudents, location.search])

  React.useEffect(() => {
    getClasses(authAxios.get(`/class/all`)).then(({data}) => {
      let arr = [{text: '', key: '1', value: ''}]
      data?.forEach(s =>
        arr.push({
          text: s?.name,
          key: s?._id,
          value: s?._id,
        }),
      )
      setClasses(arr)
      setCurrentClass(arr[0]?.value)
    })
  }, [getClasses])

  React.useEffect(() => {
    getSubjects(axios.get('/school/subject/all')).then(({data}) => {
      let subjectsOptions = [{text: '', key: '1', value: ''}]
      data?.subjects?.map(d =>
        subjectsOptions.push({
          key: d._id,
          text: d.name,
          value: d._id,
        }),
      )
      setSubjects(subjectsOptions)
      setCurrentSubject(subjectsOptions[0]?.value)
    })
  }, [getSubjects])

  const onSearchClick = () => {
    history.push(
      `${location.pathname}?page=${1}&limit=${limit}&search=${searchKeyword}`,
    )
  }
  const handleOnPageChange = (e, {activePage}) => {
    history.push(
      `${location.pathname}?page=${activePage}&limit=${limit}&search=${searchKeyword}`,
    )
  }

  const onClassChange = (e, {value}) => {
    setCurrentClass(value)

    history.replace(`${location.pathname}?currentclassid=${value}`)
  }
  const onSubjectChange = (e, {value}) => {
    setCurrentSubject(value)

    history.replace(`${location.pathname}?currentsubjectid=${value}`)
  }

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent">
        <Dimmer active={gettingStudents} inverted>
          <Loader active={true} />
        </Dimmer>

        {errorGettingSubjects ? (
          <Message error list={formatResponseError(errorGettingSubjects)} />
        ) : null}
        {errorGettingStudents ? (
          <Message error list={formatResponseError(errorGettingStudents)} />
        ) : null}
        {errorGettingClasses ? (
          <Message error list={formatResponseError(errorGettingClasses)} />
        ) : null}
        <div>
          <Input
            type="text"
            placeholder="Search..."
            size="small"
            className="mr-4"
            action
            value={searchKeyword}
            onChange={(e, data) => {
              setSearchKeyword(data.value)
            }}
          >
            <input />
            <Button type="button" onClick={onSearchClick}>
              Search
            </Button>
          </Input>
        </div>
        <div className="flex mt-16">
          <Dropdown
            placeholder="Classes"
            search
            selection
            value={currentClass}
            loading={gettingClasses}
            options={classes}
            onChange={onClassChange}
            className="drop-shadow-md shadow-md ml-auto"
          />
          <Dropdown
            placeholder="Subjects"
            search
            selection
            value={currentSubject}
            loading={gettingSubjects}
            options={subjects}
            onChange={onSubjectChange}
            className=" drop-shadow-md shadow-md  mx-2"
          />
        </div>

        <Table striped className="shadow-lg ">
          <Table.Header>
            <Table.Row
              className="text-sm"
              textAlign="center"
              verticalAlign="middle"
            >
              <Table.HeaderCell className="bg-tableHeader" width="2">
                Student's name
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader" width="2">
                Email
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader" width="2">
                School
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader" width="2">
                Class
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader" width="2">
                Subject
              </Table.HeaderCell>
              <Table.HeaderCell className="bg-tableHeader" width="2">
                Actions
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {students?.map((a, idx) => (
              <Table.Row
                key={a?._id?._id}
                textAlign="center"
                verticalAlign="top"
              >
                <Table.Cell>{a?._id?.studentName}</Table.Cell>
                <Table.Cell>{a?._id?.studentEmail}</Table.Cell>
                <Table.Cell>
                  {a?.schools?.length ? a?.schools[0]?.name : ''}
                </Table.Cell>
                <Table.Cell>
                  {a?.classes?.length ? a?.classes[0]?.name : ''}
                </Table.Cell>
                <Table.Cell>
                  {a?.subjects?.length ? a?.subjects[0]?.name : ''}
                </Table.Cell>
                <Table.Cell className=" flex items-center justify-center">
                  <Button size="small" className=" bg-transparent ">
                    <AiOutlineEye
                      size="16"
                      onClick={() =>
                        history.push(
                          routes.voucherstudentInfo.voucherstudentClasses(
                            a?._id?._id,
                          ),
                        )
                      }
                    />
                  </Button>
                  <Button size="small" className=" bg-transparent p-0 ">
                    <AiOutlineMinusCircle size="14" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        {students?.length > 0 && (
          <div className="flex justify-end my-8">
            <Pagination
              activePage={page}
              onPageChange={handleOnPageChange}
              totalPages={totalPages}
            />
          </div>
        )}
      </Segment>
    </div>
  )
}

export default VoucherStudent
