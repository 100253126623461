import {Formik} from 'formik'
import React from 'react'
import {useHistory, useLocation, useParams} from 'react-router'
import {
  Button,
  Dimmer,
  Form,
  Image,
  Loader,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react'
import * as Yup from 'yup'

import {authAxios} from '../../config/axios-config'
import useAsync from '../../hooks/use-async'
import FormikDropdown from '../formik/FormikDropdown'
import FormikInput from '../formik/FormikInput'
import FormikDate from '../formik/FormikDate'
import {formatResponseError} from '../../utils/format-response'
import {JOURNAL_QUESTION_FIELDS} from '../../utils/constants'
import useDifficulty from '../../hooks/use-difculty'
import useAccountNames from '../../hooks/use-account-names'
import routes from '../../routes'
import FileAttachment from '../shared/FileAttachment'
import {convertArrayOfObjToFormData} from '../../utils/form-data'
import useCategory from '../../hooks/use-category'
import {truncateToDecimal} from '../../utils/numbers'

let tableRowsCounter = 0

const UpdateJournalQuestion = () => {
  const [subjects, setSubjects] = React.useState([])
  const [answers, setAnswers] = React.useState([{_id: ++tableRowsCounter}])
  const [answersError, setAnswersError] = React.useState(null)
  const [question, setQuestion] = React.useState()
  const {
    accountNames,
    accountNumbers,
    errorGettingAccountNames,
    gettingAccountNames,
  } = useAccountNames()

  const history = useHistory()
  const location = useLocation()
  const {id} = useParams()
  const [state] = React.useState(location?.state)

  const [competency, setCompetency] = React.useState([])

  const [attachmentData, setAttachmentData] = React.useState(null)

  const {category, gettingCategory, errorGettingCategory} = useCategory()

  const {
    run: getQuestion,
    error: errorGettingQuestion,
    isLoading: gettingQuestion,
  } = useAsync()

  const {
    run: updateQuestion,
    error: errorUpdatingQuestion,
    isLoading: updatingQuestion,
  } = useAsync()

  const {
    run: getSubjects,
    error: errorGettingSubjects,
    isLoading: gettingSubjects,
  } = useAsync()

  const {
    run: getAccountNumbers,
    error: errorGettingAccountNumbers,
    isLoading: gettingAccountNumbers,
  } = useAsync()
  const {
    run: getCompetency,
    error: errorGettingCompetency,
    isLoading: gettingCompetency,
  } = useAsync()

  const {difficulties, gettingDifficulties, errorGettingDifficulty} =
    useDifficulty()

  const removeAnswer = idx => {
    // don't delete the first row
    if (idx === 0) return
    setAnswers([...answers.slice(0, idx), ...answers.slice(idx + 1)])
  }

  const updateJournalQuestionSchema = Yup.object({
    questionTitle: Yup.string().trim().required('Required'),
    question: Yup.string().trim().required('Required'),
    subject: Yup.string().trim().required(),
    competency: Yup.string().trim().required(),
    difficulty: Yup.string().trim().required(),
    feedbackCategory: Yup.string().trim().required(),

    hint: Yup.string().trim().required(),
    correctDate: Yup.string()
      .matches(/^(0?[1-9]|[12][0-9]|3[01])\-(0?[1-9]|1[012])\-\d{4}$/, 'Only valid dates are allowed')
      .required('Required'),
  })

  const addAnswer = idx => {
    const newAnswer = {
      _id: ++tableRowsCounter,
      [JOURNAL_QUESTION_FIELDS.ACCOUNT_NAME_ON_CREDIT]: undefined,
      [JOURNAL_QUESTION_FIELDS.ACCOUNT_NAME_ON_DEBIT]: undefined,
      [JOURNAL_QUESTION_FIELDS.DEBIT]: undefined,
      [JOURNAL_QUESTION_FIELDS.CREDIT]: undefined,
      accountnumber: '',
    }

    if (idx === 0 && answers.length === 1)
      return setAnswers([...answers, newAnswer])

    return setAnswers([
      ...answers.slice(0, idx + 1),
      newAnswer,
      ...answers.slice(idx + 1),
    ])
  }

  const updateAnswer = (id, val, field) => {
    setAnswers(() =>
      answers.map(a => {
        if (a._id === id) a[field] = val
        return a
      }),
    )
  }

  const sendUpdates = (values, {resetForm}) => {
    const data = {
      ...values,
      amountOfAccounts: answers.length,
      answers: answers.map(a => {
        const {_id, ...v} = a
        const obj = {}

        // remove undefined values
        Object.keys(v).forEach(y => (v[y] ? (obj[y] = v[y]) : null))
        return obj
      }),
    }

    const formData = new FormData()

    for (let i in data) {
      if (i === 'answers') {
        convertArrayOfObjToFormData(formData, data[i], 'answers')
      } else {
        formData.append(i, data[i])
      }
    }

    if (attachmentData) formData.append('img', attachmentData)

    let errors = []
    data.answers.forEach((a, i) => {
      if (!a) errors.push(`Row ${i + 1} can't be empty`)
      if (!a.accountnumber)
        errors.push(`Account number in row ${i + 1} is missing`)
      if (!a.debitAccountName && !a.creditAccountName)
        errors.push(
          `You have to add an answer in row ${i + 1} in either debit or credit`,
        )

      if (a.debitAccountName && !a.debitValue)
        errors.push(`You have to add debit value in row ${i + 1}`)
      if (a.creditAccountName && !a.creditValue)
        errors.push(`You have to add credit value in row ${i + 1}`)

      if (a.debitAccountName && a.debitValue && (!(a.debitValue.match(/(^\d{1,3}(\.?\d{3})*(,\d{2})?$)|(^\d{1,3}(,?\d{3})*(\.\d{2})?$)/))))
        errors.push(`Only numbers are allowed in debit field row ${i + 1}`)

      if (a.creditAccountName && a.creditValue && (!(a.creditValue.match(/(^\d{1,3}(\.?\d{3})*(,\d{2})?$)|(^\d{1,3}(,?\d{3})*(\.\d{2})?$)/))))
        errors.push(`Only numbers are allowed in credit field row ${i + 1}`)
    })

    if (errors.length > 0) {
      setAnswersError(errors)
      return
    } else setAnswersError(null)

    updateQuestion(authAxios.patch(`/question/journal/${id}`, formData)).then(
      () => {
        resetForm({})
        setAttachmentData(null)
        history.push(routes.jouralQusetion.all)
      },
    )
  }
  const getCompetencyOptions = React.useCallback(
    subjectId => {
      getCompetency(
        authAxios.get(`/school/subject/competency/all/?id=${subjectId}`),
      ).then(({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.name, key: s._id, value: s._id}),
        )
        setCompetency(options)
      })
    },
    [getCompetency],
  )

  React.useEffect(() => {
    if (state?.data?.subject) {
      getCompetency(
        authAxios.get(
          `/school/subject/competency/all/?id=${state?.data?.subject}`,
        ),
      ).then(({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.name, key: s._id, value: s._id}),
        )
        setCompetency(options)
      })
    }
  }, [getCompetency, state?.data?.subject])

  React.useEffect(() => {
    // ignore getting request when redirected from the create view
    if (state?.new) return

    getQuestion(authAxios.get(`/question/journal?qid=${id}`)).then(({data}) => {
      getCompetency(
        authAxios.get(`/school/subject/competency/all/?id=${data.subject._id}`),
      ).then(({data}) => {
        let options = []
        data?.forEach(s =>
          options.push({text: s.name, key: s._id, value: s._id}),
        )
        setCompetency(options)
      })

      let question = {}
      Object.keys(data).forEach(key => {
        if (key === 'answers') {
          if (data[key].length > 0) setAnswers(data[key])
        } else question[key] = data[key]
      })
      setQuestion(question)
    })
  }, [getCompetency, getQuestion, id, state?.new])

  React.useEffect(() => {
    getSubjects(authAxios.get('/school/subject/all')).then(({data}) => {
      let options = []
      data?.subjects.forEach(s =>
        options.push({text: s.name, key: s._id, value: s._id}),
      )
      setSubjects(options)
    })
  }, [getSubjects])

  return (
    <div>
      <Segment className="border-none shadow-none bg-transparent w-full overflow-x-auto">
        <h2 className="mb-8">
          {state?.new ? 'Create new Journal question' : 'Update Journal'}
        </h2>

        {errorUpdatingQuestion ? (
          <Message error list={formatResponseError(errorUpdatingQuestion)} />
        ) : null}
        {errorGettingCategory ? (
          <Message error list={formatResponseError(errorGettingCategory)} />
        ) : null}
        {errorGettingQuestion ? (
          <Message error list={formatResponseError(errorGettingQuestion)} />
        ) : null}

        {errorGettingAccountNames ? (
          <Message error list={formatResponseError(errorGettingAccountNames)} />
        ) : null}

        {errorGettingAccountNumbers ? (
          <Message
            error
            list={formatResponseError(errorGettingAccountNumbers)}
          />
        ) : null}

        {errorGettingSubjects ? (
          <Message
            error
            list={formatResponseError(errorGettingAccountNumbers)}
          />
        ) : null}
        {errorGettingDifficulty ? (
          <Message error list={formatResponseError(errorGettingDifficulty)} />
        ) : null}
        {answersError?.length > 0 ? (
          <Message className="mb-2" error list={answersError} />
        ) : null}
        {errorGettingCompetency ? (
          <Message error list={formatResponseError(errorGettingCompetency)} />
        ) : null}
        <Dimmer
          active={
            gettingQuestion || gettingAccountNames || gettingAccountNumbers
          }
          inverted
        >
          <Loader active={true} />
        </Dimmer>

        <Formik
          initialValues={{
            subject: question?.subject._id || state?.data?.subject,
            competency: question?.competency || state?.data?.competency || '',
            hint: question?.hint || state?.data?.hint,
            feedbackCategory: question?.feedbackCategory || '',
            questionTitle:
              question?.questionTitle || state?.data?.questionTitle,
            difficulty: question?.difficulty || state?.data?.difficulty || '',
            question: question?.question || '',
            correctDate: question?.correctDate || '',
          }}
          enableReinitialize
          onSubmit={sendUpdates}
          validationSchema={updateJournalQuestionSchema}
        >
          {formik => (
            <>
              <Form onSubmit={formik.handleSubmit} loading={updatingQuestion}>
                <div className="max-w-lg">
                  <FormikInput name="questionTitle" label="Question title" />
                  <FormikDropdown
                    label="Subject"
                    name="subject"
                    loading={gettingSubjects}
                    options={subjects}
                    onChangeCB={value => {
                      getCompetencyOptions(value)
                      formik.setFieldValue('competency', '')
                    }}
                  />

                  <FormikDropdown
                    label="Competency"
                    name="competency"
                    loading={gettingCompetency}
                    options={competency}
                    disabled={gettingCompetency}
                  />
                  <FormikDropdown
                    label="Difficulty"
                    options={difficulties}
                    name="difficulty"
                    loading={gettingDifficulties}
                  />

                  <FormikInput
                    label="Hint"
                    name="hint"
                    placeholder="Question hint"
                  />
                  <FormikDropdown
                    label="Category"
                    name="feedbackCategory"
                    loading={gettingCategory}
                    options={category}
                  />
                  <FormikInput name="question" label="Question" />
                  <FormikDate name="correctDate" label="Correct date"  placeholder="Enter date: (dd-mm-yyyy)"/>
                </div>

                <div className="mt-8">
                  <Table basic="very">
                    <Table.Header>
                      <Table.Row className="text-sm">
                        <Table.HeaderCell>Account number</Table.HeaderCell>
                        <Table.HeaderCell>
                          Account name on debit
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          Account name on credit
                        </Table.HeaderCell>
                        <Table.HeaderCell>Debit</Table.HeaderCell>
                        <Table.HeaderCell>Credit</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {answers.map((a, idx) => (
                        <Table.Row
                          key={a._id}
                          textAlign="left"
                          verticalAlign="top"
                        >
                          <Table.Cell width={1}>
                            <Form.Dropdown
                              options={accountNumbers}
                              value={a.accountnumber}
                              onChange={() => null}
                              fluid
                              selection
                              className="text-gray-700"
                              disabled
                            />
                          </Table.Cell>
                          <Table.Cell width={3}>
                            <Form.Dropdown
                              options={accountNames}
                              value={
                                a[JOURNAL_QUESTION_FIELDS.ACCOUNT_NAME_ON_DEBIT]
                              }
                              onChange={(e, {value}) => {
                                updateAnswer(a._id, value, 'accountnumber')
                                updateAnswer(
                                  a._id,
                                  value,
                                  JOURNAL_QUESTION_FIELDS.ACCOUNT_NAME_ON_DEBIT,
                                )
                              }}
                              disabled={
                                a[
                                  JOURNAL_QUESTION_FIELDS.ACCOUNT_NAME_ON_CREDIT
                                ] || a[JOURNAL_QUESTION_FIELDS.CREDIT]
                              }
                              fluid
                              selection
                              search
                              clearable
                            />
                          </Table.Cell>
                          <Table.Cell width={3}>
                            <Form.Dropdown
                              options={accountNames}
                              value={
                                a[
                                  JOURNAL_QUESTION_FIELDS.ACCOUNT_NAME_ON_CREDIT
                                ]
                              }
                              onChange={(e, {value}) => {
                                updateAnswer(a._id, value, 'accountnumber')
                                updateAnswer(
                                  a._id,
                                  value,
                                  JOURNAL_QUESTION_FIELDS.ACCOUNT_NAME_ON_CREDIT,
                                )
                              }}
                              fluid
                              selection
                              search
                              clearable
                              disabled={
                                a[
                                  JOURNAL_QUESTION_FIELDS.ACCOUNT_NAME_ON_DEBIT
                                ] || a[JOURNAL_QUESTION_FIELDS.DEBIT]
                              }
                            />
                          </Table.Cell>
                          <Table.Cell width={2}>
                            <Form.Input
                              value={a[JOURNAL_QUESTION_FIELDS.DEBIT]}
                              onChange={e =>
                                updateAnswer(
                                  a._id,
                                  e.target.value,
                                  JOURNAL_QUESTION_FIELDS.DEBIT,
                                )
                              }
                              onBlur={e =>
                                updateAnswer(
                                  a._id,
                                  truncateToDecimal(e.target.value),
                                  JOURNAL_QUESTION_FIELDS.DEBIT,
                                )
                              }
                              disabled={
                                a[
                                  JOURNAL_QUESTION_FIELDS.ACCOUNT_NAME_ON_CREDIT
                                ] || a[JOURNAL_QUESTION_FIELDS.CREDIT]
                              }
                            />
                          </Table.Cell>
                          <Table.Cell width={2}>
                            <Form.Input
                              value={a[JOURNAL_QUESTION_FIELDS.CREDIT]}
                              onChange={e =>
                                updateAnswer(
                                  a._id,
                                  e.target.value,
                                  JOURNAL_QUESTION_FIELDS.CREDIT,
                                )
                              }
                              onBlur={e =>
                                updateAnswer(
                                  a._id,
                                  truncateToDecimal(e.target.value),
                                  JOURNAL_QUESTION_FIELDS.CREDIT,
                                )
                              }
                              disabled={
                                a[
                                  JOURNAL_QUESTION_FIELDS.ACCOUNT_NAME_ON_DEBIT
                                ] || a[JOURNAL_QUESTION_FIELDS.DEBIT]
                              }
                            />
                          </Table.Cell>
                          <Table.Cell width={2}>
                            <Button
                              size="tiny"
                              negative
                              basic
                              onClick={() => removeAnswer(idx)}
                              type="button"
                            >
                              -
                            </Button>
                            <Button
                              size="tiny"
                              positive
                              basic
                              onClick={() => addAnswer(idx)}
                              type="button"
                            >
                              +
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>

                {question?.image && (
                  <Image
                    size="medium"
                    src={question?.image}
                    rounded
                    className="mb-4 mt-8"
                  />
                )}

                <FileAttachment setAttachmentData={setAttachmentData} />

                <div className="my-2 flex justify-between">
                  <Button size="tiny" primary type="submit">
                    {state?.new ? 'Save' : 'Update'}
                  </Button>
                  <Button
                    className="mr-2"
                    size="tiny"
                    onClick={() => history.push(routes.jouralQusetion.all)}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </Segment>
    </div>
  )
}

export default UpdateJournalQuestion
