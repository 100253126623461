import {Formik} from 'formik'
import React, {useState} from 'react'
import {Button, Form, Image, Message} from 'semantic-ui-react'
import * as Yup from 'yup'
import {useHistory} from 'react-router-dom'

import routes from '../../routes'
import FormikInput from '../../components/formik/FormikInput'
import useAsync from '../../hooks/use-async'
import {axios} from '../../config/axios-config'
import Auth from '../../utils/auth'
import {formatResponseError} from '../../utils/format-response'

import AdminIcon from '../../components/icon'
import {useToasts} from 'react-toast-notifications'

import styles from './login.module.scss'

const Login = () => {
  const {run, isLoading, isError, error} = useAsync()
  const history = useHistory()
  const [required2fa, setRequired2fa] = useState(false)
  const {addToast} = useToasts()

  const loginSchema = Yup.object({
    email: Yup.string().email('Invaild').required('required'),
    password: Yup.string()
      .min(6, 'must be more than 6 characters')
      .max(50, 'must be less than 50 characters')
      .required('required'),
  })

  function processToken(data) {
    Auth.setToken(data.token)
    history.push(routes.dashboard)
  }
  function process2faCodeRequest(data) {
    addToast(`Email with code is sent`, {appearance: 'success'})
    setRequired2fa(true)
  }

  const handle2faRequestClick = formik => {
    if (formik.isValid) {
      const values = formik.values
      formik.setFieldValue('code2fa', '')

      onLogin({...values, request2faCode: true})
    }
  }

  const onLogin = values => {
    run(axios.post('/admin/signin', values)).then(({data}) => {
      if (data && data.token) {
        processToken(data)
      } else {
        if (data.required2faCode) {
          process2faCodeRequest(data)
        }
      }
    }).catch(e =>{})
  }

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="flex items-center justify-center text-center">
          <AdminIcon width="128" height="128" />
        </div>
      </div>
      {isError ? <Message error content={formatResponseError(error)} /> : null}
      <Formik
        initialValues={{email: '', password: '', code2fa: ''}}
        validationSchema={loginSchema}
        onSubmit={onLogin}
      >
        {formik => (
          <Form
            className="w-full my-4"
            onSubmit={formik.handleSubmit}
            loading={isLoading}
          >
            <FormikInput
              name="email"
              label="E-mail"
              placeholder="example@example.com"
              type="email"
            />
            <FormikInput
              name="password"
              label="Password"
              type="password"
              placeholder="******"
              className="pb-4"
            />
            {required2fa && (
              <div className={styles.growing}>
                <div className={styles.simple_input}>
                  <FormikInput
                    name="code2fa"
                    label="Code"
                    type="text"
                    placeholder="code 2fa"
                  />
                </div>
                <div>
                  <Button
                    onClick={() => handle2faRequestClick(formik)}
                    type="button"
                  >
                    send 2fa code
                  </Button>
                </div>
              </div>
            )}
            <Button type="submit" primary className="w-full">
              login
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Login
