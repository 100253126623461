import {Route} from 'react-router'
import {useHistory} from 'react-router-dom'
import Login from '../pages/auth/login'
import routes from '../routes'

const AuthLayout = () => {
  const history = useHistory()

  return (
    <div className=" flex justify-center items-center h-screen w-full  ui container">
      <div className="flex relative items-center justify-center h-full w-full">
        <div className="max-w-xl mt-3 w-full max-h-screen  rounded-2xl">
          <Route exact path={routes.login} component={Login} />
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
