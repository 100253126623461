import * as React from "react"

const AdminIcon = (props) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 945.54 641.59"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#388aa0}.cls-2{fill:#feb25e}"}</style>
    </defs>
    <path
      className="cls-1"
      d="m341.8 466.69-.29 97.55 68.19 68.19s49.51-47.43 10.38-118.89c0 0-22.83-43.29-78.28-46.85Z"
      transform="translate(-236.03 -466.69)"
    />
    <path
      className="cls-2"
      d="M236 574h96.36l66.41 68.78s-35.87 41.81-108.51 15.42c.03-.05-50.67-21.1-54.26-84.2Z"
      transform="translate(-236.03 -466.69)"
    />
    <path
      className="cls-1"
      d="M319.44 468.08s8.69-.4 10.08-.2v9.88Z"
      transform="translate(-236.03 -466.69)"
    />
    <path
      className="cls-2"
      d="M301.84 473.41a15.46 15.46 0 0 1 8.7-2.76l19 19.37-.4 10.47Z"
      transform="translate(-236.03 -466.69)"
    />
    <path
      className="cls-1"
      d="M286.82 479.54s4.94-3.36 8.11-3.36l34.78 35.18v11.27Z"
      transform="translate(-236.03 -466.69)"
    />
    <path
      className="cls-2"
      d="M273.58 488.83s3-4.15 6.13-5.14l49.41 49.81v10.67Z"
      transform="translate(-236.03 -466.69)"
    />
    <path
      className="cls-1"
      d="M262.31 499.11s3.36-4.94 5.74-5.73l61.07 62.46.2 5.14-5.93.19Z"
      transform="translate(-236.03 -466.69)"
    />
    <path
      className="cls-2"
      d="M252.23 511.17s3.17-5.54 5.34-6.13l55.34 55.54-12.25-.4Z"
      transform="translate(-236.03 -466.69)"
    />
    <path
      className="cls-1"
      d="M244.33 525.79s1.78-5.93 4.54-7.31L290.58 561h-12.06Z"
      transform="translate(-236.03 -466.69)"
    />
    <path
      className="cls-2"
      d="M239.58 542.59s-.19-6.72 2.18-9.29l27.08 27.87-11.47.2Z"
      transform="translate(-236.03 -466.69)"
    />
    <path
      className="cls-1"
      d="M236.42 561s-.4-8.11.79-9.29l9.69 9.48ZM1016.72 677.64c21.39 0 40.72-12.34 40.72-37 0-21.81-17.28-36.21-40.72-36.21-23 0-40.73 14.4-40.73 36.21.01 24.66 19.33 37 40.73 37Z"
      transform="translate(-236.03 -466.69)"
    />
    <rect
      className="cls-1"
      x={61.64}
      y={232.37}
      width={64.74}
      height={245.25}
      rx={23.38}
    />
    <path
      className="cls-1"
      d="M423.54 929.81v-214.4a16.35 16.35 0 0 1 16.35-16.35h28.68a16.35 16.35 0 0 1 16.35 16.35v20.17a112 112 0 0 1 8.28-12.44q24.09-30.55 69.41-30.56 38.85 0 60.86 23t22 63.71v148.88a16.13 16.13 0 0 1-16.14 16.14h-32.45a16.13 16.13 0 0 1-16.14-16.14V791q0-22.53-9.84-33.54t-30-11q-25.4 0-39.11 15.28T488 806v123.81a14.5 14.5 0 0 1-14.5 14.5H438a14.5 14.5 0 0 1-14.46-14.5ZM795.43 950.53q-46.87 0-75.62-36.52t-28.75-96.08q0-56.46 28.62-90.9t75.23-34.45q41.7 0 67.08 35.23a1.6 1.6 0 0 0 2.85-1v-97.55A16.17 16.17 0 0 1 881 613.08h32.12a16.17 16.17 0 0 1 16.18 16.18v298.87a16.17 16.17 0 0 1-16.18 16.18h-30.29a16.18 16.18 0 0 1-16.18-16.18V913l-1 1.55q-26.71 35.98-70.22 35.98Zm17.35-202q-25.89 0-40.53 20t-14.63 55.42q0 34.71 14.5 54.12t40.4 19.43q26.16 0 40.79-20.46T868 820q0-33.66-14.64-52.57t-40.58-18.91Z"
      transform="translate(-236.03 -466.69)"
    />
    <rect
      className="cls-1"
      x={753.37}
      y={232.37}
      width={64.74}
      height={245.25}
      rx={23.77}
    />
    <path
      className="cls-1"
      d="M1117.08 919.64V637.75a24.68 24.68 0 0 1 24.68-24.67h15.13a24.68 24.68 0 0 1 24.68 24.67v281.89a24.67 24.67 0 0 1-24.68 24.67h-15.13a24.67 24.67 0 0 1-24.68-24.67Z"
      transform="translate(-236.03 -466.69)"
    />
    <path
      className="cls-2"
      d="m767.18 1072.26-11.26 34.25h-14.59l37-109h16.93l37.21 109h-15l-11.67-34.25Zm35.78-11-10.79-31.35c-2.37-7.13-4-13.62-5.62-19.88h-.38c-1.55 6.37-3.26 13-5.4 19.69L770 1061.26ZM913.54 991.73v94.61c0 7 .27 14.77.65 20.17h-12.64l-.72-13.52h-.38c-4.35 8.73-13.76 15.3-26.52 15.3-18.95 0-33.56-16-33.56-39.72-.12-26.14 16.1-42 35.13-42 12 0 20.08 5.63 23.58 11.86h.33v-46.7Zm-14.13 68.41a29.29 29.29 0 0 0-.6-6.08c-2.21-9-9.88-16.36-20.59-16.36-14.72 0-23.52 12.91-23.52 30.09 0 15.88 7.9 29.06 23.23 29.06 9.52 0 18.16-6.42 20.78-17.06a23.54 23.54 0 0 0 .7-6.1ZM937.24 1049.45c0-8.16-.27-14.64-.65-21.12H949l.74 12.59h.4c4.35-7.44 11.64-14.37 24.68-14.37 10.6 0 18.69 6.45 22 15.67h.32a31.69 31.69 0 0 1 8.82-10.17c4.73-3.56 9.83-5.5 17.2-5.5 10.44 0 25.81 6.73 25.81 33.92v46h-13.88v-44.22c0-15.14-5.58-24.11-17-24.11-8.16 0-14.41 5.93-16.92 12.8a23.35 23.35 0 0 0-1.16 7.13v48.4h-13.85v-46.92c0-12.45-5.51-21.41-16.25-21.41-8.86 0-15.36 7.08-17.72 14.2a22.52 22.52 0 0 0-1.07 7v47.13h-13.88ZM1087.93 1006.23c0 4.84-3.39 8.83-9 8.83a8.48 8.48 0 0 1-8.58-8.83 8.64 8.64 0 0 1 8.84-8.85c5.27 0 8.71 3.88 8.74 8.85Zm-15.88 100.28v-78.18h14.14v78.18ZM1109.87 1049.45c0-8.16-.26-14.64-.64-21.12h12.56l.78 12.86h.4c3.84-7.35 12.94-14.64 25.85-14.64 10.74 0 27.55 6.46 27.55 33.32v46.64h-14.15v-45.08c0-12.64-4.76-23.22-18.07-23.22-9.33 0-16.61 6.63-19.15 14.59a22.15 22.15 0 0 0-1 6.6v47.11h-14.15Z"
      transform="translate(-236.03 -466.69)"
    />
  </svg>
)

export default AdminIcon
